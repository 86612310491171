
import React, { useState, useEffect, useRef } from 'react'
import compose from 'recompose/compose'
import { useSelector, connect } from 'react-redux';
import { MapContainer, TileLayer, Polyline, LayersControl, MapConsumer } from 'react-leaflet'
import MarkerClusterer from './markerClusterer'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'leaflet-routing-machine'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { calculateLocations } from '../../utils/calculatePosition'
import Routing from './routing'
import Menu from './menu'
import style from 'jss-material-ui'
import { changeConfig, ROUTING_MODE, SELECT_RIGHTMENU, DISABLE_CLUSTERING_AT_ZOOM } from '../../pages/configuration/actions'
import { updateIcon, updateStatus, acknowledgeRollover, acknowledgeMotion } from './actions'
import { BingLayer } from 'react-leaflet-bing-v2'
import { useTranslate, useListContext, useRecordContext, useGetRecordId, useShowContext, useLocaleState } from 'react-admin'
import { PrintMap } from './printControl';
import { Box, Menu as MuiMenu, CircularProgress, MenuItem, IconButton, Switch, Typography, useTheme, Dialog, DialogContent, Select, Button, Slide } from "@mui/material"
import StandardDialog from '../dialog/StandardDialog';
import LoadingDialog from '../dialog/LoadingDialog';
import { PdfBtn } from './pdfBtn';






const Container = style('div')({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        overflow: 'hidden'
    },
})

const SMap = style(MapContainer)({

    root: {
        flex: 1,
        padding: "0px",
        margin: "0px",
        display: 'block',
        height: '100%',
        width: '100%',
        borderRadius: "12px",
        "& .leaflet-top.leaflet-right": {
            display: "none"
        },
        "& .leaflet-top.leaflet-left": {
            display: "none"
        }
    },
})

const customStyles = ({ theme, isMobile }) => ({

    fullScreenBtn: {
        position: 'absolute',
        top: '16px',
        right: "16px",
        padding: "8px",
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000
    },
    satelliteIcon: {
        position: 'absolute',
        top: '16px',
        right: isMobile ? "16px" : "66px",
        padding: "8px",
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000

    },
    clusteringBtn: {
        position: 'absolute',
        top: '16px',
        right: isMobile ? "66px" : "116px",
        padding: "10px 8px 10px 8px",
        height: '40px',
        width: '119px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"

    },
    routingIcon: {
        position: 'absolute',
        top: '16px',
        right: isMobile ? "195px" : "245px",
        padding: "8px",
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000

    },
    pdfIcon: {
        position: 'absolute',
        top: '16px',
        right: isMobile ? "245px" : "295px",
        padding: "8px",
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000

    },
    clusteringText: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        textAlign: "center",
        margin: "0px",
        padding: "0px",
        color: theme.palette.text.secondary,

    },
    zoomInBtn: {
        position: 'absolute',
        top: isMobile ? '66px' : null,
        bottom: isMobile ? null : '56px',
        right: "16px",
        padding: "8px",
        height: '30px',
        width: '30px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000
    },
    zoomOutBtn: {
        position: 'absolute',
        top: isMobile ? '106px' : null,
        bottom: isMobile ? null : '20px',
        right: "16px",
        padding: "8px",
        height: '30px',
        width: '30px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000
    },
    infoBtn: {
        position: 'absolute',
        top: '66px',
        left: '16px',
        padding: "8px",
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000
    },
    pdfIconInMobile: {
        position: 'absolute',
        top: '116px',
        left: '16px',
        padding: "8px",
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: '8px',
        zIndex: 1000
    }
})

const FullScreenIcon = ({ color }) => {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8649 1.66675H17.5524C17.9854 1.66675 18.3337 2.01506 18.3337 2.448V7.1355C18.3337 7.45125 18.1449 7.73771 17.8519 7.85815C17.5589 7.9786 17.2236 7.91349 16.999 7.68888L15.7295 6.41935L12.8975 9.25138C12.5915 9.55737 12.0967 9.55737 11.7939 9.25138L10.7523 8.20972C10.4463 7.90373 10.4463 7.40894 10.7523 7.1062L13.5843 4.27417L12.3115 3.00138C12.0869 2.77677 12.0218 2.44149 12.1423 2.14852C12.2627 1.85555 12.5492 1.66675 12.8649 1.66675ZM7.13574 18.3334H2.44824C2.0153 18.3334 1.66699 17.9851 1.66699 17.5522V12.8647C1.66699 12.5489 1.85579 12.2625 2.14876 12.142C2.44173 12.0216 2.77702 12.0867 3.00163 12.3113L4.27116 13.5808L7.10319 10.7488C7.40918 10.4428 7.90397 10.4428 8.20671 10.7488L9.24837 11.7904C9.55436 12.0964 9.55436 12.5912 9.24837 12.894L6.41634 15.726L7.68587 16.9955C7.91048 17.2201 7.97559 17.5554 7.85514 17.8484C7.7347 18.1414 7.44824 18.3302 7.13249 18.3302L7.13574 18.3334Z" fill={color} />
        </svg>

    )
}

const FullScreenExitIcon = ({ color }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.957 1.89453C16.263 1.58854 16.7578 1.58854 17.0605 1.89453L18.1022 2.9362C18.4082 3.24219 18.4082 3.73698 18.1022 4.03971L15.2702 6.87175L16.5397 8.14128C16.7643 8.36589 16.8294 8.70117 16.709 8.99414C16.5885 9.28711 16.3021 9.47591 15.9863 9.47591H11.3021C10.8691 9.47591 10.5208 9.1276 10.5208 8.69466V4.01042C10.5208 3.69466 10.7096 3.4082 11.0026 3.28776C11.2956 3.16732 11.6309 3.23242 11.8555 3.45703L13.125 4.72656L15.957 1.89453ZM4.01042 10.5208H8.69792C9.13086 10.5208 9.47917 10.8691 9.47917 11.3021V15.9896C9.47917 16.3053 9.29036 16.5918 8.9974 16.7122C8.70443 16.8327 8.36914 16.7676 8.14453 16.543L6.875 15.2734L4.04297 18.1055C3.73698 18.4115 3.24219 18.4115 2.93945 18.1055L1.89453 17.0638C1.58854 16.7578 1.58854 16.263 1.89453 15.9603L4.72656 13.1283L3.45703 11.8555C3.23242 11.6309 3.16732 11.2956 3.28776 11.0026C3.4082 10.7096 3.69466 10.5208 4.01042 10.5208Z" fill={color} />
        </svg>
    )
}


const InfoIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" stroke-width="0.6666666666666666" />
            <path d="M2 8a6 6 0 1 0 12 0 6 6 0 0 0 -12 0" />
            <path d="M8 6h0.007" />
            <path d="M7.333 8h0.667v2.667h0.667" />
        </svg>

    )
}

const SatelliteIcon = ({ color }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-satellite">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3.707 6.293l2.586 -2.586a1 1 0 0 1 1.414 0l5.586 5.586a1 1 0 0 1 0 1.414l-2.586 2.586a1 1 0 0 1 -1.414 0l-5.586 -5.586a1 1 0 0 1 0 -1.414z" />
        <path d="M6 10l-3 3l3 3l3 -3" />
        <path d="M10 6l3 -3l3 3l-3 3" />
        <path d="M12 12l1.5 1.5" />
        <path d="M14.5 17a2.5 2.5 0 0 0 2.5 -2.5" />
        <path d="M15 21a6 6 0 0 0 6 -6" />
    </svg>)

}

const SatelliteOffIcon = ({ color }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-satellite-off">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M7.707 3.707l5.586 5.586m-1.293 2.707l-1.293 1.293a1 1 0 0 1 -1.414 0l-5.586 -5.586a1 1 0 0 1 0 -1.414l1.293 -1.293" />
        <path d="M6 10l-3 3l3 3l3 -3" />
        <path d="M10 6l3 -3l3 3l-3 3" />
        <path d="M12 12l1.5 1.5" />
        <path d="M14.5 17c.69 0 1.316 -.28 1.769 -.733" />
        <path d="M15 21c1.654 0 3.151 -.67 4.237 -1.752m1.507 -2.507a6 6 0 0 0 .256 -1.741" />
        <path d="M3 3l18 18" /></svg>)

}

const PdfIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-file-type-pdf">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
            <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
            <path d="M17 18h2" />
            <path d="M20 15h-3v6" />
            <path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" />
        </svg>

    )

}


const RoutingIcon = ({ color }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-route">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
        <path d="M19 7a2 2 0 1 0 0 -4a2 2 0 0 0 0 4z" />
        <path d="M11 19h5.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h4.5" />
    </svg>)

}

const ZoomInIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-plus">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 5l0 14" />
            <path d="M5 12l14 0" />
        </svg>
    )
}
const ZoomOutIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-minus">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
        </svg>)
}

const FullScreenBtn = ({ onFullScreen, onClick }) => {
    const theme = useTheme()
    const styles = customStyles({ theme })
    return (
        <IconButton
            sx={styles.fullScreenBtn}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >

            {onFullScreen ?
                <FullScreenExitIcon color={theme.palette.text.secondary} /> :
                <FullScreenIcon color={theme.palette.text.secondary} />
            }
        </IconButton>

    )
}

const SatelliteBtn = ({ onSatellite, onClick, isMobile }) => {
    const theme = useTheme()
    const styles = customStyles({ theme, isMobile })
    return (
        <IconButton
            sx={styles.satelliteIcon}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >

            {onSatellite ?
                <SatelliteOffIcon color={theme.palette.text.secondary} /> :
                <SatelliteIcon color={theme.palette.text.secondary} />
            }
        </IconButton>

    )
}





const RoutingBtn = ({ onClick, isMobile, routingMode }) => {
    const theme = useTheme()
    const translate = useTranslate()
    const styles = customStyles({ theme, isMobile })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const routingChoices = ["driving", "cycling", "walking", "boat", "no_routing"]


    return (
        <div>
            <IconButton
                sx={styles.routingIcon}
                onClick={handleClick}
            >
                <RoutingIcon color={theme.palette.text.secondary} />

            </IconButton>
            <MuiMenu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >
                {
                    routingChoices.map((routingChoice, index) => {
                        return (
                            <MenuItem key={index} onClick={() => { onClick(routingChoice); handleClose() }} selected={routingChoice === routingMode}>
                                {translate(`resources.map.routing_mode.${routingChoice}`)}
                            </MenuItem>
                        )
                    })
                }


            </MuiMenu>
        </div>

    )
}

const ClusteringBtn = ({ onClustering, onClick, isMobile }) => {
    const theme = useTheme()
    const styles = customStyles({ theme, isMobile })
    const translate = useTranslate()
    return (
        <Box
            sx={styles.clusteringBtn}

        >

            <Switch size="small"
                checked={onClustering}
                onChange={(e) => {
                    e.preventDefault();
                    onClick();
                }}
            />

            <Typography sx={styles.clusteringText}
            >{translate("resources.map.clustring")}</Typography>
        </Box>

    )
}

const ZoomInBtn = ({ onClick, isMobile }) => {
    const theme = useTheme()
    const styles = customStyles({ theme, isMobile })
    return (
        <IconButton
            sx={styles.zoomInBtn}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            <ZoomInIcon color={theme.palette.text.secondary} />
        </IconButton>

    )
}

const ZoomOutBtn = ({ onClick, isMobile }) => {
    const theme = useTheme()
    const styles = customStyles({ theme, isMobile })
    return (
        <IconButton
            sx={styles.zoomOutBtn}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            <ZoomOutIcon color={theme.palette.text.secondary} />
        </IconButton>

    )
}



const FiltersIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-filter">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" strokeWidth="0.6666666666666666" />
            <path d="M2.667 2.667h10.667v1.448a1.333 1.333 0 0 1 -0.391 0.943L10 8v4.667l-4 1.333v-5.667L3.013 5.048A1.333 1.333 0 0 1 2.667 4.151z" />
        </svg>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const FiltersDialog = (
    { open,
        setOpen,
        searchOption,
        setSearchOption,
        maxAccuracy,
        ignorePagination,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        perPage,
        listContext,
        handleChangeSearchOption,
        handleChangeMaxAccuracy }
) => {

    const theme = useTheme();
    const styles = customStyles({ theme });
    const contentBg = theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default;
    const translate = useTranslate()
    const handleClose = () => setOpen(false);
    const [locale, setLocale] = useLocaleState();
    const dialogRef = useRef(null);
    const handleDrag = (event) => {
        const dialogElement = dialogRef.current;
        if (!dialogElement) return;

        // Detect touch or mouse events
        const startY = event.type === 'mousedown'
            ? event.clientY
            : event.touches[0].clientY;

        const onMove = (moveEvent) => {
            const currentY = moveEvent.type === 'mousemove'
                ? moveEvent.clientY
                : moveEvent.touches[0].clientY;

            const deltaY = currentY - startY;

            if (deltaY > 150) {
                // Close the dialog if dragged down more than 150px
                handleClose();
            } else {
                // Temporarily move the dialog down
                dialogElement.style.transform = `translateY(${Math.min(deltaY, 150)}px)`;
            }
        };

        const onEnd = () => {
            // Reset dialog position and remove listeners
            dialogElement.style.transform = '';
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onEnd);
            window.removeEventListener('touchmove', onMove);
            window.removeEventListener('touchend', onEnd);
        };

        // Add listeners for dragging and release
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onEnd);
        window.addEventListener('touchmove', onMove);
        window.addEventListener('touchend', onEnd);
    };
    return (
        <Dialog
            ref={dialogRef}
            open={open}
            TransitionComponent={Transition}
            onClose={() => { }}
            fullScreen
            PaperProps={{
                style: { boxShadow: 'none' }, // Optional: Remove shadow around the dialog
            }}
            slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } }
            }}
            sx={{
                "& .MuiPaper-root": {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    height: "auto",
                    minHeight: "20%",
                    maxHeight: "70%",
                    margin: "0px",
                    padding: "0px",
                    border: 1,
                    borderTopColor: theme.palette.text.disabled,
                    borderWidth: "1px",
                    borderRadius: "12px 12px 0px 0px"
                }
            }}
        >

            <Box
                sx={{
                    width: "100%",
                    height: "15px",
                    margin: "0px",
                    padding: "10px 0px 10px 0px",
                    borderRadius: "0px",
                    backgroundColor: "none",
                    opacity: 0.45,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onMouseDown={handleDrag} // Handle mouse drag
                onTouchStart={handleDrag}

            >
                <Box
                    sx={{
                        width: "40px",
                        height: "4px",
                        margin: "1px",
                        borderRadius: "12px",
                        backgroundColor: theme.palette.text.secondary,
                        opacity: 0.45
                    }}


                >
                </Box>
            </Box>
            <DialogContent
                sx={{
                    margin: "0px",
                    padding: "16px 16px 30px 16px",
                    background: contentBg,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}>


                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchOption}
                    label={translate("resources.locations.searchOption")}
                    onChange={handleChangeSearchOption}
                    sx={{
                        width: "100%",
                        height: "48px",
                        margin: "0px 0px 0px 0px !important",
                        "& .MuiInputBase-root": {
                            padding: "0px !important",
                            height: "48px",
                            textAlign: "center !important",
                            borderRadius: "12px !important",
                            borderWidth: "1px",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? theme.palette.grey[50]
                                    : theme.palette.background.appFrame,
                            color: theme.palette.text.primary,
                        },
                        "& .MuiFormLabel-root": {
                            marginTop: "4px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "12px !important",
                            borderWidth: "1px",
                        },
                        "legend > span": {
                            opacity: 1,
                            color: theme.palette.primary.main,
                            height: "15px"
                        },
                        "legend": {
                            opacity: 1,
                            color: theme.palette.primary.main,
                            height: "15px",
                            fontSize: "11px"

                        },
                    }}
                >
                    <MenuItem value={"last_5"}>{translate("resources.locations.location5")}</MenuItem>
                    <MenuItem value={"last_25"}>{translate("resources.locations.location25")}</MenuItem>
                    <MenuItem value={"last_50"}>{translate("resources.locations.location50")}</MenuItem>
                    <MenuItem value={"last_100"}>{translate("resources.locations.location100")}</MenuItem>
                    <MenuItem value={"today"}>{translate("resources.locations.locationToday")}</MenuItem>
                    <MenuItem value={"yesterday"}>{translate("resources.locations.locationYesterday")}</MenuItem>
                    <MenuItem value={"custom"}>{translate("resources.locations.customized")}</MenuItem>
                </Select>

                {startDate && endDate &&
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                        <DateTimePicker
                            label={translate("resources.devices.from")}
                            value={startDate}
                            onChange={(newValue) => { setStartDate(newValue); setSearchOption("custom") }}
                            ampm={false}
                            sx={{
                                width: "100%",
                                height: "48px",
                                margin: "16px 0px 0px 0px !important",
                                "& .MuiInputBase-root": {
                                    padding: "0px !important",
                                    height: "48px",
                                    textAlign: "center !important",
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    backgroundColor:
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[50]
                                            : theme.palette.background.appFrame,
                                    color: theme.palette.text.primary,
                                },
                                "& .MuiFormLabel-root": {
                                    marginTop: "4px",
                                },
                                "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                    paddingRight: "4px !important",
                                    paddingLeft: "10px !important",
                                },

                                "& .MuiButtonBase-root.MuiIconButton-root": {
                                    padding: "8px 2px 8px 2px !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    width: "100%",
                                    padding: "0px 0px 0px 0px !important",
                                },

                            }}

                        />
                        <DateTimePicker
                            label={translate("resources.devices.to")}
                            value={endDate}
                            onChange={(newValue) => { setEndDate(newValue); setSearchOption("custom") }}
                            ampm={false}
                            sx={{
                                width: "100%",
                                height: "48px",
                                margin: "16px 0px 0px 0px !important",
                                "& .MuiInputBase-root": {
                                    padding: "0px !important",
                                    height: "48px",
                                    textAlign: "center !important",
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    backgroundColor:
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[50]
                                            : theme.palette.background.appFrame,
                                    color: theme.palette.text.primary,
                                },
                                "& .MuiFormLabel-root": {
                                    marginTop: "4px",
                                },
                                "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                    paddingRight: "4px !important",
                                    paddingLeft: "10px !important",
                                },

                                "& .MuiButtonBase-root.MuiIconButton-root": {
                                    padding: "8px 2px 8px 2px !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    width: "100%",
                                    padding: "0px 0px 0px 0px !important",
                                },
                            }}
                        />
                    </LocalizationProvider>
                }

                <Select
                    value={maxAccuracy}
                    label={translate("resources.devices.labels.accuracy")}
                    onChange={handleChangeMaxAccuracy}
                    sx={{
                        width: "100%",
                        height: "48px",
                        margin: "16px 0px 0px 0px !important",
                        "& .MuiInputBase-root": {
                            padding: "0px !important",
                            height: "48px",
                            textAlign: "center !important",
                            borderRadius: "12px !important",
                            borderWidth: "1px",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? theme.palette.grey[50]
                                    : theme.palette.background.appFrame,
                            color: theme.palette.text.primary,
                        },
                        "& .MuiFormLabel-root": {
                            marginTop: "4px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "12px !important",
                            borderWidth: "1px",
                        },
                        "legend > span": {
                            opacity: 1,
                            color: theme.palette.primary.main,
                            height: "15px"
                        },
                        "legend": {
                            opacity: 1,
                            color: theme.palette.primary.main,
                            height: "15px",
                            fontSize: "11px"

                        },
                    }}
                >
                    <MenuItem value={"all"}>{translate("resources.locations.accuracy.all")}</MenuItem>
                    <MenuItem value={50}>{translate("resources.locations.accuracy.lessThan50m")}</MenuItem>
                    <MenuItem value={100}>{translate("resources.locations.accuracy.lessThan100m")}</MenuItem>
                    <MenuItem value={500}>{translate("resources.locations.accuracy.lessThan500m")}</MenuItem>
                    <MenuItem value={1000}>{translate("resources.locations.accuracy.lessThan1km")}</MenuItem>
                    <MenuItem value={5000}>{translate("resources.locations.accuracy.lessThan5km")}</MenuItem>
                    <MenuItem value={10000}>{translate("resources.locations.accuracy.lessThan10km")}</MenuItem>
                    <MenuItem value={20000}>{translate("resources.locations.accuracy.lessThan20km")}</MenuItem>
                </Select>


                <Box component="span" mt="16px" sx={{ width: "100%" }}>
                    <Button
                        variant='contained'
                        sx={{ width: "100%" }}
                        onClick={() => {
                            let filters = {}
                            if (maxAccuracy !== "all") {
                                filters.max_accuracy = parseInt(maxAccuracy)
                            }

                            if (startDate) {
                                filters.start_date = startDate.toISOString()
                            }
                            if (endDate) {
                                filters.end_date = endDate.toISOString()
                            }
                            if (ignorePagination) {
                                filters.ignore_pagination = true
                            } else {
                                filters.ignore_pagination = false
                            }

                            listContext.setFilters(filters, {}, false)
                            listContext.setPerPage(perPage)

                            setOpen(false)

                        }}>
                        {translate("resources.locations.labels.viewHistory")}
                    </Button>
                </Box>
                <Box component="span" mb="8px" sx={{ width: "100%" }}>
                    <Button variant="text" sx={{ width: "100%" }} onClick={() => setOpen(false)}>
                        {translate("ra.action.close")}
                    </Button>
                </Box>

            </DialogContent>


        </Dialog>
    )
}


const startDateFromSearchOption = (searchOption) => {
    let date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    switch (searchOption) {
        case "today":
            break;
        case "custom":
            break;
        case "yesterday":
            date.setDate(date.getDate() - 1)
            break;
        default:
            return null
    }
    return dayjs(date.toISOString())
}


const endDateFromSearchOption = (searchOption) => {
    let date = new Date()
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    switch (searchOption) {
        case "today":
            break;
        case "custom":
            break;
        case "yesterday":
            date.setDate(date.getDate() - 1)
            break;
        default:
            return null
    }
    return dayjs(date.toISOString())
}


const perPageFromSearchOption = (searchOption) => {
    switch (searchOption) {
        case "last_5":
            return 5
        case "last_25":
            return 25
        case "last_50":
            return 50
        case "last_100":
            return 100
        default:
            return 1000
    }
}

const ignorePaginationFromSearchOption = (searchOption) => {
    switch (searchOption) {
        case "last_5":
            return true
        case "last_25":
            return true
        case "last_50":
            return true
        case "last_100":
            return true
        default:
            return false
    }
}


const FiltersBtn = ({ open, setOpen
}) => {
    const theme = useTheme();
    const translate = useTranslate();
    const [searchOption, setSearchOption] = useState("last_5");
    const [maxAccuracy, setMaxAccuracy] = useState("all");
    const [ignorePagination, setIgnorePagination] = useState(ignorePaginationFromSearchOption("last_5"));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [perPage, setPerPage] = useState(perPageFromSearchOption("last_5"));
    const listContext = useListContext()


    const handleChangeSearchOption = (event) => {
        const { value } = event.target
        setSearchOption(value)
        setStartDate(startDateFromSearchOption(value))
        setEndDate(endDateFromSearchOption(value))
        setPerPage(perPageFromSearchOption(value))
        setIgnorePagination(ignorePaginationFromSearchOption(value))
    }


    const handleChangeMaxAccuracy = (event) => {
        const { value } = event.target
        setMaxAccuracy(value)
    }


    return (
        <>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '16px',
                    left: '16px',
                    padding: "8px",
                    height: '40px',
                    width: '40px',
                    backgroundColor: theme.palette.background.paper,
                    "&:hover": {
                        backgroundColor: theme.palette.background.paper,
                    },
                    borderRadius: '8px',
                    zIndex: 1000
                }}
                onClick={(e) => { e.preventDefault(); setOpen(!open) }}
            >
                <FiltersIcon color={theme.palette.text.secondary} />
            </IconButton>
            <FiltersDialog
                open={open}
                setOpen={setOpen}
                searchOption={searchOption}
                setSearchOption={setSearchOption}
                maxAccuracy={maxAccuracy}
                ignorePagination={ignorePagination}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                perPage={perPage}
                listContext={listContext}
                handleChangeSearchOption={handleChangeSearchOption}
                handleChangeMaxAccuracy={handleChangeMaxAccuracy}
            />
        </>


    )
}




const InfoBtn = ({ open, setOpen, isMobile, InfoDialogComponent }) => {
    const theme = useTheme()
    const styles = customStyles({ theme, isMobile })
    return (
        <>
            <IconButton
                sx={styles.infoBtn}
                onClick={(e) => { e.preventDefault(); setOpen(!open) }}
            >
                <InfoIcon color={theme.palette.text.secondary} />
            </IconButton>
            <StandardDialog
                open={open}
                onClose={() => setOpen(false)}
                BodyComponent={InfoDialogComponent}
            />
        </>

    )
}


const mapStateToProps = state => ({
    config: state.config,

})

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeConfig,
        }
    ),

)


const Loading = () => {
    const theme = useTheme()
    return (
        <Box sx={{
            display: 'flex',
            width: "100%",
            height: 'calc(100vh - 220px)',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress sx={{
                color: theme.palette.primary.main,
                animationDuration: '900ms',
            }} />
        </Box>
    );
}





export const LocationsMap = enhance((props) => {

    const [idSelected, setidSelected] = useState(null);
    const [openFilterBtn, setOpenFilterBtn] = React.useState(false);
    const [openSearchDeviceFilterBtn, setOpenSearchDeviceFilterBtn] = React.useState(false);
    const [openInfoBtn, setOpenInfoBtn] = useState(false);
    const [zoom, setZoom] = useState(13);
    const [center, setCenter] = useState(undefined);
    const [map, setMap] = useState(null)
    const [mapLayerIsSattelite, setMapLayerIsSattelite] = useState(false)
    const { changeConfig, latKey, longKey, timestampKey, altKey, accuracyKey, formattedAddressKey, iconNameKey, nameKey, allowRouting, MenuCustomListSubheader, MenuCustomListItem, isMobile, isListAsDialog, enablePdfReport, pdfReportType, disableFullscreenUi, disableClusteringUi, disableZoomUi, disableSatteliteUi, showLocationHistoryFiltersBtn, showSearchDeviceFiltersBtn, SearchDeviceFiltersBtn, InfoDialogComponent, deviceName, deviceSn, iconColorMap, handleMapClickWithSetFilters } = props
    const selectRightMenu = useSelector(state => state.config[SELECT_RIGHTMENU])
    const routingMode = useSelector(state => state.config[ROUTING_MODE])
    const disableClusteringAtZoom = useSelector(state => state.config[DISABLE_CLUSTERING_AT_ZOOM])
    const theme = useTheme()
    const styles = customStyles({ theme });
    const { data, isLoading, filterValues, page, perPage, total, setFilters } = useListContext();
    const scrollYContainerRef = useRef(null);
    const scrollXContainerRef = useRef(null);


    if (isLoading) {
        return <Loading />
    }
    // no list data 
    if (!data || (data.length <= 0)) return null;


    const { bounds, locations } = calculateLocations({ data, page, perPage, latKey, longKey, timestampKey, altKey, accuracyKey, formattedAddressKey, iconNameKey, nameKey, setCenter, iconColorMap })



    const routeMachineKey = toString(locations.length) + routingMode

    const changeCenter = (location) => {
        if (map, location.hasLocation) {
            setCenter([location.lat, location.lng])
            map.setView([location.lat, location.lng], map.getZoom());
        }
    }

    const handleSelect = (location) => {
        if (map) {
            setZoom(map.getZoom())
        }
        changeCenter(location);
        if (idSelected !== location.id) {
            setidSelected(location.id)
        }

    }

    const handleUnselect = () => {

        setidSelected(null)
    }

    const onToggleMenuOpen = () => {
        changeConfig(SELECT_RIGHTMENU, !selectRightMenu)
    }

    const zoomOut = () => {
        if (map && map.zoomOut) {
            map.zoomOut(0.25, null)
        }
    }

    const zoomIn = () => {
        if (map && map.zoomIn) {
            map.zoomIn(0.25, null)
        }
    }

    const handleChangeRoutingMode = (routingChoice) => {
        if (routingChoice !== undefined) {
            changeConfig(ROUTING_MODE, routingChoice);
        }
    }


    return (

        <Box
            sx={{
                display: "flex",
                flexDirection: isListAsDialog ? "column" : "row",
                height: isListAsDialog ? 'calc(100vh - 100px)' : (isMobile ? 'calc(100vh - 150px)' : 'calc(100vh - 300px)'),
                width: '100%',
                margin: "0px",
                padding: "0px",
            }}

        >
            {!isMobile && MenuCustomListItem && selectRightMenu &&
                <Menu
                    MenuCustomListSubheader={MenuCustomListSubheader}
                    MenuCustomListItem={MenuCustomListItem}
                    locations={locations}
                    idSelected={idSelected}
                    lastLocation={false}
                    handleSelect={handleSelect}
                    scrollYContainerRef={scrollYContainerRef}
                    scrollXContainerRef={scrollXContainerRef}
                    handleUnSelect={handleUnselect}
                    updateIcon={updateIcon}
                    updateStatus={updateStatus}
                    acknowledgeRollover={acknowledgeRollover}
                    acknowledgeMotion={acknowledgeMotion}
                    isMobile={isMobile}

                />
            }

            <SMap
                whenCreated={setMap}
                bounds={center ? undefined : bounds}
                center={center}
                zoom={40}
                zoomSnap={0.25}
                zoomDelta={0.25}

            >
                <MapConsumer>
                    {(map) => {
                        map.on('baselayerchange', function (e) {

                            if (e.name === 'satellite') {
                                setMapLayerIsSattelite(true)
                            } else {
                                setMapLayerIsSattelite(false)
                            }
                        });

                        return null
                    }}
                </MapConsumer>





                {handleMapClickWithSetFilters &&
                    <MapConsumer>
                        {(map) => {
                            // Using useEffect to prevent multiple event bindings
                            useEffect(() => {
                                map.on("click", (e) => { handleMapClickWithSetFilters({ e, setFilters }) });

                                return () => {
                                    map.off("click", (e) => { handleMapClickWithSetFilters({ e, setFilters }) }); // Cleanup to avoid duplicates
                                };

                            }, [map]); // Runs only when 'map' changes

                            return null;
                        }}
                    </MapConsumer>
                }



                <LayersControl position="topright" >
                    <LayersControl.BaseLayer checked={mapLayerIsSattelite ? false : true} name="road">
                        <TileLayer
                            maxZoom={19}
                            attribution="&amp;copy <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />

                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer checked={mapLayerIsSattelite ? true : false} name='satellite'>
                        <BingLayer
                            maxZoom={19}
                            bingkey={process.env.REACT_APP_BING_API_KEY}
                            type="AerialWithLabels"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>


                <PrintMap />

                {allowRouting && routingMode !== 'no_routing' && routingMode !== 'boat' &&
                    <Routing positions={locations} key={routeMachineKey} routingMode={routingMode} />}

                {allowRouting && routingMode === 'boat' &&
                    <Polyline positions={locations} key={routeMachineKey + 'boat'} color="#0000ff" />}

                <MarkerClusterer
                    locations={locations}
                    idSelected={idSelected}
                    handleSelect={handleSelect}
                    scrollYContainerRef={scrollYContainerRef}
                    scrollXContainerRef={scrollXContainerRef}
                    disableClusteringAtZoom={disableClusteringAtZoom ? 16 : 1}
                />

            </SMap>

            {allowRouting &&
                <RoutingBtn onClick={handleChangeRoutingMode} isMobile={isMobile} routingMode={routingMode} />}
            {!isMobile && !disableFullscreenUi &&
                <FullScreenBtn onClick={onToggleMenuOpen} onFullScreen={!selectRightMenu} />}
            {enablePdfReport &&
                <PdfBtn
                    styles={isMobile ? styles.pdfIconInMobile : styles.pdfIcon}
                    pdfReportType={pdfReportType}
                    PdfIcon={PdfIcon}
                    locations={locations}
                    filterValues={filterValues}
                    deviceName={deviceName}
                    deviceSn={deviceSn}
                    page={page}
                    total={total}
                    perPage={perPage}

                />}
            {!disableSatteliteUi &&
                <SatelliteBtn
                    onClick={() => { mapLayerIsSattelite ? setMapLayerIsSattelite(false) : setMapLayerIsSattelite(true) }}
                    onSatellite={mapLayerIsSattelite}
                    isMobile={isMobile}
                />}

            {!disableClusteringUi &&
                <ClusteringBtn onClick={() => {
                    disableClusteringAtZoom ?
                        changeConfig(DISABLE_CLUSTERING_AT_ZOOM, false) :
                        changeConfig(DISABLE_CLUSTERING_AT_ZOOM, true)
                }}
                    onClustering={disableClusteringAtZoom}
                    isMobile={isMobile}
                />}
            {!disableZoomUi &&
                <ZoomInBtn onClick={zoomIn} isMobile={isMobile} />}
            {!disableZoomUi &&
                <ZoomOutBtn onClick={zoomOut} isMobile={isMobile} />}


            {InfoDialogComponent &&
                <InfoBtn open={openInfoBtn} setOpen={setOpenInfoBtn} isMobile={isMobile} InfoDialogComponent={InfoDialogComponent} />}


            {showLocationHistoryFiltersBtn &&
                <FiltersBtn open={openFilterBtn} setOpen={setOpenFilterBtn} />}

            {showSearchDeviceFiltersBtn &&
                <SearchDeviceFiltersBtn open={openSearchDeviceFilterBtn} setOpen={setOpenSearchDeviceFilterBtn} />}

            {isMobile && MenuCustomListItem &&
                <Menu
                    MenuCustomListSubheader={MenuCustomListSubheader}
                    MenuCustomListItem={MenuCustomListItem}
                    locations={locations}
                    idSelected={idSelected}
                    lastLocation={false}
                    handleSelect={handleSelect}
                    scrollYContainerRef={scrollYContainerRef}
                    scrollXContainerRef={scrollXContainerRef}
                    handleUnSelect={handleUnselect}
                    updateIcon={updateIcon}
                    updateStatus={updateStatus}
                    acknowledgeRollover={acknowledgeRollover}
                    acknowledgeMotion={acknowledgeMotion}
                    isMobile={isMobile}
                    isListAsDialog={isListAsDialog}

                />
            }

        </Box>
    )
}
)