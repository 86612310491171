import { getValueForNestedKey } from './utils'



export const calculateLocations = ({ data, page, perPage, latKey, longKey, timestampKey, altKey, accuracyKey, iconNameKey, formattedAddressKey, nameKey, iconColorMap }) => {


    let minLat, minLong, maxLat, maxLong
    let locations = []
    let bounds = undefined


    if ((!data) || (data?.length === 0)) {

        return { bounds, locations }
    }

    data.forEach((record, idx) => {
        const lat = getValueForNestedKey(record, latKey)
        const lng = getValueForNestedKey(record, longKey)
        const timestamp = getValueForNestedKey(record, timestampKey);
        const alt = getValueForNestedKey(record, altKey);
        const accuracy = getValueForNestedKey(record, accuracyKey);
        const name = getValueForNestedKey(record, nameKey);
        let formattedAddress = getValueForNestedKey(record, formattedAddressKey);
        const { iconName, iconColor } = getIcon({ record, iconColorMap, iconNameKey });



        if (lat !== null && lng !== null) {
            locations.push({ id: record.id, number: idx + 1 + ((page - 1) * perPage), lat: lat, lng: lng, timestamp: timestamp, alt: alt, accuracy: accuracy, formattedAddress: formattedAddress, meta: { name: name }, record: record, hasLocation: true, iconColor: iconColor, iconName: iconName })
            if (!minLat || minLat > lat) {
                minLat = lat
            }
            if (!minLong || minLong > lng) {
                minLong = lng
            }
            if (!maxLat || maxLat < lat) {
                maxLat = lat
            }
            if (!maxLong || maxLong < lng) {
                maxLong = lng
            }
        } else {
            locations.push({ id: record.id, record: record, hasLocation: false })

        }

    })


    if (minLat && minLong && maxLat && maxLong) {

        bounds = [[minLat, minLong], [maxLat, maxLong]]


    }

    return { bounds, locations }
}



const getIcon = ({ record, iconColorMap, iconNameKey }) => {
    const iconName = getValueForNestedKey(record, iconNameKey);
    if (iconColorMap &&
        iconColorMap.parameterKey &&
        iconColorMap.matchingList &&
        Array.isArray(iconColorMap.matchingList) &&
        iconColorMap.matchingList.length > 0
    ) {
        const iconColorParameter = getValueForNestedKey(record, iconColorMap.parameterKey);
        for (const m of iconColorMap.matchingList) {
            if (m.equals && (m.equals === iconColorParameter)) {
                return { iconName: m.name ? m.name : iconName, iconColor: m.color }
            }

            if (m.isTrue && (m.isTrue(iconColorParameter))) {

                return { iconName: m.name ? m.name : iconName, iconColor: m.color }
            }
        }
    }

    return { iconName: iconName }

}







