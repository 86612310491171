

import { Button, Chip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const CardDetailRow = ({ label, ValueComponent }) => {

    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0
                }
            }}
        >
            <TableCell align="left" sx={{ padding: "10px 12px 10px 12px" }}> <Typography sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}>{label}</Typography></TableCell>
            <TableCell align="right" sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}><ValueComponent /></TableCell>
        </TableRow>

    )
}

export const CardDetailRowWithOnClick = ({ label, onClick, ValueComponent, labelColor }) => {

    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0
                }
            }}
        >
            <TableCell align="left" sx={{ padding: "10px 12px 10px 12px" }}>
                <Button sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", padding: "0px", color: labelColor }} onClick={onClick} variant="text">{label}</Button>
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}><ValueComponent /></TableCell>
        </TableRow>

    )
}




export const CardDetailRowWithDate = ({ label, ValueComponent, DateComponent }) => {

    return (
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >

            <TableCell align="left" sx={{ fontWeight: 500 }}>{label}</TableCell>
            <TableCell align="left"><ValueComponent /></TableCell>
            <TableCell align="left"><DateComponent /></TableCell>
        </TableRow>

    )
}

export const ColoredChipByValue = ({ value, palette }) => {

    if (palette.length > 0) {
        for (let index = 0; index < palette.length; index++) {
            const item = palette[index];


            if (item.equalTo === value) {
                return (<Chip
                    key={index}
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: 10,
                        height: 24,
                        backgroundColor: item.backgroundColor,
                        color: 'white'
                    }}
                    label={item.label}
                />)
            }

        }



    }
    return null;

} 