

export function getDateTimeToFullLocalString({ timestamp, locale, showTimezone }) {

    const date = new Date(timestamp); // UTC date
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = date.toLocaleString(
        locale ? locale : Intl.DateTimeFormat().resolvedOptions().locale,
        {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format
        }
    ).replace(',', '');

    if (showTimezone) return (`${formattedDate} ${timezone}`);
    return formattedDate;
}

export function getNowDateTimeToFullLocalString({ locale, showTimezone }) {

    const date = new Date(); // UTC date
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = date.toLocaleString(
        locale ? locale : Intl.DateTimeFormat().resolvedOptions().locale,
        {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format
        }
    ).replace(',', '');

    if (showTimezone) return (`${formattedDate} ${timezone}`);
    return formattedDate;
}

export function getDateTimeToFullLocalStringLabelFormatter(timestamp) {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const date = new Date(timestamp); // UTC date
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = date.toLocaleString(locale, {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Use 24-hour format
    }).replace(',', '');


    return (`${formattedDate} ${timezone}`);
}


export function getHoursMinutesFromTimestamp(timestamp) {
    const dateTime = new Date(timestamp)
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    return (`${hours}:${minutes}`);
}

export function getDayMonthFromTimestamp(timestamp) {
    const dateTime = new Date(timestamp)
    const day = String(dateTime.getDate()).padStart(2, '0');
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Months are zero based    
    return (`${day}/${month} `);
}




