import { Grid, useTheme, useMediaQuery } from "@mui/material"
import { useDataProvider, useLocaleState, useTranslate } from 'react-admin'
import DashboardCard, { InfoTable2Col } from "../../ui/card/DashboardCard";
import { getNowDateTimeToFullLocalString } from "../../utils/date";
import { useEffect, useState } from "react";
import { DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY, USER_ORGANIZATION_ID } from "../configuration/actions";
import { useNavigate } from "react-router-dom";
import { getNumberOfDevicesOutsideOrganizationBoundary } from "../../rest/restClient";







const Icon = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" stroke={color} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-x">
            <path stroke="none" d="M0 0h28v28H0z" fill="none" stroke-width="1.1666666666666665" />
            <path d="M9.986 4.304A10.554 10.554 0 0 0 6.58 6.579" />
            <path d="M4.304 9.986A10.554 10.554 0 0 0 3.5 14" />
            <path d="M4.304 18.014a10.554 10.554 0 0 0 2.274 3.406" />
            <path d="M9.986 23.694A10.554 10.554 0 0 0 14 24.5" />
            <path d="M18.014 23.694a10.554 10.554 0 0 0 3.406 -2.274" />
            <path d="M23.694 18.014A10.554 10.554 0 0 0 24.5 14" />
            <path d="M23.694 9.986A10.554 10.554 0 0 0 21.42 6.58" />
            <path d="M18.014 4.304A10.554 10.554 0 0 0 14 3.5" />
            <path d="m16.334 16.334 -4.666 -4.666" />
            <path d="m11.666 16.334 4.666 -4.666" />
        </svg>

    )

}



const NumberOfDevicesOutsideOrganizationBoundaryBodyComponent = ({ organization, numberOfDevices }) => {
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();

    let rows = []
    let caption = null

    rows.push({ label: translate("resources.dashboard.number_of_devices_outside_organization_boundary.organization"), info: organization.name ? organization.name.toUpperCase() : null })

    rows.push({ label: translate("resources.dashboard.number_of_devices_outside_organization_boundary.organization_address"), info: organization.site_formatted_address ? organization.site_formatted_address.toUpperCase() : null })

    rows.push({ label: translate("resources.dashboard.number_of_devices_outside_organization_boundary.boundary_radius"), info: organization.site_boundary_radius ? organization.site_boundary_radius : null })

    rows.push({ label: translate("resources.dashboard.number_of_devices_outside_organization_boundary.number_of_devices"), info: numberOfDevices ? numberOfDevices : null })


    caption = `${translate("resources.dashboard.number_of_devices_outside_organization_boundary.created_at")} ${getNowDateTimeToFullLocalString(locale)}`


    return (

        <InfoTable2Col rows={rows} lastCaption={caption} />

    )
}


const NumberOfDevicesOutsideOrganizationBoundary = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const organizationId = localStorage.getItem(USER_ORGANIZATION_ID);
    const [organization, setOrganization] = useState(null);
    const [numberOfDevices, setNumberOfDevices] = useState(null);



    useEffect(async () => {
        const { data } = await dataProvider.getOne('organizations', { id: organizationId });
        if (data?.id && data?.name) {
            setOrganization(data)
        }

        if (data && data.site_boundary_radius && data.site_latitude && data.site_longitude) {
            getNumberOfDevicesOutsideOrganizationBoundary(organizationId).then((res) => {
                if (res.status === 200) {
                    setNumberOfDevices(res.json)
                }
            })
        }


    }, []);

    if (!organization || !organization.site_boundary_radius || !organization.site_latitude || !organization.site_longitude) return null

    return (

        <Grid
            item
            sm={12}
            lg={6}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >
            <DashboardCard
                HeaderIcon={() => <Icon color={theme.palette.primary.dark} />}
                title={translate("resources.dashboard.titles.number_of_devices_outside_organization_boundary")}
                BodyComponent={() => <NumberOfDevicesOutsideOrganizationBoundaryBodyComponent organization={organization} numberOfDevices={numberOfDevices} />}
                onClick={() => { navigate(`/devices?favorite_search=${DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY}&organization_id=${organizationId}&organization_name=${organization?.name}`) }}
            />
        </Grid>
    )
}

export default NumberOfDevicesOutsideOrganizationBoundary;


