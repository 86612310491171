/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { green, red, grey } from '@material-ui/core/colors'
import { translate, useListContext, useTranslate } from 'react-admin'
import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography, CircularProgress, Box, useTheme, TableContainer } from '@mui/material'


//ticatag

const styles = {
    text: {
        textTransform: 'uppercase',
        fontSize: 10,
        height: 24,
    },
    ok: { backgroundColor: green[500], color: 'white' },
    nok: { backgroundColor: red[500], color: 'white' },
    okNormal: { backgroundColor: grey[500], color: 'white' },
}


const LoadingCircularProgress = ({ color }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress color={color} />
        </Box>
    );
}

const SensorTable = ({ classes, ...props }) => {

    const { label, setLastEmptyData } = props
    const { data, isLoading, isFetching } = useListContext()
    const translate = useTranslate();
    const theme = useTheme();



    // no data to display
    if (isLoading) {

        return <LoadingCircularProgress color="primary" />;
    }
    // no data to display
    if (isFetching) {

        return <LoadingCircularProgress color="success" />;
    }
    if (Array.isArray(data) && data.length === 0) {
        setLastEmptyData(new Date())
        return null;
    }
    if ((data === undefined || data === null)) {

        return null;
    }


    const TableHeader = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: "0px 0px 20px 0px" }}>
                <Typography variant='body2' fontWeight="800" pt={"2px"} mb={"2px"} ml={"10px"}> {translate(label)}</Typography>
            </Box>
        )
    }


    const TableComponent = () => {
        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <TableHeader />
                <TableContainer style={{ maxHeight: "320px" }}>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow>
                                <TableCell style={{ fontSize: "12px", padding: "5px" }}>{translate('resources.measures.date')}</TableCell>
                                <TableCell style={{ fontSize: "12px", padding: "5px" }}>{translate('resources.measures.status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {data.map((elem, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row" style={{ fontSize: "12px", padding: "5px" }}>
                                            {new Date(elem.timestamp).toLocaleDateString()} -{' '}
                                            {new Date(elem.timestamp).toLocaleTimeString()}
                                        </TableCell>
                                        <TableCell style={{ padding: "5px" }}>
                                            {(elem.status === 'clear' || elem.status === 'closed' || elem.status === 'off' || elem.status === 'none' || elem.status === 'still' || elem.status === 'good') &&
                                                <Chip
                                                    className={[classes.text, classes.ok]}
                                                    label={translate(`resources.measures.${elem.name}_status_${elem.status}`)}
                                                    style={{ fontSize: "10px" }}
                                                />
                                            }
                                            {(elem.status === 'open' || elem.status === 'detected' || elem.status === 'on' || elem.status === 'moving' || elem.status === 'bad') &&
                                                <Chip
                                                    className={[classes.text, classes.nok]}
                                                    label={translate(`resources.measures.${elem.name}_status_${elem.status}`)}
                                                    style={{ fontSize: "10px" }}
                                                />
                                            }
                                            {(elem.click_type === 'double_click' || elem.click_type === 'click' || elem.click_type === 'hold') &&
                                                <Chip
                                                    className={[classes.text, classes.okNormal]}
                                                    label={translate(`resources.measures.${elem.name}_status_${elem.click_type}`)}
                                                    style={{ fontSize: "10px" }}
                                                />
                                            }

                                            {(elem.name === 'button' && !elem.click_type) &&
                                                <Chip
                                                    className={[classes.text, classes.okNormal]}
                                                    label={translate(`resources.measures.button_status_click`)}
                                                    style={{ fontSize: "10px" }}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }




    return (
        <Paper elevation={0} sx={{
            backgroundColor: `${theme.palette.background.paper} !important`,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            margin: 0,
            borderRadius: "12px",
            padding: "20px",
            width: '100%',
            minHeight: '300px',
            maxHeight: '400px'
        }}>
            <TableComponent />

        </Paper>
    )

}

export default withStyles(styles)(SensorTable);
