import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { useLocaleState, useTranslate } from 'react-admin'
import DeviceSubscriptionsSummary from "./DeviceSubscriptionsSummary";
import NumberOfDevicesInAccount from "./NumberOfDevicesInAccount";
import SmsCreditSummary from "./SmsCreditSummary";
import LocationsSummary from "./LocationsSummary";
import NumberOfNonAcknowledgeAlerts from "./NumberOfNonAcknowledgeAlerts";
import { useEffect, useState } from "react";
import { getDevicesSummary, getUserUiSetup } from "../../rest/restClient";
import numberOfDevicesByProducts from "./NumberOfDevicesByProducts";
import NumberOfAlerts from "./NumberOfAlerts";
import NumberOfStationaryDevices from "./NumberOfStationaryDevices";
import NumberOfDevicesOutsideOrganizationBoundary from "./NumberOfDevicesOutsideOrganizationBoundary";
import BroadcastMessages from "./BroadcastMessages";
import { DASHBOARD_WIDGETS_KEYS, USER_ID, USER_UI_SETUP_ACTIVE_DASHBOARD_WIDGETS, USER_USER_UI_SETUP_FETCHED_AT } from "../configuration/actions";







const Dashboard = ({ }) => {
    const userId = localStorage.getItem(USER_ID)
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    const [devicesSummary, setDevicesSummary] = useState(null);
    const [activeDashboardWidgets, setActiveDashboardWidgets] = useState(null);

    useEffect(async () => {
        getDevicesSummary().then((res) => {
            if (res.status === 200) {
                setDevicesSummary(res.json)
            }
        })

        getUserUiSetup(userId).then((res) => {
            if (res && res.status === 200 && res.json && res.json.active_dashboard_widgets) {
                localStorage.setItem(USER_UI_SETUP_ACTIVE_DASHBOARD_WIDGETS, res.json.active_dashboard_widgets)
                return (res.json.active_dashboard_widgets)
            } else {
                return DASHBOARD_WIDGETS_KEYS
            }
        }).then((actW) => {
            setActiveDashboardWidgets(actW)
        })


    }, [])

    if (!activeDashboardWidgets || !devicesSummary) return null

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.dashboard.name')}
            </Typography>
            <Grid
                container
                spacing={isSmall ? 0 : 1}
                sx={{
                    margin: '0px',
                    width: "100%",
                    backgroundColor: theme.palette.background.default,
                }}
            >


                <BroadcastMessages />

                <Grid
                    item
                    sm={12}
                    md={6}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >
                    <Grid
                        container
                        spacing={isSmall ? 0 : 1}
                        sx={{
                            margin: '0px',
                            width: "100%",
                            backgroundColor: theme.palette.background.default,
                        }}
                    >
                        {
                            activeDashboardWidgets.includes("number_of_devices_in_account") &&
                            <NumberOfDevicesInAccount devicesSummary={devicesSummary} />

                        }
                        {
                            activeDashboardWidgets.includes("number_of_alerts") &&
                            <NumberOfAlerts />

                        }
                        {
                            activeDashboardWidgets.includes("sms_credit_summary") &&
                            <SmsCreditSummary />

                        }
                        {
                            activeDashboardWidgets.includes("device_subscriptions_summary") &&
                            <DeviceSubscriptionsSummary />

                        }
                        {
                            activeDashboardWidgets.includes("number_of_stationary_devices") &&
                            <NumberOfStationaryDevices />

                        }
                        {
                            activeDashboardWidgets.includes("number_of_devices_outside_organization_boundary") &&
                            <NumberOfDevicesOutsideOrganizationBoundary />

                        }

                    </Grid>
                </Grid>
                {
                    activeDashboardWidgets.includes("locations_summary") &&
                    <Grid
                        item
                        sm={12}
                        md={6}
                        sx={{
                            width: "100%",
                            margin: isSmall ? "8px 0px 0px 0px" : '0px',
                        }} >
                        <Grid
                            container
                            spacing={isSmall ? 0 : 1}
                            sx={{
                                margin: '0px',
                                width: "100%",
                                backgroundColor: theme.palette.background.default,
                            }}
                        >

                            <LocationsSummary />
                        </Grid>
                    </Grid>

                }


                {activeDashboardWidgets.includes("number_of_devices_in_account_by_products") &&
                    numberOfDevicesByProducts({
                        devicesSummary,
                        translate, locale, theme, isSmall
                    })
                }





            </Grid >
        </>
    )
}

export default Dashboard;