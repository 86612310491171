import { Box, Typography, useTheme } from "@mui/material";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Confirm,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    TextInput,
    useNotify,
    useTranslate,
    useLocaleState
} from 'react-admin'
import { useController } from "react-hook-form";
import { autocompleteInputCommonStyle, selectInputCommonStyle, textInputCommonStyle, dateTimeInputCommonStyle } from "../../themes/commonStyles";
import DropZoneContentIcon from "./DropZoneContentIcon";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { DateTimePicker } from "@mui/x-date-pickers";


const DateTimeInput2 = ({ source, defaultValue, sx }) => {
    const input = useController({ name: source, defaultValue: defaultValue });
    const theme = useTheme();
    const [locale, setLocale] = useLocaleState();

    return (
        <span>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DateTimePicker
                    label={""}
                    value={input.field.value}
                    onChange={input.field.onChange}
                    ampm={false}
                    sx={sx}

                />
            </LocalizationProvider>
        </span>
    );
};




const styledFormInputs = (inputsData) => {
    const inputs = []
    const theme = useTheme();
    const translate = useTranslate();

    const notify = useNotify();

    if (inputsData !== null && Array.isArray(inputsData)) {
        inputsData.forEach(input => {
            inputs.push(
                <Typography color="text.main" sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "center",
                    margin: (input.type === "reference" ||
                        input.type === "referenceAutocompleteArrayInput" ||
                        input.type === "referenceSelectArrayInput"

                    ) ?
                        "0px 0px 38px 0px" : "0px 0px 8px 0px"
                }}
                >
                    {`${input.label}${input.required === false ? ` (${translate('resources.common.optional')})` : ""} `}
                </Typography>
            )

            if (input.type === "captionMessage" && input.captions && Array.isArray(input.captions)) {
                input.captions.map((c, idx) => {
                    inputs.push(
                        <Typography
                            key={idx}
                            color="text.main"
                            sx={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textAlign: "center",
                                margin: (idx === (input.captions.length - 1)) ? "0px 0px 25px 0px" : "0px 0px 8px 0px"
                            }}
                        >
                            {c}
                        </Typography>
                    )
                })

            }

            if (input.type === "text") {
                inputs.push(
                    <TextInput
                        source={input.name}
                        validate={input.validate}
                        variant='outlined'
                        disabled={input.disabled === true ? true : false}
                        sx={textInputCommonStyle({ theme, width: input.width })}
                    />
                )
            }

            if (input.type === "password") {
                inputs.push(
                    <TextInput
                        source={input.name}
                        validate={input.validate}
                        variant='outlined'
                        type='password'
                        disabled={input.disabled === true ? true : false}
                        sx={textInputCommonStyle({ theme, width: input.width })}
                    />
                )
            }


            if (input.type === "boolean") {
                inputs.push(
                    <BooleanInput
                        source={input.name}
                        validate={input.validate}
                        variant='outlined'
                        disabled={input.disabled === true ? true : false}
                        sx={textInputCommonStyle({ theme, width: input.width })}
                    />
                )
            }

            if (input.type === "number") {
                inputs.push(
                    <NumberInput
                        source={input.name}
                        validate={input.validate}
                        variant='outlined'
                        disabled={input.disabled === true ? true : false}
                        sx={textInputCommonStyle({ theme, width: input.width })}
                        format={input.format}
                    />
                )
            }

            if (input.type === "select") {
                inputs.push(
                    <SelectInput
                        defaultValue={input.defaultValue}
                        source={input.name}
                        validate={input.validate}
                        choices={input.choices}
                        variant='outlined'
                        disabled={input.disabled === true ? true : false}
                        sx={selectInputCommonStyle({ theme, width: input.width, height: input.height })}
                    />
                )
            }

            if (input.type === "selectArrayInput") {
                inputs.push(
                    <SelectArrayInput
                        source={input.name}
                        choices={input.choices}
                        matchSuggestion={() => true} label={input.label}
                        disabled={input.disabled === true ? true : false}
                        variant='outlined'
                        sx={selectInputCommonStyle({ theme, width: input.width, height: input.height })}
                    />
                )
            }


            if (input.type === "reference") {
                inputs.push(
                    <ReferenceInput source={input.name} reference={input.reference} filter={input.filter} alwaysOn >
                        <AutocompleteInput
                            id="outlined-basic-size-small"
                            disablePortal
                            optionText={input.optionText}
                            optionValue={input.optionValue}
                            matchSuggestion={() => true} label={input.label}
                            disabled={input.disabled === true ? true : false}
                            sx={autocompleteInputCommonStyle({ theme, width: input.width, height: input.height })}
                        />
                    </ReferenceInput>
                )
            }

            if (input.type === "referenceAutocompleteArrayInput") {
                inputs.push(
                    <ReferenceArrayInput source={input.name} reference={input.reference} filter={input.filter} alwaysOn >
                        <AutocompleteArrayInput
                            id="outlined-basic-size-small"
                            disablePortal
                            optionText={input.optionText}
                            optionValue={input.optionValue}
                            matchSuggestion={() => true} label={input.label}
                            disabled={input.disabled === true ? true : false}
                            sx={autocompleteInputCommonStyle({ theme, width: input.width, height: input.height })}
                        />
                    </ReferenceArrayInput>
                )
            }

            if (input.type === "referenceSelectArrayInput") {
                inputs.push(
                    <ReferenceArrayInput source={input.name} reference={input.reference} filter={input.filter} alwaysOn >
                        <SelectArrayInput
                            id="outlined-basic-size-small"
                            disablePortal
                            optionText={input.optionText}
                            optionValue={input.optionValue}
                            matchSuggestion={() => true}
                            label={input.label}
                            disabled={input.disabled === true ? true : false}
                            sx={autocompleteInputCommonStyle({ theme, width: input.width, height: input.height })}
                        />
                    </ReferenceArrayInput>
                )
            }

            if (input.type === "dateTime") {
                inputs.push(
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                        <DateTimeInput2
                            label={input.label}
                            source={input.name}
                            defaultValue={input.defaultValue}
                            locales={"fr"}
                            ampm={false}
                            sx={dateTimeInputCommonStyle({ theme, width: input.width })}
                        />
                    </LocalizationProvider>
                )
            }
            if (input.type === "file") {
                const DropZoneContent = () => {
                    return (<Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px"
                    }}>
                        <DropZoneContentIcon />
                        <Typography color="text.main" sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            textAlign: "center",
                            margin: "14px"
                        }}>
                            {translate('ra.input.image.upload_single')}
                        </Typography>
                    </Box>)
                }
                inputs.push(
                    <ImageInput
                        key="ImgInput"
                        source={input.name}
                        accept={input.accept}
                        maxSize={input.maxSize ? input.maxSize : 5000000}
                        multiple={input.multiple ? input.multiple : false}
                        options={
                            {
                                onDropRejected: (fileRejections) => {
                                    if (fileRejections && Array.isArray(fileRejections)) {
                                        fileRejections.forEach((file) => {
                                            if (file.errors && Array.isArray(file.errors)) {
                                                file.errors.forEach((err) => {
                                                    if (err.code === "file-too-large") {
                                                        if (err.message === "File is larger than 500000 bytes") {
                                                            notify(translate("resources.errors.file_is_larger_than_500_KB"), { type: 'error' });
                                                        } else if (err.message === "File is larger than 200000 bytes") {
                                                            notify(translate("resources.errors.file_is_larger_than_200_KB"), { type: 'error' });
                                                        } else if (err.message === "File is larger than 5000000 bytes") {
                                                            notify(translate("resources.errors.file_is_larger_than_5_MB"), { type: 'error' });
                                                        } else if (err.message === "File is larger than 2000000 bytes") {
                                                            notify(translate("resources.errors.file_is_larger_than_2_MB"), { type: 'error' });
                                                        } else if (err.message === "File is larger than 1000000 bytes") {
                                                            notify(translate("resources.errors.file_is_larger_than_1_MB"), { type: 'error' });
                                                        } else {
                                                            notify(`Error: ${err.message}`, { type: 'error' });
                                                        }
                                                    }
                                                    if (err.code === "file-invalid-type") {
                                                        notify(translate(`Error: ${err.message}`));
                                                    }
                                                });
                                            }
                                        });
                                    }
                                },
                            }
                        }
                        fullWidth
                        label={input.subLabel}
                        placeholder={<DropZoneContent />}
                        sx={{
                            "& .RaLabeled-label": {
                                margin: "0px 0px 8px 0px",
                                padding: "0px"
                            },
                            "& .RaFileInput-dropZone": {
                                margin: "0px 0px 8px 0px",
                                padding: "0px"
                            },
                            "& .RaFileInput-dropZone": {
                                height: "170px",
                                width: input.width ? input.width : "100%",
                                background: "none",
                                border: 1,
                                borderColor: theme.palette.divider,
                                borderStyle: 'dashed'
                            }
                        }}
                        validateFileRemoval={input.validateFileRemoval}
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                )
                if (input.Confirm) {
                    inputs.push(
                        <input.Confirm />
                    )
                }

            }
        })
    }

    return inputs
}


export default styledFormInputs