// in src/comments.js
import React from 'react';
import { Card, Box, CardContent, Grid, TableBody, useTheme, Typography, ButtonBase, Tooltip, Table } from '@mui/material'
import AvatarField from '../../../ui/field/AvatarField'
import { DateField, useTranslate, TextField, useListContext, usePermissions, useLocaleState } from 'react-admin';
import { CardDetailRow } from '../../../ui/Common';
import { useNavigate } from 'react-router-dom';


const isFired = (device) => {

    if (device &&
        device.last_nack_notification_log_date
    ) {
        const date = new Date(device.last_nack_notification_log_date)
        return date < new Date()
    }
    return false
}

const NotificationLogSubjectField = ({ notificationLog }) => {
    const translate = useTranslate();
    if (notificationLog &&
        notificationLog.subject
    ) {
        const type = translate(`resources.notifications.${notificationLog.subject}`)
        return (
            <Typography
                sx={{ fontWeight: notificationLog.acknowledged === false ? 550 : 400 }}
            >
                {type.length > 22 ? `${type.substring(0, 22)}...` : type}
            </Typography>
        )
    }
    return null
}


const DeviceCardContent = ({ device }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const [locale, setLocale] = useLocaleState();

    return (

        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>


                    {device.last_notification_logs &&
                        Array.isArray(device.last_notification_logs) &&
                        device.last_notification_logs.map((n, idx) =>
                            <CardDetailRow
                                label={
                                    <DateField
                                        record={n}
                                        source="timestamp"
                                        type="date"
                                        showTime
                                        locales={locale}
                                        options={{
                                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: false,
                                        }}
                                        sx={{
                                            fontSize: "12px",
                                        }} />
                                }
                                ValueComponent={() => <NotificationLogSubjectField notificationLog={n} />} />
                        )
                    }

                </TableBody>
            </Table>
        </Box>
    );
}


const getAvatar = record => {
    const defaultAvatar = <AvatarField avatarUrl="https://ticatagsas.s3.amazonaws.com/57f3315c-e601-410a-9749-57fa11e123fc" size="45" />
    if (!record)
        return defaultAvatar

    if (record.image && record.image.src)
        return <AvatarField avatarUrl={record.image.src} size="45px" />

    if (record.product && record.product.image && record.product.image.src)
        return <AvatarField avatarUrl={record.product.image.src} size="45px" />

    return defaultAvatar
}


const DeviceCardHeader = ({ permissions, device }) => {
    const translate = useTranslate();
    const theme = useTheme();

    if (!device) return null;

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    {getAvatar(device)}
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{device.name ? `${device.name}` : ""}</Typography>
            </Box>

            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 400, fontSize: "12px", lineHeight: "20px" }}>{`ID: ${device.mac_address ? device.mac_address : device.serial_number}`}</Typography>
            </Box>


        </Box>

    );
}


const MobileGrid = ({ isSmall }) => {

    const { data, isLoading } = useListContext();
    const translate = useTranslate();
    const theme = useTheme();
    const navigate = useNavigate();

    const permissionsHook = usePermissions();
    const permissions = permissionsHook.permissions;
    if (isLoading || !data || (data?.length <= 0)) return null;

    const greenBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
    const redBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((device, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: isSmall ? "0px 0px 8px 0px" : "0px 8px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px",
                            background: {
                                xs: isFired(device) ? redBg : greenBg,
                                sm: isFired(device) ? redBg : greenBg,
                            }
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.devices.browse_notification_log`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { navigate(`/notification_logs/${device.device_id}/history`) }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <DeviceCardHeader device={device} permissions={permissions} />
                                    <DeviceCardContent sx={{
                                        width: "100%",
                                    }} device={device} />


                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );
}




export default MobileGrid;