import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin'
import { AUTHORITIES, CLIENT_ACCESS_TOKEN, CLIENT_ACCESS_TOKEN_EXPIRY_DATE, ROLE_ORGA_USER, USER_ID, USER_ACCESS_TOKEN, USER_ACCESS_TOKEN_EXPIRY_DATE, USER_REFRESH_TOKEN, USER_REFRESH_TOKEN_EXPIRY_DATE, ROLE_SUPER_ADMIN, USER_ORGANIZATION_ID, USER_NAME, USER_ORGANIZATION_NAME, USER_FAV_LANGUAGE, USER_FAV_LANGUAGE_IS_SET, RO_ID } from './pages/configuration/actions'
import { toSafeInteger } from 'lodash'
import axios from 'axios'
import { Buffer } from "buffer";
import qs from 'qs'
import jwt_decode from 'jwt-decode'
import { organizationName } from './rest/restClient';
import { supportedLanguage } from './i18nProvider';

const authProvider = async (type, params) => {

    // called when the user attempts to log in < UserNotAlowedAlert />
    if (type === AUTH_LOGIN) {
        // accept all username/password combinations
        return Promise.resolve()
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {

        const authServerUrl = process.env.REACT_APP_AUTH_URL;
        const url = `${authServerUrl}/logout_sso`;
        const authorization = 'Bearer ' + (localStorage.getItem(USER_ACCESS_TOKEN) || '')
        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: authorization,
            },
        })
            .then(() => {
                deleteAllCookiesData();
                return Promise.resolve();
            })
            .catch(error => {
                console.log(error)
            })


    }

    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status, message } = params;
        if (status === 401 &&
            (message === "JWT has expired" ||
                message === "Please login first"
            )
        ) {
            return checkUserAccessToken().then((ok) => {
                if (ok) {
                    setTimeout(() => {
                        window.location.href = "/dashboard"
                    }, 100)
                    return Promise.resolve();
                } else {
                    return Promise.reject();
                }
            })
        }
        return Promise.resolve()
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {

        return checkUserAccessToken().then((ok) => {
            if (ok) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        })

    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem(AUTHORITIES)
        return role ? Promise.resolve(role) : ROLE_ORGA_USER
    }



    return Promise.reject('Unknown method')
}




export async function waitLogged() {
    let isLogged = false

    try {
        let count = 10

        while (isLogged === null && count > 0) {
            await sleep(500)

            isLogged = await localStorage.getItem(USER_ID)
            count--
        }
    } catch (error) { }
    return isLogged ? true : false
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

function deleteAllCookiesData() {

    sessionStorage.clear()

    localStorage.clear()


    caches.keys().then(keys => {
        keys.forEach(key => caches.delete(key))
    })

    if (!('indexedDB' in window)) {
        console.log("This browser doesn't support IndexedDB");
        return;
    }

    indexedDB.databases().then(dbs => {
        dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    })

    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }




}



const checkUserAccessToken = () => {
    return (new Promise((resolve, reject) => {

        // save refresh exipry date for the first time
        if (!localStorage.getItem(USER_REFRESH_TOKEN_EXPIRY_DATE)) {
            saveUserRefreshTokenExpiryDate();
        }

        return needToRefreshUserToken().then((mustRefresh) => {

            // Refresh
            if (mustRefresh) {
                refreshUserToken().then((refreshed) => {
                    if (refreshed) {
                        return resolve(true);
                    } else {
                        return resolve(false);
                    }
                })

            }
            // no need to refresh
            else {

                const userAccessTokenExpiryDate = new Date(toSafeInteger(localStorage.getItem(USER_ACCESS_TOKEN_EXPIRY_DATE)) * 1000);
                if (localStorage.getItem(USER_ID)
                    && localStorage.getItem(USER_ACCESS_TOKEN)
                    && localStorage.getItem(USER_REFRESH_TOKEN)
                    && userAccessTokenExpiryDate > new Date()
                ) {
                    return resolve(true);
                } else {
                    return resolve(false);
                }
            }
        }).catch((err) => {
            return resolve(false);
        })


    }))
};


const needToRefreshUserToken = () => {
    return (new Promise((resolve, reject) => {

        const userRefreshToken = localStorage.getItem(USER_REFRESH_TOKEN);
        const userAccessTokenExpiryDate = new Date(toSafeInteger(localStorage.getItem(USER_ACCESS_TOKEN_EXPIRY_DATE)) * 1000);
        const userRefreshTokenExpiryDate = new Date(toSafeInteger(localStorage.getItem(USER_REFRESH_TOKEN_EXPIRY_DATE)) * 1000);
        const currentDate = new Date();
        const threeMinutesLater = new Date(currentDate.getTime() + 3 * 60 * 1000);


        if (userRefreshToken &&
            (threeMinutesLater > userAccessTokenExpiryDate) &&
            (userRefreshTokenExpiryDate > currentDate)
        ) {
            return resolve(true);
        } else {
            return resolve(false);
        }


    }))
};


const refreshUserToken = () => {
    return (new Promise(async (resolve, reject) => {

        const userRefreshToken = localStorage.getItem(USER_REFRESH_TOKEN);
        const authServerUrl = process.env.REACT_APP_AUTH_URL;
        const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
        const headers = {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`
        };
        const url = `${authServerUrl}/oauth2/token?grant_type=refresh_token&refresh_token=${userRefreshToken}`;



        axios.post(url, null, { headers })
            .then(async (response) => {
                if (response?.status === 200) {
                    const token = response.data;
                    return loginSuccessWrapper({ accessToken: token.access_token, refreshToken: token.refresh_token }).then((refreshed) => {
                        if (refreshed) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })

                } else {
                    resolve(false);
                }

            }).catch((err) => {
                resolve(false);
            })




    }))
};



const loginSuccessWrapper = ({ accessToken, refreshToken }) => {
    return new Promise(async (resolve, reject) => {

        const decodedAccessToken = jwt_decode(accessToken)
        const userName = decodedAccessToken.user_name
        const lang = decodedAccessToken.language
        const accessTokenExpiryDate = decodedAccessToken.exp
        const userId = decodedAccessToken.userId
        const organizationId = decodedAccessToken.organizationId
        const authorities = decodedAccessToken.authorities
        const userRootOrganization = decodedAccessToken.rootOrganizationId

        if (
            (authorities[0].toLowerCase() === ROLE_SUPER_ADMIN) ||

            (localStorage.getItem(RO_ID) &&
                (userRootOrganization === localStorage.getItem(RO_ID))
            )
        ) {

            localStorage.setItem(USER_ACCESS_TOKEN, accessToken)
            localStorage.setItem(USER_ACCESS_TOKEN_EXPIRY_DATE, accessTokenExpiryDate)
            localStorage.setItem(USER_REFRESH_TOKEN, refreshToken)
            localStorage.setItem(USER_ID, userId)
            localStorage.setItem(USER_ORGANIZATION_ID, organizationId)
            localStorage.setItem(USER_NAME, userName)
            localStorage.setItem(AUTHORITIES, authorities[0].toLowerCase())


            if (lang && supportedLanguage(lang) != null) {
                localStorage.setItem(USER_FAV_LANGUAGE, supportedLanguage(lang))
                localStorage.setItem(USER_FAV_LANGUAGE_IS_SET, false)
            }


            organizationName(organizationId)
                .then(organization => {
                    localStorage.setItem(USER_ORGANIZATION_NAME, organization.json.name)
                })
                .catch(err => console.log(JSON.stringify(err)))


            setTimeout(() => {
                saveUserRefreshTokenExpiryDate();
            }, 1000)

            resolve(true);
        } else {
            // user not allowed on root organization
            resolve(false);
        }


    });
}





const saveUserRefreshTokenExpiryDate = () => {
    const userRefreshToken = localStorage.getItem(USER_REFRESH_TOKEN);
    const authServerUrl = process.env.REACT_APP_AUTH_URL;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
    const headers = {
        'Authorization': `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`
    };
    const url = `${authServerUrl}/oauth2/introspect`;


    if (!userRefreshToken) {
        console.error("No refresh token found in local storage.");
        return;
    }


    const data = qs.stringify({
        token: userRefreshToken,
    });

    axios.post(url, data, { headers })
        .then(response => {
            if (response.data && response.data.active) {
                localStorage.setItem(USER_REFRESH_TOKEN_EXPIRY_DATE, response.data.exp);
            } else {
                console.log("Refresh Token is inactive or expired.");
            }
        })
        .catch(error => {
            console.error("Error during introspection");
        });

};




export default authProvider;