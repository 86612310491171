import { getUserUiSetup } from "../../rest/restClient"

export const CHANGE_THEME = 'theme'
export const CHANGE_MARKER = 'marker'
export const SELECT_RIGHTMENU = 'selectRightMenu'
export const SELECT_LEFTMENU = 'selectLeftMenu'
export const ROUTING_MODE = 'routingMode'
export const DISABLE_CLUSTERING_AT_ZOOM = 'disableClusteringAtZoom '
export const NBR_HISTORY = 'nbrHistory'
export const DATE_INF = 'dateInf'
export const DATE_SUP = 'dateSup'
export const API_KEY = 'API_KEY'
export const AUTHORITIES = 'role'
export const VERSION_CHECK_DATE = 'version_check_date'
export const ROLE_SUPER_ADMIN = 'role_super_admin'
export const ROLE_ORGA_ADMIN = 'role_orga_admin'
export const ROLE_ORGA_USER = 'role_orga_user'
export const ROLE_USER = 'role_user'
export const USER_ACCESS_TOKEN = 'user_access_token'
export const USER_ACCESS_TOKEN_EXPIRY_DATE = 'user_access_token_expiry_date'
export const USER_REFRESH_TOKEN = 'user_refresh_token'
export const USER_REFRESH_TOKEN_EXPIRY_DATE = 'user_refresh_token_expiry_date'
export const USER_ORGANIZATION_ID = 'user_org_id'
export const USER_ORGANIZATION_NAME = 'user_org_name'
export const USER_ID = 'user_id'
export const USER_NAME = 'user_name'
export const USER_ALLOWED = 'user_allowed'
export const USER_FAV_LANGUAGE = 'fav_lang'
export const USER_FAV_LANGUAGE_IS_SET = 'fav_lang_is_set'
export const CLIENT_ACCESS_TOKEN = 'client_access_token'
export const CLIENT_ACCESS_TOKEN_EXPIRY_DATE = 'client_access_token_expiry'
export const CLIENT_REFRESH_TOKEN = 'client_refresh_token'
export const CLIENT_REFRESH_TOKEN_EXPIRY_DATE = 'client_refresh_token_expiry'

export const RO_REFRESHED_AT = 'ro_refreshed_at'


export const RO_ID = 'ro_id'
export const RO_NAME = 'ro_name'
export const RO_APP_TITLE = 'ro_app_title'
export const RO_THEME_LIGHT_PRIMARY_MAIN = 'ro_th_l_pr_main'
export const RO_THEME_LIGHT_PRIMARY_DARK = 'ro_th_l_pr_dark'
export const RO_THEME_LIGHT_PRIMARY_LIGHT = 'ro_th_l_pr_light'
export const RO_THEME_LIGHT_BACKGROUND_DEFAULT = 'ro_th_l_bkg_default'
export const RO_THEME_DARK_PRIMARY_MAIN = 'ro_th_d_pr_main'
export const RO_THEME_DARK_PRIMARY_DARK = 'ro_th_d_pr_dark'
export const RO_THEME_DARK_PRIMARY_LIGHT = 'ro_th_d_pr_light'
export const RO_THEME_DARK_BACKGROUND_DEFAULT = 'ro_th_d_bkg_default'
export const RO_LOGO_URL = 'ro_logo_url'
export const RO_FAVICON_URL = 'ro_favicon_url'
export const RO_ICON_96x96_URL = 'ro_icon_96x96_url'
export const RO_ICON_180x180_URL = 'ro_icon_180x180_url'
export const RO_ICON_192x192_URL = 'ro_icon_192x192_url'
export const RO_ICON_512x512_URL = 'ro_icon_512x512_url'
export const RO_COMPANY_URL = 'ro_company_url'
export const RO_CONTACT_PAGE_URL = 'ro_contact_page_url'


export const RO_FIREBASE_API_KEY = 'ro_firebase_api_key'
export const RO_FIREBASE_AUTH_DOMAIN = 'ro_firebase_auth_domain'
export const RO_FIREBASE_PROJECT_ID = 'ro_firebase_project_id'
export const RO_FIREBASE_STORAGE_BUCKET = 'ro_firebase_storage_bucket'
export const RO_FIREBASE_MESSAGING_SENDER_ID = 'ro_firebase_messaging_sender_id'
export const RO_FIREBASE_APP_ID = 'ro_firebase_app_id'
export const RO_FIREBASE_MEASUREMENT_ID = 'ro_firebase_measurement_id'



export const DEVICE_LIST_SHOW_IMAGE_COL = 'device_list_show_image_col'

export const USER_USER_UI_SETUP_FETCHED_AT = 'uuistp_fetched_at'
export const USER_UI_SETUP_HIDDEN_MESSAGES = 'uuistp_hidden_messages'
export const USER_UI_SETUP_ACTIVE_DASHBOARD_WIDGETS = 'uuistp_active_dashboard_widgets'



export const PDF_REPORT_LOCATION_HISTORY = 'pdf_report_location_history'



export const LAST_FETCH_OF_GET_DEVICES_BY_STANDBY = 'last_fetch_of_get_devices_by_standby'
export const STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS = 'stationary_devices_for_more_than_10_days'
export const STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS = 'stationary_devices_for_more_than_20_days'
export const STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS = 'stationary_devices_for_more_than_30_days'
export const STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS = 'stationary_devices_for_more_than_50_days'
export const STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS = 'stationary_devices_for_more_than_70_days'


export const DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY = 'devices_outside_organization_boundary'


export const DASHBOARD_WIDGETS_KEYS = [
    "number_of_devices_in_account",
    "number_of_devices_in_account_by_products",
    "sms_credit_summary",
    "locations_summary",
    "number_of_stationary_devices",
    "number_of_devices_outside_organization_boundary",
]




const getUiSetup = async () => {
    const userId = localStorage.getItem(USER_ID);
    const hMsgs = await getUserUiSetup(userId).then((res) => {
        if (res && res.status === 200 && res.json && res.json.hidden_messages) {
            localStorage.setItem(USER_USER_UI_SETUP_FETCHED_AT, new Date().toISOString())
            localStorage.setItem(USER_UI_SETUP_HIDDEN_MESSAGES, res.json.hidden_messages)
            return (res.json.hidden_messages)
        } else {
            return []
        }
    })
    return hMsgs
}


export const getUiHiddenMessages = async () => {
    if (localStorage.getItem(USER_USER_UI_SETUP_FETCHED_AT) !== undefined &&
        localStorage.getItem(USER_USER_UI_SETUP_FETCHED_AT) !== null) {
        const tenMinutesAfterCheckDate = new Date(localStorage.getItem(USER_USER_UI_SETUP_FETCHED_AT)).getTime() + 600000;
        const now = new Date().getTime();
        if (now > tenMinutesAfterCheckDate) {
            localStorage.setItem(USER_USER_UI_SETUP_FETCHED_AT, new Date().toISOString())
            const hMsgs = await getUiSetup();
            return hMsgs;
        } else {
            return localStorage.getItem(USER_UI_SETUP_HIDDEN_MESSAGES).split(',')
        }
    }

    const hMsgs = await getUiSetup();
    return hMsgs;


}

export const changeConfig = (type, value) => {
    if (value === false) {
        localStorage.setItem(type, 'false')
    } else if (value === true) {
        localStorage.setItem(type, 'true')
    } else {
        localStorage.setItem(type, value)
    }


    return {
        type,
        payload: value,
    }
}

