import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import style from 'jss-material-ui'
import { useTranslate, Button, TextInput } from 'react-admin'

import {
    CircularProgress,
    Grid,
    useMediaQuery,
    useTheme,
    TextField,
    Autocomplete,
    Typography,
} from '@mui/material'

import { BingProvider } from 'leaflet-geosearch'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import MapDraw from './mapDraw'
import { autocompleteInputCommonStyle, textFieldCommonStyle, textInputCommonStyle } from '../../themes/commonStyles'
import { reverseGeocode } from '../../rest/restClient'
import StandardDialog from '../../ui/dialog/StandardDialog'



const provider = new BingProvider({
    params: {
        key: process.env.REACT_APP_BING_API_KEY,
    },
})





const SMap = style(MapContainer)({
    root: {
        margin: 0,
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        height: 350,
        width: 350,
    },
})


const getZoomFromRadius = (radius) => {
    let zoom = 9

    if (radius < 100) {
        zoom = 18
    } else if (radius < 250) {
        zoom = 16
    } else if (radius < 1000) {
        zoom = 14
    } else if (radius < 1500) {
        zoom = 13
    } else if (radius < 3000) {
        zoom = 12
    } else if (radius <= 7500) {
        zoom = 11
    } else if (radius <= 10000) {
        zoom = 10
    } else if (radius <= 20000) {
        zoom = 9
    } else if (radius <= 50000) {
        zoom = 8
    } else if (radius <= 80000) {
        zoom = 7
    } else if (radius <= 100000) {
        zoom = 6
    } else if (radius >= 100000) {
        zoom = 5
    }
    return zoom
}

const BodyComponent = ({ record, setOpenDialog }) => {

    const translate = useTranslate();
    const theme = useTheme()
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const [loading, setLoading] = useState(false);
    const { field: radiusField } = useController({ name: "site_boundary_radius", defaultValue: 200 });
    const { field: longitudeField } = useController({ name: "site_longitude", defaultValue: 2.2950 });
    const { field: latitudeField } = useController({ name: "site_latitude", defaultValue: 48.8738 });
    const { field: formattedAddressField } = useController({ name: "site_formatted_address", defaultValue: "Place Charles de Gaulle, 75008 Paris, France" });
    const [center, setCenter] = useState({ lat: record?.site_latitude ? record.site_latitude : 48.8738, lng: record?.site_longitude ? record.site_longitude : 2.2950 });
    const [place, setPlace] = useState(record?.site_formatted_address ? record.site_formatted_address : "Place Charles de Gaulle, 75008 Paris, France");
    const [places, setPlaces] = useState([record?.site_formatted_address ? record.site_formatted_address : "Place Charles de Gaulle, 75008 Paris, France"]);
    const [radius, setRadius] = useState(record?.site_boundary_radius ? record.site_boundary_radius : 200);
    const [zoom, setZoom] = useState(getZoomFromRadius(record?.site_boundary_radius ? record.site_boundary_radius : 200));




    // Handle input change and fetch places from API
    const handleInputChange = async (event, newInputValue) => {
        if (event === null) return;
        setPlace(newInputValue);
        if (newInputValue.length > 5) {
            setLoading(true);
            try {
                const response = await provider.search({ query: newInputValue })
                setPlaces(response);
            } catch (error) {
                console.error("Error fetching places:", error);
            } finally {
                setLoading(false);
            }
        } else {
            setPlaces([]);
        }
    };





    const handleGeofenceSubmit = () => {
        if (center && center.lat) latitudeField.onChange(center.lat)
        if (center && center.lng) longitudeField.onChange(center.lng)
        if (radius) radiusField.onChange(radius)
        if (place) formattedAddressField.onChange(place)
        setOpenDialog(false)
    };





    const onPlaceSelected = (event, suggestion) => {
        event.preventDefault()
        if (suggestion && suggestion.y && suggestion.x) {
            changeCenter({ lat: suggestion.y, lng: suggestion.x, address: suggestion.label }, 100)
        }

    };


    const reverseGeocoding = (latitude, longitude) => {
        try {

            reverseGeocode(latitude, longitude)
                .then(function (response) {
                    return response.json;
                }).then(function (data) {
                    setPlace(data.formatted_address)
                    setPlaces([data.formatted_address])
                });

        } catch (ex) {

        }
    }




    const changeCenter = ({ lat, lng, address }, radius) => {

        setCenter({ lat: lat, lng: lng })
        setRadius(radius)
        setPlace(address)
        setZoom(getZoomFromRadius(radius))
    };


    const onEdited = e => {
        const layer = e.layer
        const lat = layer._latlng.lat
        const lng = layer._latlng.lng
        changeCenter({ lat: lat, lng: lng, address: '' }, Math.round(layer._mRadius / 100) * 100)
        reverseGeocoding(lat, lng)

    };




    return (
        <Grid container sx={{ width: '100%' }}>

            <Grid item xs={12} sx={{ marginTop: isSmall ? "10px" : "0px" }}>
                <Autocomplete
                    options={places}
                    loading={loading}
                    getOptionLabel={(option) => option.label || ''}  // Customize according to your API response
                    onChange={onPlaceSelected}  // Trigger when an option is selected
                    inputValue={place}
                    onInputChange={handleInputChange}  // Handle typing to trigger API fetch
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Enter Address"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    sx={autocompleteInputCommonStyle({ theme, isSmall, width: isSmall ? "250px" : "400px" })}
                />

            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 500, fontSize: "12px", lineHeight: "24px", margin: "0px 0px 0px 5px" }}>
                    {`${translate('resources.addresses.radius_meters')} : ${radius}`}
                </Typography>

            </Grid>

            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <SMap
                    key={(center && zoom && radius ? center.lat + center.lng + zoom + radius : 0).toString()}
                    center={center}
                    zoom={zoom}
                    style={{ width: '100%', borderRadius: "12px" }}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapDraw onEdited={onEdited} editing={true} center={center} radius={radius} />
                </SMap>
            </Grid>

            <Button
                onClick={handleGeofenceSubmit}
                sx={{
                    margin: "20px 0px  0px 0px",
                    padding: "8px", fontSize: "14px", borderRadius: "5px",
                    color: theme.palette.background.paper,
                    backgroundColor: theme.palette.primary.main,
                    ":hover": {
                        color: theme.palette.background.paper,
                        backgroundColor: theme.palette.primary.main,
                    }
                }}>
                {translate('ra.action.confirm')}
            </Button>

        </Grid >
    )

}

const GeofenceInput = ({
    record,
    openDialog, setOpenDialog
}) => {

    return <StandardDialog
        open={openDialog}
        onClose={() => { }}
        fullWidth={false}
        BodyComponent={() => <BodyComponent record={record} setOpenDialog={setOpenDialog} />}

    />
}




export default GeofenceInput
