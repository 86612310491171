import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotify, useRefresh, useTranslate } from "react-admin";
import { getUserUiSetup, updateUserUiSetup } from "../../../rest/restClient";
import { DASHBOARD_WIDGETS_KEYS, USER_ID, USER_USER_UI_SETUP_FETCHED_AT } from "../../configuration/actions";
import { useNavigate } from "react-router-dom";

const UiSetupList = ({ }) => {
    const userId = localStorage.getItem(USER_ID)
    const theme = useTheme();
    const notify = useNotify();
    const navigate = useNavigate()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const [activeDashboardWidgets, setActiveDashboardWidgets] = useState([]);
    const [enableSave, setEnableSave] = useState(false);
    const [userUiSetup, setUserUiSetup] = useState({});



    const handleSave = () => {
        const patchUserUiSetup = {
            "active_dashboard_widgets": activeDashboardWidgets
        }
        updateUserUiSetup(userId, patchUserUiSetup).then((res) => {
            if (res && res.status === 200) {
                localStorage.removeItem(USER_USER_UI_SETUP_FETCHED_AT)
                notify(translate('resources.common.update_success'), 'info');
                navigate("/dashboard");
            }
        })

    }



    useEffect(async () => {


        const setUp = await getUserUiSetup(userId).then((res) => {
            if (res && res.status === 200 && res.json) {
                return (res.json)
            } else {
                return {}
            }
        })
        setUserUiSetup(setUp)
        if (setUp && setUp.active_dashboard_widgets) {
            setActiveDashboardWidgets(setUp.active_dashboard_widgets)

        }

    }, [])

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.ui_setup.name')}
            </Typography>
            <Grid
                container
                spacing={isSmall ? 0 : 1}
                sx={{
                    margin: '0px',
                    width: "100%",
                    backgroundColor: theme.palette.background.default,
                }}
            >

                <Grid
                    item
                    sm={12}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >

                    <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 500, fontSize: "18px", lineHeight: "42px", margin: "0px 0px 0px 0px" }}>
                        {translate('resources.ui_setup.dashboard')}
                    </Typography>
                </Grid>

                <Grid container xs={12} sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "10px"
                }}>
                    {userUiSetup &&
                        Array.isArray(userUiSetup.active_dashboard_widgets) &&
                        DASHBOARD_WIDGETS_KEYS.map((dW, idx) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    sx={{
                                        width: "100%",
                                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                                    }} >
                                    <FormControlLabel
                                        key={idx}
                                        control={<Checkbox checked={activeDashboardWidgets.includes(dW)} />}
                                        label={translate(`resources.dashboard.titles.${dW}`)}
                                        onChange={(event) => {
                                            setEnableSave(true)
                                            if (event.target.checked === true) {
                                                let newActiveDashboardWidgets = [...activeDashboardWidgets]
                                                newActiveDashboardWidgets.push(dW)
                                                setActiveDashboardWidgets(newActiveDashboardWidgets);
                                            } else {
                                                const index = activeDashboardWidgets.indexOf(dW);
                                                if (index > -1) {
                                                    let newActiveDashboardWidgets = [...activeDashboardWidgets]
                                                    newActiveDashboardWidgets.splice(index, 1)
                                                    setActiveDashboardWidgets(newActiveDashboardWidgets);
                                                }

                                            }
                                        }}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid
                    item
                    sm={12}
                    sx={{
                        width: "100%",
                        margin: "16px 0px 0px 0px",
                    }} >

                    <Button
                        variant="contained"
                        sx={{ margin: "2px 2px 2px 0px" }}
                        disabled={!enableSave}
                        onClick={handleSave}
                    >
                        {translate('ra.action.save')}
                    </Button>
                    <Button
                        variant="text"
                        sx={{ margin: "2px 0px 2px 16px" }}
                        onClick={() => {
                            if (userUiSetup && userUiSetup.active_dashboard_widgets) {
                                setActiveDashboardWidgets(userUiSetup.active_dashboard_widgets)
                            }
                            setEnableSave(false)
                        }}>
                        {translate('ra.action.cancel')}
                    </Button>
                </Grid>




            </Grid >
        </>
    )
}

export default UiSetupList;