import React, { useState } from 'react'

import {
    Edit,
    minLength,
    required,
    SaveButton,
    SimpleForm,
    Toolbar,
    useTranslate,
    useRedirect,
    useNotify,
    useRecordContext,
} from 'react-admin'
import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material';
import { isAdminPermissions, isSuperAdmin } from '../../../helper/resourceHelper';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';
import { USER_ORGANIZATION_ID } from '../../configuration/actions';
import { getOrganizationIdFromUrl } from '../../../utils/utils';
import GeofenceInput from '../GeofenceInput';



const validateRequired = [required('Champs obligatoire')]


const DrawGeofenceButton = ({ onClick }) => {
    const theme = useTheme();
    const iconUrl = `
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-x">
  <path stroke="none" d="M0 0h48v48H0z" fill="none" stroke-width="2"/>
  <path d="M17.12 7.38a18 18 0 0 0 -5.84 3.9"/>
  <path d="M7.38 17.12A18 18 0 0 0 6 24"/>
  <path d="M7.38 30.88a18 18 0 0 0 3.9 5.84"/>
  <path d="M17.12 40.62A18 18 0 0 0 24 42"/>
  <path d="M30.88 40.62a18 18 0 0 0 5.84 -3.9"/>
  <path d="M40.62 30.88A18 18 0 0 0 42 24"/>
  <path d="M40.62 17.12a18 18 0 0 0 -3.9 -5.84"/>
  <path d="M30.88 7.38A18 18 0 0 0 24 6"/>
  <path d="m28 28 -8 -8"/>
  <path d="m20 28 8 -8"/>
</svg>
    `
    return (
        <Button
            sx={{
                margin: "0px",
                padding: "5px",
                width: "60px",
                height: "60px",

            }}
            onClick={onClick}>
            <Box
                sx={{
                    margin: "0px",
                    padding: "0px",
                    borderRadius: "10px",
                    width: "50px",
                    height: "50px",
                    borderColor: `${theme.palette.text.primary} !important`,
                    border: 1,
                    borderWidth: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"

                }}
            >
                <SvgIcon sx={{ color: `${theme.palette.text.primary} !important` }} viewBox={"0 0 48 48"}>
                    <g dangerouslySetInnerHTML={{ __html: iconUrl }} />
                </SvgIcon>

            </Box>

        </Button>
    )
}
const OrganizationSite = () => {
    const translate = useTranslate();
    const [openGeofenceInputDialog, setOpenGeofenceInputDialog] = useState(false);
    const record = useRecordContext();
    if (!record) return null
    return ([
        <Typography
            color="text.main"
            sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                textAlign: "center",
                margin: "0px 0px 8px 0px"
            }}
        >
            {translate('resources.organization.site_draw_on_map')}
        </Typography>,
        <DrawGeofenceButton onClick={() => { setOpenGeofenceInputDialog(true) }} />,
        <GeofenceInput
            record={record}
            openDialog={openGeofenceInputDialog}
            setOpenDialog={setOpenGeofenceInputDialog} />
    ]
    )

}

const OrganizationEdit = () => {
    const translate = useTranslate();
    const theme = useTheme();

    const isAdminOfCurrentOrganization = () => {
        const userOrganizationId = localStorage.getItem(USER_ORGANIZATION_ID);
        const currentOrganizationId = getOrganizationIdFromUrl();
        return isAdminPermissions() && currentOrganizationId.length > 0 && userOrganizationId === currentOrganizationId
    }
    const inputsData = [
        { name: 'name', label: translate('resources.organization.name'), validate: validateRequired, type: "text" },
        { name: 'description', label: translate('resources.organization.description'), type: "text" },
    ]
    const siteInputsData = [
        { name: 'site_formatted_address', label: translate('resources.organization.site_formatted_address'), validate: validateRequired, type: "text", disabled: true, width: '300px' },
        { name: 'site_boundary_radius', label: translate('resources.organization.site_boundary_radius'), validate: validateRequired, type: "number", disabled: true },
        { name: 'site_longitude', label: translate('resources.organization.site_longitude'), validate: validateRequired, type: "number", disabled: true },
        { name: 'site_latitude', label: translate('resources.organization.site_latitude'), validate: validateRequired, type: "number", disabled: true },
    ]

    if (isSuperAdmin() ||
        (isAdminPermissions() &&
            !isAdminOfCurrentOrganization())
    ) {
        inputsData.push(
            { name: 'parent_organization_id', label: translate('resources.organization.parent'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
        )
    } else {
        inputsData.push(
            { name: 'parent_organization_id', label: translate('resources.organization.parent'), type: "text", disabled: true },
        )
    }

    if (isSuperAdmin()) {
        inputsData.push(
            { name: 'root_organization_id', label: translate('resources.organization.root'), validate: validateRequired, type: "reference", reference: "root_organizations", optionText: "name", disabled: true },
        )
    } else {
        inputsData.push(
            { name: 'root_organization_id', label: translate('resources.organization.root'), type: "text", disabled: true },
        )
    }



    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.organizations.edit')}
            </Typography>
            <Edit
                title={'resources.organizations.edit'}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                }}
            >
                <SimpleForm  >
                    {styledFormInputs(inputsData)}
                    <OrganizationSite />
                    {styledFormInputs(siteInputsData)}
                </SimpleForm>
            </Edit>
        </>
    )
}

export default OrganizationEdit