export const transparenteIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="

const mapIconUrl_12x12_map_icon_color = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="{mapIconColor}" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 12" style="enable-background:new 0 0 511.953 511.953;" xml:space="preserve" width="12" height="12">
  <g transform="translate(-1)">
    <g>
      <g>
        <path d="M6.024 3.499c-0.276 0 -0.5 0.225 -0.5 0.5 0 0.276 0.224 0.5 0.5 0.5s0.5 -0.225 0.5 -0.5c0 -0.276 -0.224 -0.5 -0.5 -0.5"/>
        <path d="M8.568 0.911C7.641 0.148 6.423 -0.156 5.227 0.076 3.613 0.389 2.348 1.694 2.08 3.322c-0.159 0.961 0.017 1.904 0.509 2.725l0.204 0.341c0.653 1.089 1.328 2.215 1.868 3.373l0.911 1.951c0.082 0.176 0.259 0.289 0.453 0.289s0.371 -0.113 0.453 -0.289l0.82 -1.756c0.547 -1.172 1.221 -2.313 1.874 -3.417l0.304 -0.516c0.36 -0.612 0.55 -1.311 0.55 -2.023 0 -1.199 -0.531 -2.324 -1.457 -3.088M6.024 5.499c-0.827 0 -1.5 -0.673 -1.5 -1.5s0.673 -1.5 1.5 -1.5 1.5 0.673 1.5 1.5 -0.673 1.5 -1.5 1.5"/>
      </g>
    </g>
  </g>
</svg>

`

const mapIconUrl_12x12_current_color = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 12" style="enable-background:new 0 0 511.953 511.953;" xml:space="preserve" width="12" height="12">
  <g transform="translate(-1)">
    <g>
      <g>
        <path d="M6.024 3.499c-0.276 0 -0.5 0.225 -0.5 0.5 0 0.276 0.224 0.5 0.5 0.5s0.5 -0.225 0.5 -0.5c0 -0.276 -0.224 -0.5 -0.5 -0.5"/>
        <path d="M8.568 0.911C7.641 0.148 6.423 -0.156 5.227 0.076 3.613 0.389 2.348 1.694 2.08 3.322c-0.159 0.961 0.017 1.904 0.509 2.725l0.204 0.341c0.653 1.089 1.328 2.215 1.868 3.373l0.911 1.951c0.082 0.176 0.259 0.289 0.453 0.289s0.371 -0.113 0.453 -0.289l0.82 -1.756c0.547 -1.172 1.221 -2.313 1.874 -3.417l0.304 -0.516c0.36 -0.612 0.55 -1.311 0.55 -2.023 0 -1.199 -0.531 -2.324 -1.457 -3.088M6.024 5.499c-0.827 0 -1.5 -0.673 -1.5 -1.5s0.673 -1.5 1.5 -1.5 1.5 0.673 1.5 1.5 -0.673 1.5 -1.5 1.5"/>
      </g>
    </g>
  </g>
</svg>

`




const mapIconUrl_16x16_map_icon_color = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="{mapIconColor}" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 511.953 511.953;" xml:space="preserve" width="16" height="16">
  <g transform="translate(-1)">
    <g>
      <g>
        <path d="M8.032 4.665a0.667 0.667 0 1 0 0.003 1.336 0.667 0.667 0 0 0 -0.003 -1.336"/>
        <path d="M11.424 1.215a5.373 5.373 0 0 0 -4.455 -1.113A5.293 5.293 0 0 0 2.773 4.429a5.293 5.293 0 0 0 0.679 3.633l0.272 0.455c0.871 1.452 1.771 2.953 2.491 4.497l1.215 2.601a0.667 0.667 0 0 0 1.208 0l1.093 -2.341c0.729 -1.563 1.628 -3.084 2.499 -4.556l0.405 -0.688A5.333 5.333 0 0 0 13.368 5.333a5.32 5.32 0 0 0 -1.943 -4.117m-3.393 6.116c-1.103 0 -2 -0.897 -2 -2s0.897 -2 2 -2 2 0.897 2 2 -0.897 2 -2 2"/>
      </g>
    </g>
  </g>
</svg>

`

const mapIconUrl_16x16_current_color = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 511.953 511.953;" xml:space="preserve" width="16" height="16">
  <g transform="translate(-1)">
    <g>
      <g>
        <path d="M8.032 4.665a0.667 0.667 0 1 0 0.003 1.336 0.667 0.667 0 0 0 -0.003 -1.336"/>
        <path d="M11.424 1.215a5.373 5.373 0 0 0 -4.455 -1.113A5.293 5.293 0 0 0 2.773 4.429a5.293 5.293 0 0 0 0.679 3.633l0.272 0.455c0.871 1.452 1.771 2.953 2.491 4.497l1.215 2.601a0.667 0.667 0 0 0 1.208 0l1.093 -2.341c0.729 -1.563 1.628 -3.084 2.499 -4.556l0.405 -0.688A5.333 5.333 0 0 0 13.368 5.333a5.32 5.32 0 0 0 -1.943 -4.117m-3.393 6.116c-1.103 0 -2 -0.897 -2 -2s0.897 -2 2 -2 2 0.897 2 2 -0.897 2 -2 2"/>
      </g>
    </g>
  </g>
</svg>

`




const addressIconUrl_24x24_map_icon_color = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="{mapIconColor}" class="icon icon-tabler icons-tabler-filled icon-tabler-flag-3">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M19 4c.852 0 1.297 .986 .783 1.623l-.076 .084l-3.792 3.793l3.792 3.793c.603 .602 .22 1.614 -.593 1.701l-.114 .006h-13v6a1 1 0 0 1 -.883 .993l-.117 .007a1 1 0 0 1 -.993 -.883l-.007 -.117v-16a1 1 0 0 1 .883 -.993l.117 -.007h14z"/>
</svg>

`
const addressIconUrl_24x24_current_color = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-flag-3">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M19 4c.852 0 1.297 .986 .783 1.623l-.076 .084l-3.792 3.793l3.792 3.793c.603 .602 .22 1.614 -.593 1.701l-.114 .006h-13v6a1 1 0 0 1 -.883 .993l-.117 .007a1 1 0 0 1 -.993 -.883l-.007 -.117v-16a1 1 0 0 1 .883 -.993l.117 -.007h14z"/>
</svg>

`






const tractorIconUrl_36x36 = `

<svg width="36px" height="36px" viewBox="0 0 5.76 5.76" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--noto" preserveAspectRatio="xMidYMid meet">
  <path d="M2.162 2.706V1.575c0 -0.175 -0.038 -0.306 -0.09 -0.371s-0.093 -0.096 -0.131 -0.086 -0.148 0.103 -0.131 0.144c0.017 0.041 0.096 0.09 0.117 0.193s0.01 0.182 0.01 0.182 -0.065 0 -0.069 0.052 0 1.073 0 1.073z" fill="#5e6268"/>
  <path d="M3.067 1.355 2.528 3.064s-0.64 0.294 -0.655 0.298c-0.014 0.005 -0.742 0.139 -0.742 0.139l-0.048 0.433 -0.558 0.255s0.014 -0.212 -0.014 -0.231S0.072 4.117 0.072 4.171s0.014 0.414 0.014 0.433 0.337 0.188 0.366 0.202 1.767 0.053 1.767 0.053 0.39 -0.014 0.665 -0.015c0.288 -0.001 0.803 -0.014 0.803 -0.014l1.464 -1.26h0.395L5.445 3.333l-0.405 -0.226s-0.019 -0.525 -0.053 -0.934 -0.053 -0.751 -0.053 -0.751z" fill="#5e6268"/>
  <path d="M2.08 3.81s0.068 -0.087 0.168 -0.091c0.101 -0.005 0.39 -0.014 0.39 -0.014s0.034 -0.428 0.058 -0.602 0.063 -0.423 0.063 -0.423l-0.26 -0.241 -0.154 0.221 -0.462 0.178 -0.751 0.231 0.053 0.515 0.438 -0.005z" fill="#02ab47"/>
  <path d="m2.345 2.659 0.414 0.019s0.096 -0.385 0.212 -0.727 0.221 -0.616 0.221 -0.616l-0.405 0.101s-0.143 0.334 -0.25 0.626c-0.129 0.349 -0.206 0.622 -0.193 0.596" fill="#afe2fb"/>
  <path d="M2.182 3.902s0.045 -0.074 0.119 -0.08 0.325 -0.004 0.325 -0.004v0.441l-0.222 0.002z" fill="#1a1a1a"/>
  <path d="M2.987 3.726c0.013 0.026 0.395 0.013 0.395 0.013l0.509 -0.582 0.263 -0.089s-0.013 -0.561 -0.026 -0.926 -0.017 -0.811 -0.017 -0.811L3.474 1.395s-0.068 0.14 -0.098 0.225 -0.064 0.212 -0.064 0.212 0.166 -0.017 0.174 0.004 -0.106 0.493 -0.119 0.501 -0.204 0 -0.204 0 -0.098 0.331 -0.14 0.688c-0.042 0.356 -0.05 0.675 -0.038 0.7" fill="#afe2fb"/>
  <path d="M4.369 1.62c-0.041 0 -0.061 0.037 -0.054 0.101 0.007 0.064 0.051 1.028 0.058 1.065s0.017 0.074 0.091 0.074 0.294 -0.004 0.324 -0.004 0.061 -0.041 0.054 -0.112 -0.061 -1.001 -0.064 -1.052 -0.031 -0.068 -0.091 -0.071c-0.058 -0.002 -0.288 -0.003 -0.318 -0.003" fill="#94c7ea"/>
  <path d="M0.699 3.124s0.03 -0.108 0.221 -0.195c0.192 -0.087 0.449 -0.156 0.725 -0.207s0.666 -0.062 0.699 -0.063c0.112 -0.002 0.175 0.087 0.106 0.111 -0.036 0.013 -0.201 0.024 -0.623 0.135 -0.339 0.089 -0.482 0.201 -0.482 0.201l-0.333 0.072z" fill="#76de88"/>
  <path d="M2.789 1.471c0.186 -0.007 0.662 -0.007 1.083 0s1.017 0.027 1.072 0.027c0.055 0 0.121 -0.062 0.114 -0.145s-0.117 -0.121 -0.331 -0.141c-0.138 -0.013 -0.479 -0.045 -0.896 -0.052s-0.783 -0.004 -0.952 0.052c-0.135 0.044 -0.193 0.135 -0.186 0.2s0.049 0.061 0.097 0.059" fill="#02ab47"/>
  <path d="M3.075 4.253c0.009 0.027 0.11 0.038 0.138 0.017 0.027 -0.021 0.194 -0.378 0.39 -0.638 0.211 -0.281 0.306 -0.34 0.338 -0.35 0.057 -0.016 0.448 -0.068 0.734 -0.05 0.286 0.017 0.541 0.069 0.648 0.141s0.18 0.193 0.223 0.195 0.012 -0.226 -0.14 -0.357c-0.152 -0.131 -0.438 -0.154 -0.725 -0.172 -0.214 -0.013 -0.819 0.03 -0.892 0.054 -0.103 0.035 -0.221 0.207 -0.393 0.49 -0.121 0.2 -0.334 0.628 -0.321 0.669" fill="#02ab47"/>
  <path d="M0.699 3.124s0.634 -0.036 0.647 -0.018c0.018 0.027 -0.037 0.15 -0.081 0.281 -0.045 0.131 -0.138 0.507 -0.138 0.507L0.581 4.181s0.031 -0.527 0.041 -0.683 0.032 -0.333 0.076 -0.373" fill="#2c7e33"/>
  <path d="m2.334 4.856 0.2 -0.004s0.131 -0.567 -0.355 -1.05c-0.423 -0.419 -1.05 -0.289 -1.038 -0.203 0.01 0.072 0.609 -0.065 0.963 0.376 0.365 0.455 0.23 0.882 0.23 0.882" fill="#868586"/>
  <path d="M0.644 3.289s0.285 -0.002 0.448 0.004c0.08 0.004 0.104 0.024 0.104 0.042 0 0.021 -0.019 0.04 -0.099 0.047 -0.166 0.013 -0.468 0.03 -0.468 0.03s0.002 -0.033 0.006 -0.067c0.004 -0.031 0.009 -0.056 0.009 -0.056" fill="#1a1a19"/>
  <path d="M0.621 3.537s0.182 -0.006 0.345 -0.006c0.093 0 0.127 0.013 0.127 0.039 0 0.022 -0.058 0.043 -0.125 0.053 -0.164 0.023 -0.355 0.038 -0.355 0.038s0.001 -0.032 0.003 -0.063c0.002 -0.031 0.004 -0.061 0.004 -0.061" fill="#1a1a19"/>
  <path d="M0.605 3.782s0.171 -0.009 0.238 -0.01c0.068 -0.002 0.144 -0.009 0.148 0.018 0.003 0.027 -0.036 0.069 -0.106 0.077 -0.053 0.006 -0.287 0.029 -0.287 0.029s0.001 -0.031 0.002 -0.057c0.002 -0.028 0.005 -0.056 0.005 -0.056" fill="#1a1a19"/>
  <path d="M5.582 4.468c0 0.622 -0.505 1.127 -1.129 1.127S3.323 5.09 3.326 4.468c0.003 -0.625 0.503 -1.142 1.124 -1.14 0.623 0.002 1.133 0.517 1.133 1.14" fill="#302f2f"/>
  <path d="M2.262 4.654c0 0.513 -0.401 0.927 -0.918 0.927s-0.93 -0.43 -0.93 -0.944 0.413 -0.933 0.93 -0.916c0.536 0.018 0.918 0.42 0.918 0.934" fill="#302f2f"/>
  <path d="M1.855 4.665c0 0.276 -0.174 0.514 -0.517 0.514 -0.315 0 -0.504 -0.236 -0.521 -0.471 -0.022 -0.31 0.186 -0.567 0.502 -0.57 0.343 -0.003 0.536 0.244 0.536 0.526" fill="#f3790e"/>
  <path d="M1.704 4.665c0 0.21 -0.169 0.378 -0.379 0.378 -0.186 0 -0.369 -0.161 -0.369 -0.372 0 -0.21 0.165 -0.385 0.375 -0.385s0.373 0.168 0.373 0.378" fill="#fe8f01"/>
  <path cx="29.59" cy="103.56" rx="4.81" ry="4.8" fill="#fcb900" d="M1.548 4.66A0.216 0.216 0 0 1 1.332 4.876A0.216 0.216 0 0 1 1.115 4.66A0.216 0.216 0 0 1 1.548 4.66z"/>
  <path d="M5.134 4.506c0 0.355 -0.235 0.673 -0.671 0.676 -0.394 0.002 -0.65 -0.316 -0.672 -0.62 -0.028 -0.399 0.24 -0.731 0.649 -0.734 0.444 -0.003 0.693 0.315 0.693 0.679" fill="#f3790e"/>
  <path d="M4.939 4.505c0 0.271 -0.219 0.488 -0.49 0.488 -0.24 0 -0.476 -0.208 -0.476 -0.479S4.185 4.018 4.457 4.018s0.482 0.216 0.482 0.487" fill="#fe8f01"/>
  <path d="M4.737 4.5c0 0.154 -0.126 0.285 -0.279 0.279 -0.165 -0.007 -0.279 -0.125 -0.279 -0.279s0.125 -0.279 0.279 -0.279 0.279 0.125 0.279 0.279" fill="#fcb900"/>
  <path d="M1.897 3.907s-0.095 0.137 -0.095 0.146c0 0.004 0.04 0.029 0.073 0.061 0.036 0.034 0.064 0.075 0.068 0.076 0.008 0.002 0.14 -0.095 0.14 -0.095s-0.04 -0.058 -0.085 -0.104a0.742 0.742 0 0 0 -0.101 -0.084" fill="#8e736e"/>
  <path d="M2.191 4.285s-0.143 0.066 -0.145 0.074 0.014 0.054 0.026 0.1c0.012 0.048 0.01 0.095 0.018 0.101s0.167 -0.002 0.167 -0.002 0 -0.085 -0.016 -0.141a0.877 0.877 0 0 0 -0.05 -0.131" fill="#8e736e"/>
  <path d="M2.257 4.76s-0.154 -0.031 -0.156 -0.026c-0.002 0.004 -0.018 0.076 -0.028 0.106s-0.029 0.08 -0.028 0.087 0.153 0.076 0.153 0.076 0.021 -0.054 0.036 -0.112 0.023 -0.131 0.023 -0.131" fill="#8e736e"/>
  <path d="M2.087 5.204s-0.126 -0.109 -0.134 -0.108 -0.046 0.05 -0.073 0.078 -0.071 0.067 -0.072 0.072c-0.001 0.004 0.101 0.143 0.101 0.143s0.063 -0.045 0.102 -0.09 0.076 -0.095 0.076 -0.095" fill="#8e736e"/>
  <path d="M1.724 5.5s-0.06 -0.163 -0.065 -0.165c-0.004 -0.002 -0.048 0.018 -0.106 0.035 -0.057 0.016 -0.093 0.018 -0.098 0.023 -0.004 0.004 0.004 0.182 0.004 0.182s0.043 -0.002 0.129 -0.024c0.081 -0.021 0.136 -0.05 0.136 -0.05" fill="#8e736e"/>
  <path d="M1.048 5.341c-0.005 0.001 -0.087 0.159 -0.087 0.159s0.044 0.022 0.126 0.047c0.082 0.026 0.144 0.028 0.144 0.028s0.019 -0.176 0.011 -0.184c-0.006 -0.005 -0.049 -0.004 -0.098 -0.018 -0.053 -0.014 -0.09 -0.035 -0.095 -0.033" fill="#8e736e"/>
  <path d="M0.735 5.101c-0.004 -0.002 -0.142 0.092 -0.142 0.092s0.05 0.07 0.086 0.106c0.036 0.036 0.1 0.089 0.1 0.089s0.102 -0.135 0.102 -0.141 -0.05 -0.042 -0.078 -0.068c-0.036 -0.035 -0.063 -0.075 -0.068 -0.077" fill="#8e736e"/>
  <path d="M0.585 4.73c-0.004 -0.004 -0.167 0 -0.167 0s0.008 0.087 0.021 0.142c0.017 0.072 0.051 0.14 0.051 0.14s0.148 -0.076 0.152 -0.082 -0.022 -0.055 -0.033 -0.098c-0.015 -0.055 -0.023 -0.103 -0.023 -0.103" fill="#8e736e"/>
  <path d="m0.416 4.562 0.167 0.011s0.008 -0.06 0.016 -0.096 0.032 -0.095 0.03 -0.1c-0.003 -0.005 -0.153 -0.082 -0.153 -0.082s-0.03 0.082 -0.043 0.141c-0.014 0.059 -0.018 0.127 -0.018 0.127" fill="#8e736e"/>
  <path d="M0.595 4.076s0.131 0.123 0.137 0.125 0.036 -0.041 0.078 -0.082c0.044 -0.042 0.088 -0.072 0.089 -0.079 0.002 -0.01 -0.104 -0.154 -0.104 -0.154s-0.058 0.035 -0.111 0.089c-0.054 0.054 -0.089 0.102 -0.089 0.102" fill="#8e736e"/>
  <path d="M0.983 3.784s0.07 0.158 0.074 0.161 0.053 -0.022 0.095 -0.032c0.065 -0.015 0.103 -0.013 0.108 -0.018s-0.009 -0.172 -0.009 -0.172 -0.064 -0.004 -0.142 0.016c-0.077 0.02 -0.126 0.045 -0.126 0.045" fill="#8e736e"/>
  <path d="M1.454 3.729s-0.018 0.161 -0.015 0.167 0.066 0.015 0.11 0.028 0.084 0.031 0.093 0.031 0.084 -0.148 0.084 -0.148 -0.076 -0.037 -0.13 -0.052c-0.082 -0.023 -0.142 -0.027 -0.142 -0.027" fill="#8e736e"/>
  <path d="M4.204 3.355s0.052 0.189 0.058 0.196 0.049 -0.004 0.131 -0.01c0.081 -0.006 0.125 -0.001 0.128 -0.004s0.027 -0.209 0.027 -0.209 -0.053 -0.01 -0.18 -0.002c-0.11 0.007 -0.164 0.028 -0.164 0.028" fill="#8e736e"/>
  <path d="M4.84 3.393s-0.063 0.166 -0.062 0.172 0.085 0.041 0.12 0.063 0.094 0.065 0.099 0.068 0.129 -0.144 0.129 -0.144 -0.054 -0.043 -0.139 -0.091c-0.089 -0.052 -0.148 -0.068 -0.148 -0.068" fill="#8e736e"/>
  <path d="M5.309 3.727s-0.118 0.126 -0.119 0.135 0.046 0.061 0.065 0.092 0.05 0.101 0.055 0.103 0.171 -0.06 0.171 -0.06 -0.035 -0.093 -0.075 -0.154 -0.097 -0.116 -0.097 -0.116" fill="#8e736e"/>
  <path d="M5.558 4.226s-0.184 0.045 -0.189 0.05 0.007 0.074 0.011 0.137c0.003 0.052 -0.004 0.125 -0.001 0.132s0.201 0.026 0.201 0.026 0.011 -0.07 0.005 -0.164c-0.005 -0.095 -0.027 -0.18 -0.027 -0.18" fill="#8e736e"/>
  <path d="M5.532 4.811s-0.184 -0.065 -0.191 -0.063 -0.028 0.062 -0.053 0.106c-0.024 0.045 -0.059 0.097 -0.058 0.103s0.17 0.125 0.17 0.125 0.047 -0.059 0.08 -0.128c0.032 -0.068 0.053 -0.144 0.053 -0.144" fill="#8e736e"/>
  <path d="M5.225 5.29s-0.15 -0.149 -0.155 -0.145 -0.054 0.05 -0.093 0.078c-0.039 0.028 -0.105 0.066 -0.11 0.07 -0.009 0.007 0.081 0.189 0.081 0.189s0.065 -0.024 0.152 -0.085c0.087 -0.063 0.126 -0.108 0.126 -0.108" fill="#8e736e"/>
  <path d="m4.389 5.593 0.042 -0.202s0.072 0.004 0.121 0c0.047 -0.004 0.108 -0.022 0.115 -0.018s0.062 0.191 0.062 0.191 -0.091 0.026 -0.182 0.032 -0.159 -0.003 -0.159 -0.003" fill="#8e736e"/>
  <path d="M4.012 5.289c-0.005 0.001 -0.11 0.162 -0.11 0.162s0.045 0.031 0.129 0.066 0.163 0.048 0.163 0.048l0.046 -0.189s-0.07 -0.018 -0.122 -0.036c-0.053 -0.019 -0.099 -0.051 -0.105 -0.05" fill="#8e736e"/>
  <path d="M3.651 4.946c-0.004 -0.003 -0.173 0.092 -0.173 0.092s0.045 0.076 0.094 0.136c0.049 0.059 0.108 0.115 0.108 0.115s0.127 -0.14 0.127 -0.144 -0.057 -0.059 -0.093 -0.103c-0.036 -0.045 -0.058 -0.09 -0.063 -0.095" fill="#8e736e"/>
  <path d="M3.357 4.747s0.19 -0.038 0.193 -0.042 -0.012 -0.076 -0.018 -0.118c-0.005 -0.043 -0.005 -0.116 -0.007 -0.121s-0.2 -0.011 -0.2 -0.011 -0.005 0.076 0.001 0.135c0.009 0.085 0.031 0.158 0.031 0.158" fill="#8e736e"/>
  <path d="M3.366 4.163s0.199 0.047 0.203 0.047 0.015 -0.049 0.039 -0.099c0.024 -0.051 0.059 -0.101 0.057 -0.106s-0.175 -0.133 -0.175 -0.133 -0.05 0.074 -0.081 0.155c-0.032 0.081 -0.044 0.137 -0.044 0.137" fill="#8e736e"/>
  <path d="M3.682 3.635s0.149 0.137 0.156 0.137 0.055 -0.036 0.102 -0.069 0.102 -0.065 0.102 -0.069 -0.097 -0.186 -0.097 -0.186 -0.087 0.04 -0.146 0.085c-0.067 0.051 -0.117 0.103 -0.117 0.103" fill="#8e736e"/>
</svg>



  `





const backhoeIconUrl_48x48_map_icon_color = `

<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="{mapIconColor}" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-backhoe">
  <path stroke="none" d="M0 0h48v48H0z" fill="none" stroke-width="2"/>
  <path d="M4 34a4 4 0 1 0 8 0 4 4 0 1 0 -8 0"/>
  <path d="M22 34a4 4 0 1 0 8 0 4 4 0 1 0 -8 0"/>
  <path d="M26 38H8"/>
  <path d="M8 30h18"/>
  <path d="M16 24V14h4a6 6 0 0 1 6 6V30"/>
  <path d="M10 30v-4A2 2 0 0 1 12 24h14"/>
  <path d="m42.24 19.76 -6.24 -9.759 -10 10"/>
  <path d="M42.24 19.76a6 6 0 0 1 -4.24 10.24 6 6 0 0 1 -4.24 -1.76z"/>
</svg>


  `
const backhoeIconUrl_48x48_current_color = `

<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-backhoe">
  <path stroke="none" d="M0 0h48v48H0z" fill="none" stroke-width="2"/>
  <path d="M4 34a4 4 0 1 0 8 0 4 4 0 1 0 -8 0"/>
  <path d="M22 34a4 4 0 1 0 8 0 4 4 0 1 0 -8 0"/>
  <path d="M26 38H8"/>
  <path d="M8 30h18"/>
  <path d="M16 24V14h4a6 6 0 0 1 6 6V30"/>
  <path d="M10 30v-4A2 2 0 0 1 12 24h14"/>
  <path d="m42.24 19.76 -6.24 -9.759 -10 10"/>
  <path d="M42.24 19.76a6 6 0 0 1 -4.24 10.24 6 6 0 0 1 -4.24 -1.76z"/>
</svg>


  `




const carIconUrl_22x22 = `
<svg width="22px" height="22px" viewBox="0 -2.769 11.123 11.123" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style>
      .a{fill:#0000ff;}.b{fill:#ffffff;}.c{fill:#211715;}
    </style>
  </defs>
  <path class="a" d="M10.252 4.591a1.192 1.192 0 0 0 0.432 -0.196c0.114 -0.083 0.183 -0.2 0.265 -0.42 0.108 -0.291 0.139 -0.641 0.074 -1.014a8.25 8.25 0 0 0 -0.052 -0.271 3.025 3.025 0 0 0 -0.154 -0.543 2.704 2.704 0 0 0 -0.4 -0.628 3.392 3.392 0 0 0 -0.921 -0.835C8.852 0.346 8.441 0.241 7.716 0.122s-2.168 -0.028 -2.872 0.183C4.056 0.541 3.426 0.977 2.681 1.662a0.596 0.596 0 0 1 -0.293 0.145c-0.557 0.107 -0.786 0.131 -1.253 0.239 -0.25 0.058 -0.501 0.15 -0.69 0.324 -0.334 0.307 -0.368 0.812 -0.374 1.268a1.604 1.604 0 0 0 0.06 0.465c0.096 0.329 0.25 0.516 0.659 0.545l2.083 0.016c1.395 0 4.033 -0.024 5.435 -0.008Z"/>
  <path class="a" d="M8.307 4.216h-0.554c-0.183 0 -3.667 0.012 -4.312 0.009H2.928a1.008 1.008 0 0 1 0.05 0.312q0 0.065 -0.006 0.126l5.288 -0.008a0.917 0.917 0 0 1 -0.005 -0.118 1.008 1.008 0 0 1 0.053 -0.32Z"/>
  <path class="b" d="M4.529 1.965a42.167 42.167 0 0 1 -1.373 0.014 0.049 0.049 0 0 1 -0.035 -0.082c0.213 -0.226 0.398 -0.46 0.721 -0.675a4.721 4.721 0 0 1 1.439 -0.62 3.483 3.483 0 0 1 1.04 -0.125c-0.034 0.228 -0.167 0.916 -0.236 1.443 -0.412 0.016 -0.997 0.033 -1.556 0.045"/>
  <path class="a" d="M0.143 2.911c0.052 -0.207 0.143 -0.396 0.301 -0.541a1.192 1.192 0 0 1 0.371 -0.226c0.11 0.017 0.242 0.038 0.354 0.063a0.458 0.458 0 0 1 0.087 0.023c0.027 0.014 0.063 0.042 0.047 0.093a2.017 2.017 0 0 1 -0.219 0.47 0.302 0.302 0 0 1 -0.182 0.14c-0.272 0.072 -0.567 0.063 -0.779 0.067q0.009 -0.045 0.02 -0.088"/>
  <path class="b" d="M6.402 1.907a32.083 32.083 0 0 1 0.108 -0.745c0.037 -0.245 0.074 -0.488 0.099 -0.688q0.337 0.004 0.672 0.038c0.333 0.034 0.665 0.091 0.981 0.199 0.488 0.167 1.164 0.466 1.475 0.877a0.032 0.032 0 0 1 -0.019 0.05c-0.625 0.12 -2.383 0.216 -3.071 0.255Z"/>
  <path class="c" d="M1.239 2.304a2.017 2.017 0 0 1 -0.138 0.326c-0.05 0.092 -0.098 0.202 -0.206 0.235 -0.232 0.072 -0.486 0.063 -0.726 0.067 -0.085 0.001 -0.085 0.133 0 0.132 0.206 -0.003 0.412 -0.004 0.615 -0.039a0.55 0.55 0 0 0 0.281 -0.108c0.076 -0.066 0.123 -0.168 0.169 -0.256a2.017 2.017 0 0 0 0.132 -0.321c0.025 -0.081 -0.102 -0.116 -0.127 -0.035Z"/>
  <path class="c" d="M0.746 2.467c-0.013 0.067 -0.016 0.138 -0.042 0.202l0.007 -0.016 -0.005 0.011a0.066 0.066 0 0 0 0.114 0.067c0.037 -0.069 0.039 -0.152 0.054 -0.228a0.069 0.069 0 0 0 -0.007 -0.051 0.066 0.066 0 0 0 -0.121 0.016Z"/>
  <path class="a" d="M10.47 2.229a1.833 1.833 0 0 0 0.347 -0.082 2.521 2.521 0 0 1 0.153 0.536l-0.024 0.001a3.208 3.208 0 0 1 -0.367 0.024 0.092 0.092 0 0 1 -0.042 -0.01 0.069 0.069 0 0 1 -0.021 -0.039c-0.034 -0.127 -0.066 -0.259 -0.103 -0.378a0.04 0.04 0 0 1 0.031 -0.049z"/>
  <path class="c" d="M2.972 4.73q0.465 0 0.931 -0.003l1.084 -0.005 1.137 -0.005q0.545 -0.002 1.091 -0.002 0.47 0 0.941 0.004l0.104 0.001c0.085 0.001 0.085 -0.131 0 -0.132q-0.46 -0.005 -0.92 -0.005 -0.539 -0.001 -1.078 0.001l-1.137 0.004 -1.096 0.005 -0.951 0.003h-0.106c-0.085 0 -0.085 0.132 0 0.132Z"/>
  <path class="c" d="M0.789 4.581c-0.218 -0.017 -0.409 -0.092 -0.516 -0.292C0.136 4.034 0.129 3.736 0.14 3.454c0.011 -0.27 0.039 -0.555 0.168 -0.798 0.143 -0.27 0.407 -0.422 0.692 -0.507 0.254 -0.076 0.52 -0.12 0.78 -0.166l0.398 -0.07c0.152 -0.028 0.31 -0.045 0.445 -0.127a1.192 1.192 0 0 0 0.182 -0.149q0.095 -0.086 0.191 -0.169 0.183 -0.158 0.374 -0.307a5.5 5.5 0 0 1 0.735 -0.481 3.987 3.987 0 0 1 0.773 -0.317 5.042 5.042 0 0 1 0.649 -0.132 9.304 9.304 0 0 1 1.578 -0.093 5.821 5.821 0 0 1 1.499 0.243 4.721 4.721 0 0 1 0.773 0.316 2.704 2.704 0 0 1 0.638 0.471 3.987 3.987 0 0 1 0.558 0.67 2.017 2.017 0 0 1 0.216 0.417 3.392 3.392 0 0 1 0.117 0.445c0.066 0.317 0.115 0.636 0.059 0.958a1.833 1.833 0 0 1 -0.133 0.431 0.596 0.596 0 0 1 -0.205 0.266 1.1 1.1 0 0 1 -0.392 0.172c-0.083 0.019 -0.048 0.146 0.035 0.127 0.268 -0.062 0.528 -0.204 0.655 -0.455a1.897 1.897 0 0 0 0.197 -0.923 3.025 3.025 0 0 0 -0.066 -0.503 5.042 5.042 0 0 0 -0.113 -0.475c-0.097 -0.312 -0.28 -0.581 -0.485 -0.833a3.667 3.667 0 0 0 -0.575 -0.588 3.025 3.025 0 0 0 -0.353 -0.25 4.125 4.125 0 0 0 -0.425 -0.204 4.583 4.583 0 0 0 -0.789 -0.255 6.875 6.875 0 0 0 -0.819 -0.141 8.021 8.021 0 0 0 -1.595 0.026c-0.518 0.052 -1.026 0.14 -1.508 0.343 -0.578 0.244 -1.084 0.619 -1.553 1.031 -0.122 0.108 -0.237 0.244 -0.396 0.296a1.833 1.833 0 0 1 -0.247 0.052q-0.117 0.022 -0.235 0.042c-0.259 0.047 -0.519 0.092 -0.776 0.149 -0.306 0.068 -0.622 0.172 -0.841 0.408 -0.197 0.213 -0.278 0.497 -0.314 0.778 -0.039 0.309 -0.055 0.645 0.029 0.948 0.059 0.213 0.163 0.423 0.369 0.526a1.008 1.008 0 0 0 0.359 0.087c0.085 0.006 0.084 -0.126 0 -0.132"/>
  <path class="c" d="M4.529 1.899a81.125 81.125 0 0 1 -0.852 0.014q-0.176 0.002 -0.352 0.001l-0.143 -0.001 0.003 0.002h-0.001c-0.007 0.002 0.003 -0.007 0 0q-0.004 0.006 0 0l0.046 -0.053a3.3 3.3 0 0 1 0.504 -0.483 3.987 3.987 0 0 1 0.67 -0.397 4.721 4.721 0 0 1 0.772 -0.285 3.987 3.987 0 0 1 0.841 -0.151q0.105 -0.006 0.21 -0.006c0.004 0 0.022 0.005 0.018 0l-0.003 0.017 -0.006 0.035 -0.05 0.286q-0.065 0.366 -0.123 0.734l-0.027 0.181a0.183 0.183 0 0 1 -0.011 0.065c0.017 -0.023 0.006 -0.003 0.006 -0.003l-0.02 0.001 -0.052 0.002 -0.372 0.012q-0.423 0.013 -0.847 0.023l-0.211 0.005c-0.085 0.002 -0.085 0.134 0 0.132q0.462 -0.01 0.924 -0.024l0.41 -0.013 0.091 -0.003a0.596 0.596 0 0 0 0.104 -0.006c0.077 -0.018 0.096 -0.083 0.105 -0.151q0.058 -0.4 0.129 -0.797l0.059 -0.335 0.022 -0.127c0.011 -0.07 -0.018 -0.14 -0.091 -0.16a0.458 0.458 0 0 0 -0.098 -0.005q-0.061 0 -0.121 0.003 -0.113 0.005 -0.225 0.018a4.125 4.125 0 0 0 -0.464 0.081 5.5 5.5 0 0 0 -0.855 0.277 4.308 4.308 0 0 0 -0.735 0.396 3.208 3.208 0 0 0 -0.583 0.514 0.917 0.917 0 0 0 -0.128 0.149 0.13 0.13 0 0 0 0.023 0.165c0.045 0.042 0.108 0.034 0.165 0.035h0.175q0.429 -0.002 0.857 -0.011l0.238 -0.005c0.085 -0.002 0.085 -0.134 0 -0.132"/>
  <path class="c" d="M8.342 0.803a4.125 4.125 0 0 1 0.245 0.469 19.25 19.25 0 0 1 0.221 0.484 0.066 0.066 0 0 0 0.09 0.024 0.07 0.07 0 0 0 0.024 -0.09 19.25 19.25 0 0 0 -0.221 -0.484 4.125 4.125 0 0 0 -0.245 -0.469c-0.046 -0.071 -0.16 -0.005 -0.114 0.067Z"/>
  <path class="c" d="M6.468 1.907c0.042 -0.328 0.096 -0.654 0.144 -0.981l0.036 -0.248 0.016 -0.119 0.003 -0.023c0.004 -0.034 0.002 -0.006 0 -0.007l-0.02 0.012h0.027l0.04 0.001q0.256 0.007 0.512 0.031c0.332 0.032 0.663 0.086 0.98 0.19a5.042 5.042 0 0 1 0.716 0.295c0.231 0.117 0.459 0.255 0.644 0.438q0.032 0.031 0.061 0.064l0.027 0.031 0.011 0.014c0.015 0.018 0.009 0.022 0.022 -0.008 0.014 -0.031 0.011 -0.023 -0.01 -0.019l-0.026 0.005 -0.055 0.009 -0.116 0.017 -0.118 0.015c-0.446 0.055 -0.895 0.087 -1.343 0.118q-0.633 0.043 -1.266 0.078l-0.109 0.006c-0.084 0.005 -0.085 0.137 0 0.132q0.631 -0.035 1.263 -0.076a27.958 27.958 0 0 0 1.419 -0.122q0.14 -0.016 0.279 -0.038l0.112 -0.019a0.101 0.101 0 0 0 0.074 -0.046c0.027 -0.043 0.012 -0.088 -0.017 -0.125 -0.163 -0.21 -0.39 -0.368 -0.619 -0.499a5.042 5.042 0 0 0 -0.766 -0.347 4.4 4.4 0 0 0 -0.969 -0.227q-0.269 -0.033 -0.539 -0.046l-0.128 -0.005c-0.044 -0.001 -0.096 -0.01 -0.139 0.002 -0.09 0.025 -0.083 0.136 -0.093 0.211 -0.048 0.348 -0.106 0.694 -0.155 1.042l-0.033 0.241a0.066 0.066 0 0 0 0.066 0.066 0.07 0.07 0 0 0 0.066 -0.066Z"/>
  <path class="c" d="M6.215 2.121c-0.03 0.213 -0.058 0.425 -0.067 0.64q-0.016 0.378 -0.018 0.756l-0.001 0.423c0 0.085 0.132 0.085 0.132 0q-0.001 -0.376 0.005 -0.752c0.005 -0.218 0.008 -0.437 0.028 -0.655q0.02 -0.188 0.047 -0.377a0.07 0.07 0 0 0 -0.046 -0.081 0.066 0.066 0 0 0 -0.081 0.046Z"/>
  <path class="c" d="M3.216 2.268a2.292 2.292 0 0 0 -0.116 0.622 5.958 5.958 0 0 0 0.019 0.669q0.015 0.187 0.042 0.373a0.066 0.066 0 0 0 0.081 0.046 0.07 0.07 0 0 0 0.046 -0.081 5.958 5.958 0 0 1 -0.055 -0.64 3.025 3.025 0 0 1 0.023 -0.605 3.208 3.208 0 0 1 0.087 -0.349c0.026 -0.081 -0.101 -0.116 -0.127 -0.035"/>
  <path class="c" d="M3.441 4.291h0.659l1.028 -0.002 1.136 -0.003 0.956 -0.003 0.514 -0.001h0.018c0.085 0 0.085 -0.132 0 -0.132l-0.441 0.001 -0.917 0.003 -1.129 0.003 -1.055 0.002H3.441c-0.085 0 -0.085 0.132 0 0.132"/>
  <path class="c" d="M8.912 2.063a4.125 4.125 0 0 1 0.135 0.412q0.069 0.229 0.124 0.461l0.017 0.077 0.003 0.015c0.002 0.009 -0.001 -0.019 -0.001 -0.006l0.001 0.009a0.092 0.092 0 0 1 0.018 -0.04l0.047 -0.019a3.208 3.208 0 0 0 -0.339 0.021 1.283 1.283 0 0 0 -0.355 0.1 0.839 0.839 0 0 0 -0.419 0.398 0.07 0.07 0 0 0 0.024 0.09 0.067 0.067 0 0 0 0.09 -0.024 0.688 0.688 0 0 1 0.167 -0.226 0.825 0.825 0 0 1 0.217 -0.131 1.1 1.1 0 0 1 0.334 -0.083q0.08 -0.007 0.16 -0.01l0.077 -0.002c0.031 -0.001 0.066 0.003 0.09 -0.021 0.031 -0.03 0.02 -0.075 0.013 -0.113l-0.031 -0.137a10.083 10.083 0 0 0 -0.112 -0.411 4.125 4.125 0 0 0 -0.131 -0.396 0.07 0.07 0 0 0 -0.081 -0.046 0.066 0.066 0 0 0 -0.048 0.082"/>
  <path class="c" d="M10.789 2.088a1.512 1.512 0 0 1 -0.255 0.064l-0.057 0.01 -0.03 0.004h-0.003l-0.025 0.004c-0.064 0.019 -0.083 0.085 -0.065 0.143q0.046 0.158 0.086 0.317c0.015 0.055 0.027 0.115 0.087 0.136a0.412 0.412 0 0 0 0.128 0.008c0.097 -0.003 0.195 -0.014 0.291 -0.023a0.07 0.07 0 0 0 0.066 -0.066 0.066 0.066 0 0 0 -0.066 -0.066q-0.11 0.012 -0.219 0.02l-0.109 0.005a0.183 0.183 0 0 0 -0.049 -0.001c0.003 -0.002 -0.017 -0.017 0.007 0.006 0.016 0.015 0.003 0.003 0.003 0l-0.001 -0.006 -0.006 -0.021q-0.035 -0.137 -0.073 -0.273l-0.019 -0.065 -0.004 -0.015c-0.006 -0.01 0 0.003 -0.002 0.008 -0.003 0.01 -0.014 0.014 -0.019 0.019l-0.003 0.001 0.012 -0.002 0.112 -0.018a1.512 1.512 0 0 0 0.246 -0.063 0.07 0.07 0 0 0 0.046 -0.081 0.067 0.067 0 0 0 -0.081 -0.046Z"/>
  <path class="c" d="M5.983 2.441c-0.001 -0.056 0.007 -0.119 -0.034 -0.164a0.128 0.128 0 0 0 -0.096 -0.041h-0.461a0.128 0.128 0 0 0 -0.118 0.085 0.183 0.183 0 0 0 -0.006 0.077 0.321 0.321 0 0 0 0.009 0.086 0.128 0.128 0 0 0 0.117 0.083c0.088 0.003 0.177 0 0.266 0h0.197a0.127 0.127 0 0 0 0.124 -0.125 0.066 0.066 0 1 0 -0.132 0c0 -0.01 0.013 -0.004 0.003 -0.007h-0.433c-0.003 0 -0.023 -0.002 -0.024 0l0.006 0.004c0.003 0.002 -0.001 0.011 0.001 0.001a0.183 0.183 0 0 0 -0.001 -0.041c0 -0.006 0.002 -0.043 -0.004 -0.032 -0.002 0.004 -0.006 0 -0.003 0.002 0.002 0.003 0.023 0 0.027 0h0.436c-0.002 0.003 -0.01 -0.008 -0.007 -0.009a0.917 0.917 0 0 0 0.002 0.083c0.002 0.085 0.134 0.085 0.132 0"/>
  <path class="c" d="M8.762 2.441c-0.001 -0.056 0.007 -0.119 -0.034 -0.164a0.128 0.128 0 0 0 -0.096 -0.041h-0.461a0.128 0.128 0 0 0 -0.118 0.085 0.183 0.183 0 0 0 -0.006 0.077 0.321 0.321 0 0 0 0.009 0.086 0.128 0.128 0 0 0 0.117 0.083c0.088 0.003 0.177 0 0.266 0h0.197a0.127 0.127 0 0 0 0.124 -0.125 0.066 0.066 0 0 0 -0.132 0c0 -0.01 0.013 -0.004 0.003 -0.007h-0.433c-0.003 0 -0.023 -0.002 -0.024 0l0.006 0.004c0.003 0.002 -0.001 0.011 0.001 0.001a0.183 0.183 0 0 0 -0.001 -0.041c0 -0.006 0.002 -0.043 -0.004 -0.032 -0.002 0.004 -0.006 0 -0.003 0.002 0.002 0.003 0.023 0 0.027 0h0.435c-0.002 0.003 -0.01 -0.008 -0.007 -0.009a0.917 0.917 0 0 0 0.002 0.083c0.002 0.085 0.134 0.085 0.132 0"/>
  <path class="c" d="M3.849 2.094a0.917 0.917 0 0 0 -0.065 0.068l-0.037 0.049a0.137 0.137 0 0 0 -0.025 0.068 0.082 0.082 0 0 0 0.037 0.066 0.092 0.092 0 0 0 0.052 0.014l0.054 0.005 0.061 0.001 0.039 -0.002a0.082 0.082 0 0 0 0.06 -0.039 0.321 0.321 0 0 0 0.018 -0.075q0.007 -0.034 0.017 -0.067a0.067 0.067 0 0 0 -0.046 -0.081 0.067 0.067 0 0 0 -0.081 0.046l-0.017 0.063 -0.006 0.031 -0.006 0.033 0.007 -0.016 -0.002 0.003 0.01 -0.013 -0.002 0.002 0.013 -0.01 -0.003 0.001 0.016 -0.007 -0.005 0.001 0.018 -0.002a0.917 0.917 0 0 1 -0.148 -0.006l0.018 0.002 -0.012 -0.002 0.016 0.007 -0.005 -0.003 0.013 0.01 -0.002 -0.002 0.01 0.013 -0.001 -0.002 0.007 0.016 -0.001 -0.003 0.002 0.018v-0.003l-0.002 0.018 0.003 -0.008 -0.007 0.016a0.321 0.321 0 0 1 0.032 -0.051l-0.01 0.013a0.596 0.596 0 0 1 0.073 -0.077 0.066 0.066 0 0 0 0 -0.093 0.07 0.07 0 0 0 -0.093 0Z"/>
  <path class="c" d="M3.996 2.165a0.917 0.917 0 0 0 -0.027 0.119l-0.003 0.011a0.018 0.018 0 0 1 -0.01 0.005 0.917 0.917 0 0 1 -0.155 -0.007 0.018 0.018 0 0 1 -0.013 -0.008 0.018 0.018 0 0 1 0.001 -0.013 0.504 0.504 0 0 1 0.106 -0.13Z"/>
  <path class="a" d="M3.747 1.706a0.229 0.229 0 0 1 0.04 -0.139 0.275 0.275 0 0 1 0.167 -0.092l0.079 -0.003a0.128 0.128 0 0 1 0.107 0.08c0.017 0.065 0.008 0.212 0.01 0.241a2.017 2.017 0 0 1 -0.007 0.289c-0.005 0.039 -0.032 0.081 -0.1 0.067s-0.169 -0.027 -0.2 -0.065a0.412 0.412 0 0 1 -0.062 -0.155 1.833 1.833 0 0 1 -0.032 -0.224"/>
  <path class="c" d="M3.813 1.706c0.001 -0.053 0.015 -0.098 0.054 -0.126a0.321 0.321 0 0 1 0.071 -0.035 0.092 0.092 0 0 1 0.039 -0.005c0.038 -0.001 0.065 -0.01 0.091 0.019a0.06 0.06 0 0 1 0.013 0.039q0.004 0.043 0.003 0.088c0 0.099 0.003 0.198 -0.001 0.297l-0.006 0.089 -0.003 0.013c0.002 -0.011 -0.004 0.007 -0.001 0.001s0.009 -0.001 -0.003 0.001c0.007 -0.001 -0.009 -0.001 -0.008 -0.001a0.458 0.458 0 0 1 -0.079 -0.015 0.321 0.321 0 0 1 -0.081 -0.023c-0.012 -0.006 -0.009 -0.004 -0.016 -0.013a0.183 0.183 0 0 1 -0.023 -0.047 0.917 0.917 0 0 1 -0.025 -0.1 0.917 0.917 0 0 1 -0.025 -0.182c-0.001 -0.085 -0.133 -0.085 -0.132 0a1.192 1.192 0 0 0 0.03 0.217c0.015 0.079 0.038 0.177 0.107 0.227 0.06 0.043 0.147 0.054 0.218 0.065a0.14 0.14 0 0 0 0.171 -0.115c0.023 -0.133 0.011 -0.274 0.011 -0.409 0 -0.072 0.003 -0.156 -0.046 -0.214a0.202 0.202 0 0 0 -0.171 -0.069 0.367 0.367 0 0 0 -0.199 0.059 0.28 0.28 0 0 0 -0.12 0.239c-0.002 0.085 0.13 0.085 0.132 0"/>
  <path class="c" d="M1.366 2.339a0.137 0.137 0 0 0 -0.043 -0.144c-0.043 -0.036 -0.095 -0.044 -0.149 -0.056a5.958 5.958 0 0 0 -0.322 -0.057 0.07 0.07 0 0 0 -0.081 0.046 0.066 0.066 0 0 0 0.046 0.081q0.136 0.02 0.273 0.047a0.917 0.917 0 0 1 0.129 0.028l0.014 0.007c0.001 0.001 0.01 0.007 0.004 0.002s0.002 0.003 0.003 0.004q0.004 0.004 0 0 -0.005 -0.006 0 0c0.005 0.006 0.002 0.003 0.001 0s-0.001 -0.004 0 0 0.001 0.004 0.001 0 0 -0.004 0 0c-0.002 0.012 0.005 -0.016 -0.002 0.007a0.066 0.066 0 1 0 0.127 0.035Z"/>
  <path class="c" d="M2.009 3.575a0.95 0.95 0 0 0 -0.969 0.963c0 0.601 0.375 0.981 0.969 0.981 0.608 0 0.969 -0.38 0.969 -0.981A0.95 0.95 0 0 0 2.009 3.575"/>
  <path class="c" d="M9.223 3.575a0.95 0.95 0 0 0 -0.969 0.963c0 0.601 0.375 0.981 0.969 0.981 0.608 0 0.969 -0.38 0.969 -0.981A0.95 0.95 0 0 0 9.223 3.575"/>
  <path class="c" d="M2.009 3.509a1.054 1.054 0 0 0 -0.645 0.216 1.008 1.008 0 0 0 -0.35 0.524c-0.113 0.401 0.004 0.886 0.345 1.141 0.165 0.124 0.37 0.181 0.574 0.193 0.214 0.012 0.438 -0.023 0.628 -0.127 0.369 -0.201 0.525 -0.644 0.474 -1.046a1.04 1.04 0 0 0 -0.273 -0.587 0.999 0.999 0 0 0 -0.57 -0.299 0.917 0.917 0 0 0 -0.184 -0.015c-0.085 0 -0.085 0.132 0 0.132a0.917 0.917 0 0 1 0.564 0.187 0.898 0.898 0 0 1 0.305 0.459 1.1 1.1 0 0 1 -0.01 0.579 0.802 0.802 0 0 1 -0.269 0.409 0.898 0.898 0 0 1 -0.504 0.175 1.054 1.054 0 0 1 -0.556 -0.103 0.77 0.77 0 0 1 -0.341 -0.358 1.072 1.072 0 0 1 -0.085 -0.565 0.88 0.88 0 0 1 0.743 -0.772 0.917 0.917 0 0 1 0.154 -0.012c0.085 0 0.085 -0.132 0 -0.132"/>
  <path class="a" d="M2.009 4.205a0.335 0.335 0 0 0 -0.341 0.338c0 0.211 0.132 0.345 0.341 0.345 0.214 0 0.341 -0.134 0.341 -0.345a0.335 0.335 0 0 0 -0.341 -0.338"/>
  <path class="c" d="M9.223 3.509a1.054 1.054 0 0 0 -0.645 0.216 1.008 1.008 0 0 0 -0.35 0.524c-0.113 0.401 0.004 0.886 0.345 1.141 0.165 0.124 0.37 0.181 0.574 0.193 0.214 0.012 0.438 -0.023 0.628 -0.127 0.369 -0.201 0.525 -0.644 0.474 -1.046a1.04 1.04 0 0 0 -0.273 -0.587 0.999 0.999 0 0 0 -0.57 -0.299 0.917 0.917 0 0 0 -0.184 -0.015c-0.085 0 -0.085 0.132 0 0.132a0.917 0.917 0 0 1 0.564 0.187 0.898 0.898 0 0 1 0.305 0.459 1.1 1.1 0 0 1 -0.01 0.579 0.802 0.802 0 0 1 -0.269 0.409 0.898 0.898 0 0 1 -0.504 0.175 1.054 1.054 0 0 1 -0.556 -0.103 0.77 0.77 0 0 1 -0.341 -0.358 1.072 1.072 0 0 1 -0.085 -0.565 0.88 0.88 0 0 1 0.742 -0.771 0.917 0.917 0 0 1 0.154 -0.012c0.085 0 0.085 -0.132 0 -0.132"/>
  <path class="a" d="M9.223 4.205a0.335 0.335 0 0 0 -0.34 0.338c0 0.211 0.132 0.345 0.341 0.345 0.214 0 0.341 -0.134 0.341 -0.345a0.335 0.335 0 0 0 -0.341 -0.338"/>
</svg>


`


const containerIconUrl_22x22 = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="22" height="22">
  <path style="fill:#D35B38;" d="M20.545 20.563H1.455a1.018 1.018 0 0 1 -1.017 -1.017V10.237c0 -0.562 0.456 -1.034 1.017 -1.034h19.089c0.562 0 1.017 0.473 1.017 1.034v9.309a1.018 1.018 0 0 1 -1.017 1.017"/>
  <g>
    <path style="fill:#4D3D36;" d="M20.545 8.764h-0.692v-0.14c0 -0.676 -0.55 -1.227 -1.227 -1.227s-1.227 0.55 -1.227 1.227v0.14H4.602v-0.14c0 -0.143 -0.025 -0.28 -0.07 -0.407l5.324 -1.972a1.794 1.794 0 0 0 2.567 -0.194l2.611 1.135a0.438 0.438 0 1 0 0.349 -0.803l-2.578 -1.121a1.833 1.833 0 0 0 0.037 -0.362 1.799 1.799 0 0 0 -1.359 -1.743V1.437a0.438 0.438 0 1 0 -0.876 0v2.104c0 0.242 0.196 0.438 0.438 0.438a0.921 0.921 0 0 1 0.92 0.917l-0.614 -0.267a0.438 0.438 0 1 0 -0.349 0.804l0.545 0.237a0.917 0.917 0 0 1 -0.502 0.15 0.921 0.921 0 0 1 -0.92 -0.92 0.438 0.438 0 1 0 -0.876 0c0 0.21 0.037 0.411 0.103 0.598L3.9 7.516a1.238 1.238 0 0 0 -0.526 -0.119 1.228 1.228 0 0 0 -1.227 1.227v0.14H1.455C0.653 8.764 0 9.425 0 10.237v9.309a1.458 1.458 0 0 0 1.455 1.455h19.089a1.458 1.458 0 0 0 1.455 -1.455V10.237c0 -0.812 -0.653 -1.472 -1.455 -1.472m-2.27 -0.14a0.351 0.351 0 0 1 0.701 0v0.14h-0.701zm-15.251 0a0.351 0.351 0 0 1 0.701 0v0.14H3.024zm17.521 1.017c0.314 0 0.579 0.273 0.579 0.596v9.309c0 0.319 -0.26 0.579 -0.579 0.579H1.455a0.58 0.58 0 0 1 -0.579 -0.579V10.237c0 -0.323 0.265 -0.596 0.579 -0.596z"/>
    <path style="fill:#4D3D36;" d="M4.01 10.881a0.438 0.438 0 0 0 -0.438 0.438v7.144a0.438 0.438 0 1 0 0.876 0V11.319a0.438 0.438 0 0 0 -0.438 -0.438"/>
    <path style="fill:#4D3D36;" d="M7.472 10.881a0.438 0.438 0 0 0 -0.438 0.438v7.144a0.438 0.438 0 1 0 0.876 0V11.319a0.438 0.438 0 0 0 -0.438 -0.438"/>
    <path style="fill:#4D3D36;" d="M10.978 10.881a0.438 0.438 0 0 0 -0.438 0.438v7.144a0.438 0.438 0 1 0 0.876 0V11.319a0.438 0.438 0 0 0 -0.438 -0.438"/>
    <path style="fill:#4D3D36;" d="M14.484 10.881a0.438 0.438 0 0 0 -0.438 0.438v7.144a0.438 0.438 0 1 0 0.876 0V11.319a0.438 0.438 0 0 0 -0.438 -0.438"/>
    <path style="fill:#4D3D36;" d="M17.99 10.881a0.438 0.438 0 0 0 -0.438 0.438v7.144a0.438 0.438 0 1 0 0.876 0V11.319a0.438 0.438 0 0 0 -0.438 -0.438"/>
    <path style="fill:#4D3D36;" d="m16.304 7.712 0.304 0.132a0.439 0.439 0 0 0 0.348 -0.804l-0.304 -0.132a0.438 0.438 0 0 0 -0.348 0.804"/>
  </g>
</svg>

`




const cigaretteIconUrl_22x22 = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" viewBox="0 0 22 22" xml:space="preserve" width="22" height="22">
  <path x="75.294" style="fill:#F2F2F4;" width="361.412" height="512" d="M3.235 0H18.765V22H3.235V0z"/>
  <path x="256" style="fill:#DFDFE1;" width="180.706" height="512" d="M11 0H18.765V22H11V0z"/>
  <path style="fill:#E17858;" points="436.706,361.412 436.706,0 75.294,0 75.294,361.412 256,180.706 " d="M18.765 15.529L18.765 0L3.235 0L3.235 15.529L11 7.765Z"/>
  <path style="fill:#FF4F19;" points="256,0 256,180.706 436.706,361.412 436.706,0 " d="M11 0L11 7.765L18.765 15.529L18.765 0Z"/>
  <path x="180.706" y="67.765" style="fill:#F2F2F4;" width="150.588" height="45.176" d="M7.765 2.912H14.235V4.853H7.765V2.912z"/>
  <path x="256" y="67.765" style="fill:#DFDFE1;" width="75.294" height="45.176" d="M11 2.912H14.235V4.853H11V2.912z"/>
  <path style="fill:#FFEA8A;" cx="256" cy="376.471" r="45.176" d="M12.941 16.176A1.941 1.941 0 0 1 11 18.118A1.941 1.941 0 0 1 9.059 16.176A1.941 1.941 0 0 1 12.941 16.176z"/>
  <path style="fill:#FFDB2D;" d="M11 14.235v3.882c1.072 0 1.941 -0.869 1.941 -1.941S12.072 14.235 11 14.235"/>
</svg>

`


const palletIconUrl_22x22 = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="22" height="22">
  <g>
    <path x="11.13" y="445.217" style="fill:#EBEBEB;" width="489.739" height="44.522" d="M0.478 19.13H21.522V21.043H0.478V19.13z"/>
    <path x="11.13" y="333.913" style="fill:#EBEBEB;" width="489.739" height="44.522" d="M0.478 14.348H21.522V16.261H0.478V14.348z"/>
  </g>
  <g>
    <path x="228.174" y="378.435" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M9.804 16.261H12.196V19.13H9.804V16.261z"/>
    <path x="61.217" y="378.435" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M2.63 16.261H5.022V19.13H2.63V16.261z"/>
    <path x="395.13" y="378.435" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M16.978 16.261H19.37V19.13H16.978V16.261z"/>
  </g>
  <g>
    <path x="11.13" y="289.391" style="fill:#FFAC3D;" width="489.739" height="44.522" d="M0.478 12.435H21.522V14.348H0.478V12.435z"/>
    <path x="11.13" y="178.087" style="fill:#FFAC3D;" width="489.739" height="44.522" d="M0.478 7.652H21.522V9.565H0.478V7.652z"/>
  </g>
  <g>
    <path x="228.174" y="222.609" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M9.804 9.565H12.196V12.435H9.804V9.565z"/>
    <path x="61.217" y="222.609" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M2.63 9.565H5.022V12.435H2.63V9.565z"/>
    <path x="395.13" y="222.609" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M16.978 9.565H19.37V12.435H16.978V9.565z"/>
  </g>
  <g>
    <path x="11.13" y="133.565" style="fill:#EBEBEB;" width="489.739" height="44.522" d="M0.478 5.739H21.522V7.652H0.478V5.739z"/>
    <path x="11.13" y="22.261" style="fill:#EBEBEB;" width="489.739" height="44.522" d="M0.478 0.957H21.522V2.87H0.478V0.957z"/>
  </g>
  <g>
    <path x="228.174" y="66.783" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M9.804 2.87H12.196V5.739H9.804V2.87z"/>
    <path x="61.217" y="66.783" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M2.63 2.87H5.022V5.739H2.63V2.87z"/>
    <path x="395.13" y="66.783" style="fill:#A8A8A8;" width="55.652" height="66.783" d="M16.978 2.87H19.37V5.739H16.978V2.87z"/>
  </g>
  <path style="fill:#231F20;" d="M21.522 3.348c0.264 0 0.478 -0.214 0.478 -0.478V0.957c0 -0.264 -0.214 -0.478 -0.478 -0.478H0.478C0.214 0.478 0 0.692 0 0.957v1.913c0 0.264 0.214 0.478 0.478 0.478h1.674v1.913H0.478c-0.264 0 -0.478 0.214 -0.478 0.478v3.826c0 0.264 0.214 0.478 0.478 0.478h1.674v1.913H0.478C0.214 11.956 0 12.171 0 12.435v3.826c0 0.264 0.214 0.478 0.478 0.478h1.674v1.913H0.478c-0.264 0 -0.478 0.214 -0.478 0.478v1.913c0 0.264 0.214 0.478 0.478 0.478h21.044c0.264 0 0.478 -0.214 0.478 -0.478v-1.913c0 -0.264 -0.214 -0.478 -0.478 -0.478h-1.674v-1.913h1.674c0.264 0 0.478 -0.214 0.478 -0.478V12.435c0 -0.264 -0.214 -0.478 -0.478 -0.478h-1.674v-1.913h1.674c0.264 0 0.478 -0.214 0.478 -0.478v-3.826c0 -0.264 -0.214 -0.478 -0.478 -0.478h-1.674V3.348zM0.957 1.435h20.087v0.957H0.957zm11.717 3.826V3.348H16.5v1.913zm-7.174 0V3.348h3.826v1.913zm4.783 -1.913h1.435v1.913h-1.435zm-7.174 0h1.435v1.913H3.109zm0 6.696h1.435v1.913H3.109zm6.217 0v1.913H5.5v-1.913zm7.174 0v1.913h-3.826v-1.913zm-4.783 1.913h-1.435v-1.913h1.435zM3.109 16.739h1.435v1.913H3.109zm6.217 0v1.913H5.5v-1.913zm7.174 0v1.913h-3.826v-1.913zm-4.783 1.913h-1.435v-1.913h1.435zm9.326 1.913H0.957v-0.957h20.087zm-2.152 -1.913h-1.435v-1.913h1.435zm2.152 -2.87H0.957v-0.957h20.087zm0 -1.913H0.957v-0.957h20.087zm-2.152 -1.913h-1.435v-1.913h1.435zm2.152 -2.87H0.957v-0.957h20.087zm0 -1.913H0.957v-0.957h20.087zm-2.152 -1.913h-1.435V3.348h1.435z"/>
</svg>


`


const boatIconUrl_22x22_map_icon_color = `
<svg version="1.1" id="Capa_1" stroke="{mapIconColor}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" style="enable-background:new 0 0 212.5 212.5;" xml:space="preserve" width="22" height="22">
  <path d="m21.792 16.408 -3.339 3.585a0.776 0.776 0 0 1 -0.568 0.247H2.755a0.776 0.776 0 0 1 -0.68 -0.401L0.097 16.255a0.776 0.776 0 0 1 0.011 -0.77 0.776 0.776 0 0 1 0.669 -0.382H21.224a0.776 0.776 0 0 1 0.568 1.306m-0.943 -4.003L9.773 1.97a0.776 0.776 0 0 0 -1.309 0.565v10.436a0.776 0.776 0 0 0 0.776 0.776h11.076a0.776 0.776 0 0 0 0.533 -1.342M6.545 7.968a0.776 0.776 0 0 0 -0.84 0.148L1.151 12.406a0.776 0.776 0 0 0 0.533 1.342h4.553a0.776 0.776 0 0 0 0.776 -0.776V8.681a0.776 0.776 0 0 0 -0.469 -0.713"/>
</svg>

`
const boatIconUrl_22x22_current_color = `
<svg version="1.1" id="Capa_1" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" style="enable-background:new 0 0 212.5 212.5;" xml:space="preserve" width="22" height="22">
  <path d="m21.792 16.408 -3.339 3.585a0.776 0.776 0 0 1 -0.568 0.247H2.755a0.776 0.776 0 0 1 -0.68 -0.401L0.097 16.255a0.776 0.776 0 0 1 0.011 -0.77 0.776 0.776 0 0 1 0.669 -0.382H21.224a0.776 0.776 0 0 1 0.568 1.306m-0.943 -4.003L9.773 1.97a0.776 0.776 0 0 0 -1.309 0.565v10.436a0.776 0.776 0 0 0 0.776 0.776h11.076a0.776 0.776 0 0 0 0.533 -1.342M6.545 7.968a0.776 0.776 0 0 0 -0.84 0.148L1.151 12.406a0.776 0.776 0 0 0 0.533 1.342h4.553a0.776 0.776 0 0 0 0.776 -0.776V8.681a0.776 0.776 0 0 0 -0.469 -0.713"/>
</svg>

  `

const icons = [
  {
    name: "mapIconUrl_12x12",
    label: "mapIconUrl_12x12",
    iconUrlCurrentColor: mapIconUrl_12x12_current_color,
    iconUrlMapIconColor: mapIconUrl_12x12_map_icon_color,
    viewBox: "0 0 12 12"

  },
  {
    name: "mapIconUrl_16x16",
    label: "mapIconUrl_16x16",
    iconUrlCurrentColor: mapIconUrl_16x16_current_color,
    iconUrlMapIconColor: mapIconUrl_16x16_map_icon_color,
    viewBox: "0 0 16 16"

  },
  {
    name: "addressIconUrl_24x24",
    label: "addressIconUrl_24x24",
    iconUrlCurrentColor: addressIconUrl_24x24_current_color,
    iconUrlMapIconColor: addressIconUrl_24x24_map_icon_color,
    viewBox: "0 0 24 24"

  },
  {
    name: "tractorIconUrl_36x36",
    label: "tractorIconUrl_36x36",
    iconUrlCurrentColor: tractorIconUrl_36x36,
    iconUrlMapIconColor: tractorIconUrl_36x36,
    viewBox: "0 0 36 36"

  },
  {
    name: "backhoeIconUrl_48x48",
    label: "backhoeIconUrl_48x48",
    iconUrlCurrentColor: backhoeIconUrl_48x48_current_color,
    iconUrlMapIconColor: backhoeIconUrl_48x48_map_icon_color,
    viewBox: "0 0 48 48"

  },
  {
    name: "carIconUrl_22x22",
    label: "carIconUrl_22x22",
    iconUrlCurrentColor: carIconUrl_22x22,
    iconUrlMapIconColor: carIconUrl_22x22,
    viewBox: "0 0 22 22"
  },
  {
    name: "containerIconUrl_22x22",
    label: "containerIconUrl_22x22",
    iconUrlCurrentColor: containerIconUrl_22x22,
    iconUrlMapIconColor: containerIconUrl_22x22,
    viewBox: "0 0 22 22"
  },

  {
    name: "cigaretteIconUrl_22x22",
    label: "cigaretteIconUrl_22x22",
    iconUrlCurrentColor: cigaretteIconUrl_22x22,
    iconUrlMapIconColor: cigaretteIconUrl_22x22,
    viewBox: "0 0 22 22"
  },

  {
    name: "palletIconUrl_22x22",
    label: "palletIconUrl_22x22",
    iconUrlCurrentColor: palletIconUrl_22x22,
    iconUrlMapIconColor: palletIconUrl_22x22,
    viewBox: "0 0 22 22"
  },
  {
    name: "boatIconUrl_22x22",
    label: "boatIconUrl_22x22",
    iconUrlCurrentColor: boatIconUrl_22x22_current_color,
    iconUrlMapIconColor: boatIconUrl_22x22_map_icon_color,
    viewBox: "0 0 22 22"
  }





]


const getIconByName = (iconName) => {
  return icons.filter((i => i.name === iconName)).length > 0 ? icons.filter((i => i.name === iconName))[0] : icons.filter((i => i.name === "mapIconUrl_12x12"))[0]

}





export const getIconChoices = () => {
  return icons
}






export const getMarkerDefaultIcon = (iconName, iconColor) => {



  const iconSettings = {
    mapIconUrl: getIconByName(iconName).iconUrlMapIconColor,
    mapIconColor: iconColor ? iconColor : '#0000ff',
  }



  return new L.divIcon({
    className: "leaflet-data-marker",
    html: L.Util.template(iconSettings.mapIconUrl, iconSettings),

  })


}







