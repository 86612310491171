import React, { useState } from 'react';
import { useTranslate, useRefresh, DateField, useLocaleState, useNotify, ShowBase, useShowContext } from 'react-admin';
import { Box, Button, Table, TableBody, Tooltip, useTheme } from '@mui/material'
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { updateDeviceConfiguation } from '../../rest/restClient';
import { isDgmatter } from '../../helper/resourceHelper';
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';
import { CardDetailRow } from '../Common';
import StandardDialog from '../dialog/StandardDialog';
import LoadingDialog from '../dialog/LoadingDialog';



const Icon = ({ color }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-live-photo"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" /><path d="M15.9 20.11l0 .01" /><path d="M19.04 17.61l0 .01" /><path d="M20.77 14l0 .01" /><path d="M20.77 10l0 .01" /><path d="M19.04 6.39l0 .01" /><path d="M15.9 3.89l0 .01" /><path d="M12 3l0 .01" /><path d="M8.1 3.89l0 .01" /><path d="M4.96 6.39l0 .01" /><path d="M3.23 10l0 .01" /><path d="M3.23 14l0 .01" /><path d="M4.96 17.61l0 .01" /><path d="M8.1 20.11l0 .01" /><path d="M12 21l0 .01" /></svg>

    )
}


const ConfigurationContent = ({ configuration }) => {
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    if (!configuration) return null
    return (
        <Box sx={{
            width: "100%",
            padding: "0px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>


                    {configuration.recovery_mode_status && (
                        <CardDetailRow label={translate('resources.devices.recovery_mode_status')} ValueComponent={() => <Typography sx={{ fontWeight: 400 }}>{configuration.recovery_mode_status.toUpperCase()}</Typography>} />
                    )}

                    {configuration.last_recovery_mode_request && (
                        <CardDetailRow label={translate('resources.devices.last_recovery_mode_request')} ValueComponent={() => <DateField
                            source="last_recovery_mode_request"
                            record={configuration}
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />} />
                    )}


                    {configuration.last_recovery_mode_entry && (
                        <CardDetailRow label={translate('resources.devices.last_recovery_mode_entry')} ValueComponent={() => <DateField
                            source="last_recovery_mode_entry"
                            record={configuration}
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />} />
                    )}


                    {configuration.last_recovery_mode_exit && (
                        <CardDetailRow label={translate('resources.devices.last_recovery_mode_exit')} ValueComponent={() => <DateField
                            source="last_recovery_mode_exit"
                            record={configuration}
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />} />
                    )}


                </TableBody>

            </Table>
        </Box>
    );
}



const TrackingModeDialogContent = ({ setopenDialog }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const notify = useNotify();
    const refresh = useRefresh();
    const [openTrackingModeInfoDialog, setOpenTrackingModeInfoDialog] = useState(false);

    const { record, isPending } = useShowContext();


    if (isPending) {
        return <LoadingDialog isPending={isPending} />
    }


    if (!record) {
        setTimeout(() => {
            setopenDialog(false);
        }, 2000);
        return (null)
    }


    if (record.configuration === undefined || !isDgmatter(record)) {
        setTimeout(() => {
            setopenDialog(false);
        }, 2000);
        return (null)
    }

    const trackingModeStatus = record.configuration.recovery_mode_status.toLowerCase();
    const lastRecoveryMode = record.configuration.last_recovery_mode_request ? new Date(record.configuration.last_recovery_mode_request) : null
    const durationFomLastRecoveryModeHr = lastRecoveryMode ? Math.ceil(Math.abs(new Date() - lastRecoveryMode) / (1000 * 60 * 60)) : null;



    let contentBg = ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(54, 65, 82, 0.4) 0%, rgba(255, 255, 255, 0.2) 38.3%)`]
    let buttonLabel = null;
    let buttonHandleClick = null;
    let note = null;

    const activateTrakingMode = () => {
        updateDeviceConfiguation({ device_configuration: { id: record.configuration.device_configuration_id, recovery_mode_request: (new Date()).toISOString() } })
            .then((response) => {
                if (response.status === 202 || response.status === 200) {
                    notify(translate("resources.devices.success_setting_on_tracking_mode"), { type: 'info' });
                    setopenDialog(false);
                    refresh();
                } else {
                    notify(translate("resources.devices.error_setting_on_tracking_mode"), { type: 'warning' });
                    setopenDialog(false);
                }
            })
            .catch((e) => {
                notify(translate("resources.devices.error_setting_on_tracking_mode"), { type: 'warning' });
                setopenDialog(false);
            })
    }

    if (trackingModeStatus === "inactive") {
        buttonLabel = translate("resources.devices.go_tracking_mode");
        buttonHandleClick = activateTrakingMode
    }
    if (trackingModeStatus === "active") {
        contentBg = ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`]
    }

    if (trackingModeStatus === "pending" && durationFomLastRecoveryModeHr && durationFomLastRecoveryModeHr > 24) {
        note = translate("resources.devices.go_tracking_mode_because_pending_more_than_24_jours");
        contentBg = ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(255, 193, 7, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`]
        buttonLabel = translate("resources.devices.go_tracking_mode");
        buttonHandleClick = activateTrakingMode
    } else if (trackingModeStatus === "pending") {
        contentBg = ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(255, 193, 7, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`]
    }




    return (
        <DialogContent sx={{ background: { xs: contentBg, lg: contentBg } }}>
            <DialogTitle id="alert-dialog-title" sx={{ display: "flex", flexDirection: 'row', minWidth: "200px", justifyContent: "center", padding: "0px", margin: "0px 0px 15px 0px" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500, color: theme.palette.text.primary }}>{translate(`resources.devices.labels.tracking_mode`)}</Typography>
            </DialogTitle>

            <ConfigurationContent configuration={record.configuration} />
            <Typography sx={{ fontSize: "14px", fontWeight: 500, color: theme.palette.text.primary, margin: "20px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_title")}</Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 0px 10px" }}>{`${translate("resources.devices.tracking_mode_info_1").substring(0, 120)}...`}</Typography>
            <Button
                variant='text'
                onClick={() => { setOpenTrackingModeInfoDialog(true); }}
                sx={{ fontSize: "12px", fontWeight: 400, }}
            >
                {translate("resources.common.view_more")}
            </Button>
            <StandardDialog
                open={openTrackingModeInfoDialog}
                onClose={() => { }}
                fullWidth={false}
                BodyComponent={() =>
                    <Box sx={{ display: "flex", justifyContent: 'flex-start', flexDirection: "column", margin: "15px 4px 8px 4px", maxWidth: "400px" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 500, color: theme.palette.text.primary, margin: "20px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_title")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_1")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_2")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_3")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_4")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_5")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_6")}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: theme.palette.text.secondary, margin: "10px 10px 10px 10px" }}>{translate("resources.devices.tracking_mode_info_7")}</Typography>
                        <Button
                            variant='text'
                            onClick={() => { setOpenTrackingModeInfoDialog(false); }}
                            sx={{ fontSize: "16px", margin: "20px 0px 0px 0px" }}
                        >
                            {translate("ra.action.close")}
                        </Button>

                    </Box>
                } />



            {
                note &&
                <Typography sx={{ fontSize: "12px", fontWeight: 400, color: theme.palette.error.dark, margin: "10px 10px 10px 10px" }}>{note}</Typography>

            }




            <Box sx={{ display: "flex", justifyContent: 'center', margin: "15px 4px 8px 4px" }}>
                {
                    buttonLabel &&
                    buttonHandleClick &&
                    <Button
                        variant='contained'
                        onClick={buttonHandleClick}
                    >
                        {buttonLabel}
                    </Button>

                }
            </Box>




            <Box sx={{ display: "flex", justifyContent: 'center', margin: "8px 4px 8px 4px" }}>

                <Button
                    variant='text'
                    onClick={() => { setopenDialog(false); }}
                >
                    {translate("ra.action.close")}
                </Button>


            </Box>

        </DialogContent>




    )







};


const TrackingModeDialog = ({ open, setOpen, deviceId }) => {

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            sx={{
                "& .MuiPaper-root": {
                    margin: "10px",
                    padding: "0px"
                }
            }}

        >

            <ShowBase resource="devices" emptyWhileLoading={true} id={deviceId} >
                <TrackingModeDialogContent setopenDialog={setOpen} />
            </ShowBase>
        </Dialog>)




}

const TrackingModeButton = ({
    record = {},
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const [openDialog, setopenDialog] = useState(false);

    if (!record || !record.id || !isDgmatter(record)) {
        return (null)
    }

    return (


        <>
            <Tooltip title={translate('resources.devices.labels.tracking_mode')} disableInteractive>
                <Button
                    sx={listActionsColumnIconCommonStyle({ isLastItem: false })}
                    onClick={() => { setopenDialog(true); }}
                >
                    <Icon color={theme.palette.primary.main} />
                </Button>


            </Tooltip>
            <TrackingModeDialog deviceId={record.id} open={openDialog} setOpen={setopenDialog} />
        </>

    )

};






export default TrackingModeButton;



