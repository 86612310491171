import React, { useRef } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button, Slide, Typography } from '@mui/material'
import {
    Filter,
    List,
    ReferenceInput,
    useTranslate,
    usePermissions,
    TextInput,
    AutocompleteInput,
    Pagination,
    useListContext
} from 'react-admin'
import { hasWriteAccess, PER_PAGE } from '../../../helper/resourceHelper'
import MobileGrid from './MobileGrid'
import { useMediaQuery, useTheme, Dialog, DialogContent } from '@mui/material';
import { autocompleteInputCommonStyle, listCommonStyle, searchInputCommonStyle } from '../../../themes/commonStyles';
import { AddBtnWithOptionalMenu } from '../../../ui/button/AddBtn';
import LoadingDialog from '../../../ui/dialog/LoadingDialog';



const FiltersIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-filter">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" strokeWidth="0.6666666666666666" />
            <path d="M2.667 2.667h10.667v1.448a1.333 1.333 0 0 1 -0.391 0.943L10 8v4.667l-4 1.333v-5.667L3.013 5.048A1.333 1.333 0 0 1 2.667 4.151z" />
        </svg>
    )
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FiltersDialog = ({ open, setOpen }) => {

    const theme = useTheme();
    const contentBg = theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default;
    const translate = useTranslate();
    const dialogRef = useRef(null);
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });


    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, undefined, false);
        } else {
            hideFilter("main");
        }

        setTimeout(() => {
            setOpen(false)
        }, 500);
    };

    const resetFilter = () => {
        setFilters({}, [], false);
        setTimeout(() => {
            setOpen(false)
        }, 500);
    };


    const handleClose = () => setOpen(false);




    const handleDrag = (event) => {
        const dialogElement = dialogRef.current;
        if (!dialogElement) return;

        // Detect touch or mouse events
        const startY = event.type === 'mousedown'
            ? event.clientY
            : event.touches[0].clientY;

        const onMove = (moveEvent) => {
            const currentY = moveEvent.type === 'mousemove'
                ? moveEvent.clientY
                : moveEvent.touches[0].clientY;

            const deltaY = currentY - startY;

            if (deltaY > 150) {
                // Close the dialog if dragged down more than 150px
                handleClose();
            } else {
                // Temporarily move the dialog down
                dialogElement.style.transform = `translateY(${Math.min(deltaY, 150)}px)`;
            }
        };

        const onEnd = () => {
            // Reset dialog position and remove listeners
            dialogElement.style.transform = '';
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onEnd);
            window.removeEventListener('touchmove', onMove);
            window.removeEventListener('touchend', onEnd);
        };

        // Add listeners for dragging and release
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onEnd);
        window.addEventListener('touchmove', onMove);
        window.addEventListener('touchend', onEnd);
    };

    return (
        <Dialog
            ref={dialogRef}
            open={open}
            TransitionComponent={Transition}
            onClose={() => { }}
            fullScreen
            PaperProps={{
                style: { boxShadow: 'none' }, // Optional: Remove shadow around the dialog
            }}
            slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } }
            }}
            sx={{
                "& .MuiPaper-root": {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    height: "auto",
                    minHeight: "300px",
                    maxHeight: "400px",
                    margin: "0px",
                    padding: "0px",
                    border: 1,
                    borderTopColor: theme.palette.text.disabled,
                    borderWidth: "1px",
                    borderRadius: "12px 12px 0px 0px"
                }
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "15px",
                    margin: "0px",
                    padding: "10px 0px 10px 0px",
                    borderRadius: "0px",
                    backgroundColor: "none",
                    opacity: 0.45,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onMouseDown={handleDrag} // Handle mouse drag
                onTouchStart={handleDrag}

            >
                <Box
                    sx={{
                        width: "40px",
                        height: "4px",
                        margin: "1px",
                        borderRadius: "12px",
                        backgroundColor: theme.palette.text.secondary,
                        opacity: 0.45
                    }}


                >
                </Box>
            </Box>
            <DialogContent

                sx={{
                    width: "100%",
                    margin: "0px",
                    padding: "0px",
                    background: contentBg,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ padding: "0px 0px 0px 16px" }}>
                        <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>
                            {translate("resources.common.filter")}
                        </Typography>
                    </Box>
                </Box>

                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}
                        style={{
                            width: "100%",
                            height: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                margin: "25px 0px 0px 0px",
                                padding: "16px 16px 30px 16px",
                                background: contentBg,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >

                            <Box component="span" mb="16px" sx={{ width: "100%" }}>
                                <ReferenceInput source="device_id" reference="devices" alwaysOn >
                                    <AutocompleteInput
                                        id="outlined-basic-size-small"
                                        optionText="name"
                                        matchSuggestion={() => true} label="resources.hooks.fields.device"
                                        sx={autocompleteInputCommonStyle({ theme, width: "100%" })}
                                    />
                                </ReferenceInput>
                            </Box>



                            <Box component="span" mt="16px" sx={{ width: "100%" }}>
                                <Button variant="contained" color="primary" type="submit" sx={{ width: "100%" }}>
                                    {translate('resources.common.apply_filters')}
                                </Button>
                            </Box>
                            <Box component="span" mb="8px" sx={{ width: "100%" }}>
                                <Button variant="text" sx={{ width: "100%" }} onClick={resetFilter} >
                                    {translate('resources.common.reset_filters')}
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </FormProvider>
            </DialogContent>


        </Dialog>
    )
}


const FiltersBtn = ({ label, color }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <Button sx={{ color: color }}
                onClick={(e) => { e.preventDefault(); setOpen(!open) }}
            >
                <FiltersIcon color={color} />&nbsp;{label}

            </Button>
            <FiltersDialog open={open} setOpen={setOpen} />
        </div>



    )
}


const NotificationPagination = ({ cardMode }) => {
    const theme = useTheme()

    return (<Pagination sx={{
        '& .MuiToolbar-root': {
            borderTopRightRadius: cardMode ? "12px" : "0px",
            borderTopLeftRadius: cardMode ? "12px" : "0px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            marginTop: cardMode ? "25px" : "0px",

        }

    }} rowsPerPageOptions={[10, 25, 50, 100, 500]} />)
};



const NotificationActions = ({
    isMobile
}) => {
    const { resource } = useListContext();
    const { isLoading, permissions } = usePermissions();
    const theme = useTheme();
    const translate = useTranslate();
    const addBtnMenuItems = []


    if (hasWriteAccess(permissions)) {
        addBtnMenuItems.push({
            label: translate('resources.notifications.create'),
            path: `/${resource}/create`
        })
    }

    if (!isMobile) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "row"

            }}>

                <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} isMobile={isMobile} />
            </Box>
        )
    }


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0px",
            margin: "0px",
            width: "100%",
        }}>
            <FiltersBtn
                color={theme.palette.text.secondary}
                label={translate("resources.common.filters")}
            />

            <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} isMobile={isMobile} />
        </Box>
    )



}

const SearchInputLabelComponent = (labelProps) => {

    const translate = useTranslate();
    const SearchIcon = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_165_9911)">
                    <path d="M10.3338 9.33326H9.80708L9.62042 9.15326C10.4204 8.21993 10.8338 6.9466 10.6071 5.59326C10.2938 3.73993 8.74708 2.25993 6.88042 2.03326C4.06042 1.6866 1.68708 4.05993 2.03375 6.87993C2.26042 8.74659 3.74042 10.2933 5.59375 10.6066C6.94708 10.8333 8.22042 10.4199 9.15375 9.61993L9.33375 9.80659V10.3333L12.1671 13.1666C12.4404 13.4399 12.8871 13.4399 13.1604 13.1666C13.4338 12.8933 13.4338 12.4466 13.1604 12.1733L10.3338 9.33326ZM6.33375 9.33326C4.67375 9.33326 3.33375 7.99326 3.33375 6.33326C3.33375 4.67326 4.67375 3.33326 6.33375 3.33326C7.99375 3.33326 9.33375 4.67326 9.33375 6.33326C9.33375 7.99326 7.99375 9.33326 6.33375 9.33326Z" fill="#697586" />
                </g>
                <defs>
                    <clipPath id="clip0_165_9911">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        }}>
            <SearchIcon {...labelProps} />
            <div style={{
                marginLeft: "8px",
                color: "inherit",
            }}>
                {translate('resources.common.search')}
            </div>
        </div>

    )
}

const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};


const NotificationList = (props) => {
    const { isLoading, permissions } = usePermissions();
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.notifications.name', { smart_count: 2 })}
            </Typography>

            <List

                title={translate('resources.notifications.name', { smart_count: 2 })}
                actions={<NotificationActions isMobile={isSmall} />}

                filters={!isSmall ?
                    <Filter {...props}>

                        <ReferenceInput source="device_id" reference="devices" alwaysOn >
                            <AutocompleteInput
                                id="outlined-basic-size-small"
                                optionText={(r) => r.serial_number ? `${r.name} #${r.serial_number}` : r.name}
                                matchSuggestion={() => true} label="resources.hooks.fields.device"
                                sx={autocompleteInputCommonStyle({ theme })}
                            />
                        </ReferenceInput>
                    </Filter> : null
                }
                sort={{ field: 'name', order: 'ASC' }}
                perPage={PER_PAGE}
                pagination={false}
                sx={listCommonStyle({ isCardMode: true, theme, isSmall })}
            >
                <MobileGrid isSmall={isSmall} />

                <NotificationPagination cardMode={true} />
                <LoadingPopUp />

            </List>
        </>
    )
}

export default NotificationList