import React, { useEffect, useState } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import {
    ArrayInput,
    Create,
    required,
    SaveButton,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
    Toolbar,
} from 'react-admin'
import PhoneInput from 'react-phone-number-input'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, SvgIcon, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import CalendarTimeSlotSelector from '../../../ui/inputs/CalendarTimeSlotSelector';
import GeofenceInput from '../GeofenceInput';
import StandardDialog from '../../../ui/dialog/StandardDialog';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';
import { getUiHiddenMessages, USER_ID, USER_USER_UI_SETUP_FETCHED_AT } from '../../configuration/actions';
import { updateUserUiSetup } from '../../../rest/restClient';






const validateRequired = [required('Champs obligatoire')]

const styles = theme => ({
    width100: {
        width: 320,
        marginTop: 16,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const PhoneNumberInput = ({ value, onChange }) => {
    const translate = useTranslate();
    return (
        <PhoneInput
            country="FR"
            placeholder={translate('resources.notifications.enter_phone_number')}
            value={value}
            onChange={onChange}
            sx={{
                width: '320px',
                marginTop: '16px'
            }}
        />
    )
}
const PhoneNumberField = ({ source }) => {
    const input = useController({ name: source, defaultValue: '' });
    return <PhoneNumberInput {...input.field} />
}

const DrawGeofenceButton = ({ onClick }) => {
    const theme = useTheme();
    const iconUrl = `
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-x">
  <path stroke="none" d="M0 0h48v48H0z" fill="none" stroke-width="2"/>
  <path d="M17.12 7.38a18 18 0 0 0 -5.84 3.9"/>
  <path d="M7.38 17.12A18 18 0 0 0 6 24"/>
  <path d="M7.38 30.88a18 18 0 0 0 3.9 5.84"/>
  <path d="M17.12 40.62A18 18 0 0 0 24 42"/>
  <path d="M30.88 40.62a18 18 0 0 0 5.84 -3.9"/>
  <path d="M40.62 30.88A18 18 0 0 0 42 24"/>
  <path d="M40.62 17.12a18 18 0 0 0 -3.9 -5.84"/>
  <path d="M30.88 7.38A18 18 0 0 0 24 6"/>
  <path d="m28 28 -8 -8"/>
  <path d="m20 28 8 -8"/>
</svg>
    `
    return (
        <Button
            sx={{
                margin: "0px",
                padding: "5px",
                width: "60px",
                height: "60px",

            }}
            onClick={onClick}>
            <Box
                sx={{
                    margin: "0px",
                    padding: "0px",
                    borderRadius: "10px",
                    width: "50px",
                    height: "50px",
                    borderColor: `${theme.palette.text.primary} !important`,
                    border: 1,
                    borderWidth: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"

                }}
            >
                <SvgIcon sx={{ color: `${theme.palette.text.primary} !important` }} viewBox={"0 0 48 48"}>
                    <g dangerouslySetInnerHTML={{ __html: iconUrl }} />
                </SvgIcon>

            </Box>

        </Button>
    )
}



const NotificationEditToolBar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ComponentBySourceEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element === equals) {
        return (
            <Component />
        )
    }
    return null;
}


const NotificationSubjectsInputHelper = ({
    setDeviceId
}) => {
    const { getValues, setValue } = useFormContext();
    const selectedDeviceId = useWatch({ name: 'device_id' });

    useEffect(async () => {
        if (selectedDeviceId) {
            setDeviceId(selectedDeviceId)
        }
    }, [selectedDeviceId, getValues, setValue]);

    return (
        <>
        </>
    );
};

const InfoAboutDestinationVerificationDialog = ({ openDialog, setOpenDialog }) => {
    const translate = useTranslate();
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const handleDoNotShowAgain = (event) => {
        setDoNotShowAgain(event.target.checked);
    };

    const handleConfirm = () => {
        setOpenDialog(false);
        if (doNotShowAgain) {
            const userId = localStorage.getItem(USER_ID)
            const patchUserUiSetup = {
                "messages_to_hide": ["info_about_destination_verification"]
            }
            updateUserUiSetup(userId, patchUserUiSetup).then((res) => {
                if (res && res.status === 200) {
                    localStorage.removeItem(USER_USER_UI_SETUP_FETCHED_AT)
                }
            })
        }
    };


    return (
        <StandardDialog
            open={openDialog}
            fullWidth={true}
            BodyComponent={() => {

                return (
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 10px 0px", fontWeight: 500 }}>
                                {translate('resources.notifications.info_about_destination_verification.title')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.notifications.info_about_destination_verification.message1')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.notifications.info_about_destination_verification.message2')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "10px 0px 0px 0px", fontWeight: 400 }}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox value={doNotShowAgain} onChange={handleDoNotShowAgain} />} label={translate('resources.common.do_not_show_this_message_again')} />
                                </FormGroup>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                            <TableCell align="center" sx={{ padding: "0px", fontWeight: 500 }}>
                                <Button onClick={handleConfirm} variant='contained' sx={{ margin: "10px 0px  0px 10px", }}>
                                    {translate('resources.common.i_confirm')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )
            }}
        />
    );
};


const NotificationCreate = () => {

    const translate = useTranslate();
    const theme = useTheme();
    const [deviceId, setDeviceId] = useState(null);
    const [openInfoAboutDestinationVerificationDialog, setOpenInfoAboutDestinationVerificationDialog] = useState(false);

    const transform = (data) => {
        if (data && data.device_id) {
            data.device_ids = [data.device_id]
            delete data.device_id;
        }

        return data

    };

    useEffect(() => {
        setTimeout(async () => {
            const hiddenMessages = await getUiHiddenMessages();
            if (Array.isArray(hiddenMessages) && hiddenMessages.includes('info_about_destination_verification')) {
                setOpenInfoAboutDestinationVerificationDialog(false)
            } else {
                setOpenInfoAboutDestinationVerificationDialog(true)
            }
        }, 500);

    }, [])



    const inputsDataGeneral = [
        { name: 'name', label: translate('resources.notifications.fields.name'), validate: validateRequired, type: "text" },
        { name: 'applyOn', label: translate('resources.notifications.fields.applyOn'), validate: validateRequired, type: "select", choices: [{ id: 'oneDevice', name: translate('resources.notifications.fields.applyOnOneDevice') }, { id: 'devices', name: translate('resources.notifications.fields.applyOnGroupOfDevices') }, { id: 'organizations', name: translate('resources.notifications.fields.applyOnGroupOfOrganizations') }] },
    ]


    const inputsDataApplyOnOneDevice = [
        { name: 'device_id', label: translate('resources.notifications.fields.device_id'), validate: validateRequired, type: "reference", reference: "devices", optionText: "name" },
    ]
    const inputsDataApplyOnDevices = [
        { name: 'device_ids', label: translate('resources.notifications.fields.device_ids'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "devices", optionText: "name", width: "480px", height: "250px" },
    ]
    const inputsDataSubjects = (filter) => ([
        { name: 'subject', label: translate('resources.notifications.fields.subject'), validate: validateRequired, type: "reference", reference: "notification_subjects", filter: filter, optionValue: "subject", optionText: (s) => (s && s.subject) ? translate(`resources.notifications.${s.subject}`) : "n" },
    ])

    const inputsDataApplyOnOrganizations = [
        { name: 'organization_ids', label: translate('resources.notifications.fields.organization_ids'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "organizations", optionText: "name", width: "480px", height: "200px" },
    ]

    const timeZonesChoices = [
        { id: 'Etc/GMT+12', name: '(UTC-12:00) International Date Line West' },
        { id: 'Pacific/Midway', name: '(UTC-11:00) Midway Island, Samoa' },
        { id: 'Pacific/Honolulu', name: '(UTC-10:00) Hawaii' },
        { id: 'America/Anchorage', name: '(UTC-09:00) Alaska' },
        { id: 'America/Los_Angeles', name: '(UTC-08:00) Pacific Time (US & Canada)' },
        { id: 'America/Denver', name: '(UTC-07:00) Mountain Time (US & Canada)' },
        { id: 'America/Chicago', name: '(UTC-06:00) Central Time (US & Canada)' },
        { id: 'America/Mexico_City', name: '(UTC-06:00) Mexico City' },
        { id: 'America/New_York', name: '(UTC-05:00) Eastern Time (US & Canada)' },
        { id: 'America/Bogota', name: '(UTC-05:00) Bogota, Lima, Quito' },
        { id: 'America/Caracas', name: '(UTC-04:30) Caracas' },
        { id: 'America/Halifax', name: '(UTC-04:00) Atlantic Time (Canada)' },
        { id: 'America/Santiago', name: '(UTC-04:00) Santiago' },
        { id: 'Atlantic/Bermuda', name: '(UTC-04:00) Bermuda' },
        { id: 'America/St_Johns', name: '(UTC-03:30) Newfoundland' },
        { id: 'America/Argentina/Buenos_Aires', name: '(UTC-03:00) Buenos Aires' },
        { id: 'America/Sao_Paulo', name: '(UTC-03:00) Brasilia' },
        { id: 'Atlantic/South_Georgia', name: '(UTC-02:00) South Georgia' },
        { id: 'Atlantic/Azores', name: '(UTC-01:00) Azores' },
        { id: 'Europe/London', name: '(UTC+00:00) London, Lisbon' },
        { id: 'Africa/Casablanca', name: '(UTC+00:00) Casablanca' },
        { id: 'Europe/Berlin', name: '(UTC+01:00) Central European Time Berlin' },
        { id: 'Europe/Madrid', name: '(UTC+01:00) Central European Time Madrid' },
        { id: 'Europe/Paris', name: '(UTC+01:00) Central European Time Paris' },
        { id: 'Africa/Lagos', name: '(UTC+01:00) Lagos' },
        { id: 'Europe/Athens', name: '(UTC+02:00) Athens, Bucharest' },
        { id: 'Africa/Johannesburg', name: '(UTC+02:00) Johannesburg' },
        { id: 'Europe/Moscow', name: '(UTC+03:00) Moscow, St. Petersburg' },
        { id: 'Asia/Riyadh', name: '(UTC+03:00) Riyadh' },
        { id: 'Asia/Tehran', name: '(UTC+03:30) Tehran' },
        { id: 'Asia/Dubai', name: '(UTC+04:00) Abu Dhabi, Dubai' },
        { id: 'Asia/Kabul', name: '(UTC+04:30) Kabul' },
        { id: 'Asia/Karachi', name: '(UTC+05:00) Islamabad, Karachi' },
        { id: 'Asia/Kolkata', name: '(UTC+05:30) New Delhi, Mumbai' },
        { id: 'Asia/Kathmandu', name: '(UTC+05:45) Kathmandu' },
        { id: 'Asia/Dhaka', name: '(UTC+06:00) Dhaka, Almaty' },
        { id: 'Asia/Yangon', name: '(UTC+06:30) Yangon (Rangoon)' },
        { id: 'Asia/Bangkok', name: '(UTC+07:00) Bangkok, Hanoi' },
        { id: 'Asia/Shanghai', name: '(UTC+08:00) Beijing, Singapore' },
        { id: 'Asia/Tokyo', name: '(UTC+09:00) Tokyo, Seoul' },
        { id: 'Australia/Sydney', name: '(UTC+10:00) Sydney, Melbourne' },
        { id: 'Pacific/Noumea', name: '(UTC+11:00) New Caledonia' },
        { id: 'Pacific/Fiji', name: '(UTC+12:00) Fiji, Kamchatka' },
        { id: 'Pacific/Auckland', name: '(UTC+12:00) Auckland, Wellington' }
    ];

    const inputsDataGeneral2 = [
        { name: 'active', label: translate('resources.notifications.fields.active'), validate: validateRequired, type: "boolean" },
        { name: 'language', label: translate('resources.notifications.fields.language'), validate: validateRequired, type: "select", choices: [{ id: 'fr', name: translate('resources.notifications.fields.french') }, { id: 'en', name: translate('resources.notifications.fields.english') }, { id: 'es', name: translate('resources.notifications.fields.spanish') }] },
        { name: 'timezone', label: translate('resources.notifications.fields.timezone'), validate: validateRequired, type: "select", choices: timeZonesChoices }
    ]

    const inputsDataDestinations = [
        { name: 'channel', label: translate('resources.notifications.fields.channel'), validate: validateRequired, type: "select", choices: [{ id: 'sms', name: 'SMS' }, { id: 'email', name: 'EMAIL' }] },
    ]

    const inputsGeofenceCrossedParametersMethod = [
        { name: 'geofence_input_method', label: translate('resources.notifications.fields.geofence_input_method'), validate: validateRequired, type: "select", choices: [{ id: 'with_map', name: translate('resources.notifications.fields.geofence_input_method_with_map') }, { id: 'without_map', name: translate('resources.notifications.fields.geofence_input_method_without_map') }] },
    ]

    const inputsGeofenceCrossedParametersWithMap = [
        { name: 'geofence_formatted_address', label: translate('resources.notifications.fields.geofence_formatted_address'), validate: validateRequired, type: "text", disabled: true, width: '300px' },
        { name: 'geofence_radius', label: translate('resources.notifications.fields.geofence_radius'), validate: validateRequired, type: "number", disabled: true },
        { name: 'geofence_longitude', label: translate('resources.notifications.fields.geofence_longitude'), validate: validateRequired, type: "number", disabled: true },
        { name: 'geofence_latitude', label: translate('resources.notifications.fields.geofence_latitude'), validate: validateRequired, type: "number", disabled: true },
        { name: 'geofence_on_entry', label: translate('resources.notifications.fields.geofence_on_entry'), validate: validateRequired, type: "boolean" },
        { name: 'geofence_on_exit', label: translate('resources.notifications.fields.geofence_on_exit'), validate: validateRequired, type: "boolean" }
    ]

    const inputsGeofenceCrossedParametersWithoutMap = [
        { name: 'geofence_radius', label: translate('resources.notifications.fields.geofence_radius'), validate: validateRequired, type: "number" },
        { name: 'geofence_longitude', label: translate('resources.notifications.fields.geofence_longitude'), validate: validateRequired, type: "number" },
        { name: 'geofence_latitude', label: translate('resources.notifications.fields.geofence_latitude'), validate: validateRequired, type: "number" },
        { name: 'geofence_on_entry', label: translate('resources.notifications.fields.geofence_on_entry'), validate: validateRequired, type: "boolean" },
        { name: 'geofence_on_exit', label: translate('resources.notifications.fields.geofence_on_exit'), validate: validateRequired, type: "boolean" }
    ]


    const inputHighGThresholdsReachedParameters = [
        { name: 'high_g_peak_threshold', label: translate('resources.notifications.fields.high_g_peak_threshold'), validate: validateRequired, type: "number" },
        { name: 'high_g_average_threshold', label: translate('resources.notifications.fields.high_g_average_threshold'), validate: validateRequired, type: "number" },
        { name: 'high_g_duration_threshold', label: translate('resources.notifications.fields.high_g_duration_threshold'), validate: validateRequired, type: "number" },
    ]


    const inputBatteryLowParametersParameters = [
        { name: 'battery_threshold_value', label: translate('resources.notifications.fields.battery_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'battery_threshold_unit', label: translate('resources.notifications.fields.battery_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'percent', name: 'PERCENT' }, { id: 'millivolt', name: 'MILLIVOLT' }] },
    ]

    const inputCo2ThresholdReachedParameters = [
        { name: 'co2_threshold_value', label: translate('resources.notifications.fields.co2_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'co2_threshold_unit', label: translate('resources.notifications.fields.co2_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'ppm', name: 'PPM' }] },
    ]

    const inputHumidityThresholdReachedParameters = [
        { name: 'humidity_threshold_value', label: translate('resources.notifications.fields.humidity_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'humidity_threshold_unit', label: translate('resources.notifications.fields.humidity_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'percent', name: 'PERCENT' }] },
    ]

    const inputTemperatureThresholdReachedParameters = [
        { name: 'temperature_threshold_value', label: translate('resources.notifications.fields.temperature_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'temperature_threshold_unit', label: translate('resources.notifications.fields.temperature_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'celsius', name: 'CELSIUS' }, { id: 'fahrenheit', name: 'FAHRENHEIT' }] },
    ]


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.notifications.create')}
            </Typography>
            <Create
                title={'resources.notifications.create'}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                }}
                redirect="list"
                transform={transform}
            >
                <SimpleForm toolbar={<NotificationEditToolBar />} >

                    {styledFormInputs(inputsDataGeneral)}

                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneDevice"}
                        Component={() => styledFormInputs(inputsDataApplyOnOneDevice)}
                    />

                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneDevice"}
                        Component={() => <NotificationSubjectsInputHelper setDeviceId={setDeviceId} />}
                    />


                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneDevice"}
                        Component={() => deviceId ? styledFormInputs(inputsDataSubjects({ device_id: deviceId })) : <div />}
                    />


                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"devices"}
                        Component={() => styledFormInputs(inputsDataApplyOnDevices)}
                    />
                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"devices"}
                        Component={() => styledFormInputs(inputsDataSubjects({}))}
                    />

                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"organizations"}
                        Component={() => styledFormInputs(inputsDataApplyOnOrganizations)}
                    />
                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"organizations"}
                        Component={() => styledFormInputs(inputsDataSubjects({}))}
                    />



                    {styledFormInputs(inputsDataGeneral2)}


                    {styledFormInputs(inputsDataDestinations)}



                    <ComponentBySourceEqual
                        source={"channel"}
                        equals={"email"}
                        Component={() =>
                            <ArrayInput source="destinations" validate={[required()]}>
                                <SimpleFormIterator>
                                    <TextInput variant="standard" margin="normal" source="email" validate={[required()]} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        }
                    />
                    <ComponentBySourceEqual
                        source={"channel"}
                        equals={"sms"}
                        Component={() =>
                            <ArrayInput
                                source="destinations"
                                validate={[required()]}
                            >
                                <SimpleFormIterator>
                                    <PhoneNumberField source="number" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        }
                    />



                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => styledFormInputs(inputsGeofenceCrossedParametersMethod)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentBySourceEqual
                                source={"geofence_input_method"}
                                equals={"with_map"}
                                Component={() => {
                                    const [openGeofenceInputDialog, setOpenGeofenceInputDialog] = useState(false);
                                    return ([
                                        <Typography color="text.main" sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            margin: "0px 0px 8px 0px"
                                        }}
                                        >
                                            {translate('resources.notifications.fields.geofence_input_draw_on_map')}
                                        </Typography>,
                                        <DrawGeofenceButton onClick={() => { setOpenGeofenceInputDialog(true) }} />,
                                        <GeofenceInput
                                            openDialog={openGeofenceInputDialog}
                                            setOpenDialog={setOpenGeofenceInputDialog} />
                                    ]
                                    )

                                }}
                            />
                        )}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentBySourceEqual
                                source={"geofence_input_method"}
                                equals={"with_map"}
                                Component={() => styledFormInputs(inputsGeofenceCrossedParametersWithMap)}
                            />
                        )}
                    />

                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentBySourceEqual
                                source={"geofence_input_method"}
                                equals={"without_map"}
                                Component={() => styledFormInputs(inputsGeofenceCrossedParametersWithoutMap)}
                            />
                        )}
                    />

                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"high_g_thresholds_reached"}
                        Component={() => styledFormInputs(inputHighGThresholdsReachedParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"battery_low"}
                        Component={() => styledFormInputs(inputBatteryLowParametersParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"co2_threshold_reached"}
                        Component={() => styledFormInputs(inputCo2ThresholdReachedParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"humidity_threshold_reached"}
                        Component={() => styledFormInputs(inputHumidityThresholdReachedParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"temperature_threshold_reached"}
                        Component={() => styledFormInputs(inputTemperatureThresholdReachedParameters)}
                    />


                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 15px 0px"
                    }}
                    >
                        {translate('resources.notifications.fields.calendar.calendar')}
                    </Typography>
                    <CalendarTimeSlotSelector />
                    <InfoAboutDestinationVerificationDialog openDialog={openInfoAboutDestinationVerificationDialog} setOpenDialog={setOpenInfoAboutDestinationVerificationDialog} />
                </SimpleForm>
            </Create>
        </>
    )
}

export default NotificationCreate
