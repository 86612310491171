import React from 'react'
import L from 'leaflet'
import './markerPulse'
import './markerPulse.css'
//react-google-maps
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css';
//ticatag
import TifizMarker from './marker'
import { getMarkerDefaultIcon } from './icons'







const getMarkerSelectIcon = (iconColor) => {

  if (iconColor) {
    return new L.Icon.Pulse({
      fillColor: iconColor,
      color: iconColor,
    })
  }

  return new L.Icon.Pulse({
    fillColor: 'blue',
    color: 'blue',
  })
}





const markerClusterer = (props) => {

  const { handleSelect, scrollYContainerRef, scrollXContainerRef, locations, idSelected, disableClusteringAtZoom } = props

  const renderMarker = (location, idSelected, index) => {

    if (location && location.id && location.lat && location.lng) {
      const icon = location.id === idSelected ? getMarkerSelectIcon(location.iconColor) : getMarkerDefaultIcon(location.iconName, location.iconColor);
      return (
        <TifizMarker
          icon={icon}
          iconColor={location.iconColor}
          key={index}
          index={index}
          handleSelect={handleSelect}
          scrollYContainerRef={scrollYContainerRef}
          scrollXContainerRef={scrollXContainerRef}
          location={location}
          selected={location.id === idSelected}

        />
      )
    } else {
      return <div key={index} />
    }
  }



  if (!locations || (locations && locations.length === 0)) {
    return null
  }


  return (
    <MarkerClusterGroup key={disableClusteringAtZoom * 200} disableClusteringAtZoom={disableClusteringAtZoom}>
      {locations.map((location, index) => renderMarker(location, idSelected, index))}
    </MarkerClusterGroup>

  )


}



export default markerClusterer;


