// in src/comments.js
import React from 'react';
import { Card, Box, CardContent, Grid, TableBody, useTheme, Tooltip, ButtonBase, Typography, Table } from '@mui/material'
import AvatarField from '../../../ui/field/AvatarField'
import { DateField, useTranslate, TextField, useListContext, usePermissions, useLocaleState } from 'react-admin';
import { CardDetailRow } from '../../../ui/Common';
import { Battery } from '../../device/list/Battery';
import { useNavigate } from 'react-router-dom';






const DeviceCardContent = ({ device }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const [locale, setLocale] = useLocaleState();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>
                    {device.mac_address ? (
                        <CardDetailRow label={translate('resources.devices.fields.mac_address')} ValueComponent={() => <TextField source="mac_address" record={device} />} />
                    ) : (device.serial_number && (
                        <CardDetailRow label={translate('resources.devices.fields.mac_address')} ValueComponent={() => <TextField source="serial_number" record={device} />} />
                    ))}
                    {device.last_battery && (
                        <CardDetailRow label={translate('resources.devices.fields.battery')} ValueComponent={() => <Battery record={device} />} />
                    )}
                    {device.last_seen && (
                        <CardDetailRow label={translate('resources.devices.fields.last_seen')} ValueComponent={() => <DateField
                            record={device}
                            source="last_seen"
                            type="date"
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />} />
                    )}

                </TableBody>
            </Table>
        </Box>
    );
}



const getAvatar = record => {
    const defaultAvatar = <AvatarField avatarUrl="https://ticatagsas.s3.amazonaws.com/57f3315c-e601-410a-9749-57fa11e123fc" size="45" />
    if (!record)
        return defaultAvatar

    if (record.image && record.image.src)
        return <AvatarField avatarUrl={record.image.src} size="45px" />

    if (record.product && record.product.image && record.product.image.src)
        return <AvatarField avatarUrl={record.product.image.src} size="45px" />

    return defaultAvatar
}




const DeviceCardHeader = ({ permissions, device }) => {

    return (

        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    {getAvatar(device)}
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{device ? `${device.name}` : ""}</Typography>
            </Box>

        </Box>

    );
}

const MobileGrid = ({ isSmall }) => {

    const { data, isLoading } = useListContext();
    const translate = useTranslate();
    const theme = useTheme();
    const navigate = useNavigate()
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    if (isLoading || !data || (data?.length <= 0)) return null;

    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((device, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: isSmall ? "0px 0px 8px 0px" : "0px 8px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.devices.browse_measure`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { navigate(`/measures/${device.id}/history`) }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <DeviceCardHeader device={device} permissions={permissions} />
                                    <DeviceCardContent sx={{
                                        width: "100%",
                                    }} device={device} />


                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );
}



export default MobileGrid;