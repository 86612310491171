import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { Button, Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';



const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chart-dots">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 3v18h18" />
            <path d="M9 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M19 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M14 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M10.16 10.62l2.34 2.88" />
            <path d="M15.088 13.328l2.837 -4.586" />
        </svg>

    )
}
const MeasuresButton = ({
    basePath = '',
    disabled,
    record = {},
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];
    return (

        <Tooltip title={disabled ? "" : translate(`resources.devices.browse_measure`)} disableInteractive >
            <Button
                component={Link}
                disabled={disabled}
                to={`/measures/${record.id}/history`}
                {...rest}
                sx={listActionsColumnIconCommonStyle({ isLastItem: false })}
            >
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Button>
        </Tooltip>
    );
}



export default MeasuresButton