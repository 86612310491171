
const AddressIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-flag-3">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 14h14l-4.5 -4.5l4.5 -4.5h-14v16" />
        </svg>

    )

}


export default AddressIcon