// in src/comments.js
import React from 'react';
import { ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, required, DateField, useLocaleState } from 'react-admin';
import { Table, useTheme, Grid, TableBody, Typography, CardContent, Card, Avatar, Tooltip, ButtonBase, Box, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { CardDetailRow } from '../../ui/Common';
import { NotificationLogIcon } from '../notificationLog';
import { AckChip } from './AckChip';
import NotificationLogAckButton, { NotificationLogAckContainedButton } from '../../ui/button/NotificationLogAckButton';




const NotificationLogCardContent = ({ notificationLog }) => {
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    let description = notificationLog.description
    if (locale && locale === 'fr' && notificationLog.meta && notificationLog.meta.descriptionFR) description = notificationLog.meta.descriptionFR
    if (locale && locale === 'en' && notificationLog.meta && notificationLog.meta.descriptionEN) description = notificationLog.meta.descriptionEN
    if (locale && locale === 'es' && notificationLog.meta && notificationLog.meta.descriptionES) description = notificationLog.meta.descriptionES


    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>

                    {notificationLog.timestamp && (
                        <CardDetailRow label={translate('resources.notification_logs.timestamp')} ValueComponent={() => <DateField
                            source="timestamp"
                            record={notificationLog}
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />} />
                    )}

                    {notificationLog.description && (
                        <CardDetailRow label={translate('resources.notification_logs.description')} ValueComponent={() => <Typography sx={{ fontWeight: 400 }}>{description.length > 400 ? `${description.substring(0, 400)}...` : description}</Typography>} />
                    )}

                    <TableRow
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0
                            }
                        }}
                    >
                        <TableCell colSpan={2} align="right" sx={{ padding: "10px 12px 10px 12px" }}>
                            <NotificationLogAckContainedButton
                                eventId={notificationLog.event_id}
                                canAck={notificationLog.acknowledged === false}
                                canNack={notificationLog.acknowledged === true}
                            /></TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </Box>
    );
}


const NotificationLogCardHeader = ({ permissions, notificationLog }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const subject = translate(`resources.notifications.${notificationLog.subject}`)

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>

                <Box >
                    <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{subject.length > 35 ? `${subject.substring(0, 35)}...` : subject}</Typography>
                </Box>
                <Box sx={{
                    minWidth: "24px",
                    minHeight: "24px",
                }}>
                    <AckChip record={notificationLog} />
                </Box>
            </Box>

        </Box>

    );
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((nlog, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: "0px 0px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            padding: "0px"
                        }}>

                            <NotificationLogCardHeader notificationLog={nlog} permissions={permissions} />
                            <NotificationLogCardContent sx={{
                                width: "100%",
                            }} notificationLog={nlog} />

                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )


}




export default MobileGrid