
import { Box, IconButton, useTheme } from "@mui/material"
import { useLocaleState, useNotify, useTranslate } from "react-admin";
import LoadingDialog from "../dialog/LoadingDialog";
import { useEffect, useState } from "react";
import jsPDF from 'jspdf';
import L from 'leaflet'
import html2canvas from 'html2canvas';
import svgToMiniDataURI from 'mini-svg-data-uri';
import StandardDialog from "../dialog/StandardDialog";
import { saveAs } from 'file-saver';
import { getDateTimeToFullLocalString, getNowDateTimeToFullLocalString } from "../../utils/date";



function splitStringIntoChunks(str, maxLength) {
    const chunks = [];
    for (let i = 0; i < str.length; i += maxLength) {
        chunks.push(str.substring(i, i + maxLength));
    }
    return chunks;
}

export const PdfBtn = ({ styles,
    pdfReportType,
    PdfIcon,
    deviceName,
    deviceSn,
    locations,
    page,
    total,
    perPage,
    filterValues
}) => {
    const theme = useTheme();
    const notify = useNotify();
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    const [isLoading, setIsLoading] = useState(false);
    const searchEndDate = filterValues.end_date
    const searchStartDate = filterValues.start_date
    const searchSize = filterValues.size ? filterValues.size : locations ? locations.length : null
    const resultsDates = locations.map(l => l.timestamp).map(date => new Date(date));
    const resultsStartDate = new Date(Math.min(...resultsDates));
    const resultsEndDate = new Date(Math.max(...resultsDates));
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(async () => {
        window.html2canvas = html2canvas;


    }, [isLoading])

    const generatePDF = async ({ map, setIsLoading }) => {


        try {
            // Generate Map Screenshot
            const input = document.getElementById("map-page-export-pdf");
            html2canvas(input, { useCORS: true, imageTimeout: 30000 }).then(
                (canvas) => {

                    const imgData = canvas.toDataURL('image/png');
                    //await saveImageToServer(imgData);

                    // Initialize jsPDF
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    const pageWidth = 210; // A4 width in mm
                    const pageHeight = 297; // A4 height in mm
                    const margin = 10;
                    let yOffset = margin;

                    // Helper to add new page if needed
                    const addPage = () => {
                        pdf.addPage();
                        yOffset = margin;
                    };

                    // Add table row
                    const drawTableRow = (row, y, colWidths, cellHeight, fillCh1, fillCh2, fillCh3, fillCh4) => {
                        let x = margin;
                        row.forEach((cell, i) => {
                            pdf.setFillColor(fillCh1, fillCh2, fillCh3, fillCh4);
                            pdf.rect(x, y, colWidths[i], cellHeight, 'FD');
                            pdf.setTextColor(0, 0, 0);
                            pdf.text(String(cell), x + 2, y + 5);
                            x += colWidths[i];
                        });
                    };

                    // Add Header Date
                    const dateNow = getNowDateTimeToFullLocalString({ locale, showTimezone: true });
                    pdf.setFontSize(8);
                    pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.created_on")}: ${dateNow} `, margin, yOffset);
                    yOffset += 10;

                    // Title
                    pdf.setFontSize(20);
                    pdf.text(translate("resources.exportPdf.locationsHistoryDocument.title"), pageWidth / 2, yOffset, { align: 'center' });
                    yOffset += 20;

                    // Header Device Title
                    pdf.setFontSize(10);
                    pdf.setFont(undefined, 'bold');
                    pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.beacon")}:`, margin, yOffset);
                    pdf.setFont(undefined, 'normal');
                    yOffset += 5;

                    // Header Device Info
                    pdf.setFontSize(8);
                    drawTableRow([translate("resources.exportPdf.locationsHistoryDocument.name"), translate("resources.exportPdf.locationsHistoryDocument.id")], yOffset, [50, 50], 7, 238, 238, 238);
                    yOffset += 7;
                    drawTableRow([deviceName, deviceSn], yOffset, [50, 50], 7, 255, 255, 255);
                    yOffset += 20;


                    // Header Search Title
                    pdf.setFontSize(10);
                    pdf.setFont(undefined, 'bold');
                    pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.search")}:`, margin, yOffset);
                    pdf.setFont(undefined, 'normal');
                    yOffset += 5;



                    // Header Search Info
                    pdf.setFontSize(8);
                    if (searchEndDate && searchStartDate) {
                        const formatter = new Intl.DateTimeFormat('en-GB', {
                            timeZone: timezone,
                            hour12: false,
                            dateStyle: 'short',
                            timeStyle: 'medium',
                        });
                        const startDateText = formatter.format(new Date(searchStartDate));
                        const endDateText = formatter.format(new Date(searchEndDate));
                        drawTableRow([translate("resources.exportPdf.locationsHistoryDocument.start_date") + ` (${timezone})`, translate("resources.exportPdf.locationsHistoryDocument.end_date") + ` (${timezone})`], yOffset, [50, 50], 7, 238, 238, 238);
                        yOffset += 7;
                        drawTableRow([startDateText, endDateText], yOffset, [50, 50], 7, 255, 255, 255);
                        yOffset += 20;

                        // waring limits locations.length
                        if (searchSize === locations.length) {
                            const noteText = translate("resources.exportPdf.locationsHistoryDocument.warning_limited_results_size", { part_number: page });
                            const noteTexts = splitStringIntoChunks(noteText, 128);
                            pdf.setFontSize(10);
                            pdf.setFont(undefined, 'bold');
                            pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.note")}:`, margin, yOffset);
                            pdf.setFontSize(8);
                            pdf.setFont(undefined, 'normal');
                            pdf.setTextColor(255, 0, 0);
                            noteTexts.forEach((t) => { pdf.text(`${t}`, margin + 22, yOffset); yOffset += 3; })
                            yOffset += 10;
                            pdf.setTextColor(0, 0, 0);
                        }


                    } else {
                        drawTableRow([translate("resources.exportPdf.locationsHistoryDocument.request")], yOffset, [80], 7, 238, 238, 238);
                        yOffset += 7;
                        switch (searchSize) {
                            case 5:
                                drawTableRow([translate("resources.locations.location5")], yOffset, [80], 7, 255, 255, 255);
                                break;
                            case 25:
                                drawTableRow([translate("resources.locations.location25")], yOffset, [80], 7, 255, 255, 255);
                                break;
                            case 50:
                                drawTableRow([translate("resources.locations.location50")], yOffset, [80], 7, 255, 255, 255);
                                break;
                            case 100:
                                drawTableRow([translate("resources.locations.location100")], yOffset, [80], 7, 255, 255, 255);
                                break;
                            default:
                                drawTableRow([`Last ${searchSize}`], yOffset, [80], 7, 255, 255, 255);
                        }
                        yOffset += 20;
                    }


                    // Header Results Title
                    pdf.setFontSize(10);
                    pdf.setFont(undefined, 'bold');
                    pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.results")}:`, margin, yOffset);
                    pdf.setFont(undefined, 'normal');
                    yOffset += 5;


                    pdf.setFontSize(8);
                    if (resultsEndDate && resultsStartDate) {
                        const formatter = new Intl.DateTimeFormat('en-GB', {
                            timeZone: timezone,
                            hour12: false,
                            dateStyle: 'short',
                            timeStyle: 'medium',
                        });
                        const partText = `${page}/${total != null ? Math.ceil(total / perPage) : undefined}`
                        const startDateText = formatter.format(new Date(resultsStartDate));
                        const endDateText = formatter.format(new Date(resultsEndDate));
                        drawTableRow([translate("resources.exportPdf.locationsHistoryDocument.part"), translate("resources.exportPdf.locationsHistoryDocument.number_of_results"), translate("resources.exportPdf.locationsHistoryDocument.results_start_date") + ` (${timezone})`, translate("resources.exportPdf.locationsHistoryDocument.results_end_date") + ` (${timezone})`], yOffset, [15, 20, 60, 60], 7, 238, 238, 238);
                        yOffset += 7;
                        drawTableRow([partText, locations.length, startDateText, endDateText], yOffset, [15, 20, 60, 60], 7, 255, 255, 255);
                        yOffset += 20;

                    }


                    // Map Title
                    pdf.setFontSize(10);
                    pdf.setFont(undefined, 'bold');
                    pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.itinerary_map")}:`, margin, yOffset);
                    pdf.setFont(undefined, 'normal');
                    yOffset += 5;


                    // Add Map Image
                    const imgWidth = 190;
                    const imgHeight = 126.6;
                    if (yOffset + imgHeight > pageHeight - margin) addPage();
                    pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
                    yOffset += imgHeight + 10;
                    addPage();

                    // Location Table Title
                    pdf.setFontSize(10);
                    pdf.setFont(undefined, 'bold');
                    pdf.text(`${translate("resources.exportPdf.locationsHistoryDocument.table.title")}:`, margin, yOffset);
                    pdf.setFont(undefined, 'normal');
                    yOffset += 5;

                    // Location Table
                    pdf.setFontSize(8);
                    pdf.setDrawColor(200);
                    pdf.setLineWidth(0.1);
                    const locationsTableHeaders = [
                        "",
                        translate("resources.exportPdf.locationsHistoryDocument.table.dateTime"),
                        translate("resources.exportPdf.locationsHistoryDocument.table.latitude"),
                        translate("resources.exportPdf.locationsHistoryDocument.table.longitude"),
                        translate("resources.exportPdf.locationsHistoryDocument.table.accuracy"),
                        translate("resources.exportPdf.locationsHistoryDocument.table.adress")
                    ];
                    const locationsColWidths = [10, 30, 22, 22, 15, 96];



                    drawTableRow(locationsTableHeaders, yOffset, locationsColWidths, 10, 238, 238, 238);
                    yOffset += 10;

                    locations.forEach(location => {
                        const row = [
                            location.number,
                            getDateTimeToFullLocalString({ timestamp: location.timestamp, locale }),
                            location.lat,
                            location.lng,
                            location.accuracy || '',
                            location.formattedAddress || '',
                        ];

                        if (yOffset + 10 > pageHeight - margin) addPage();
                        drawTableRow(row, yOffset, locationsColWidths, 10, 255, 255, 255);
                        yOffset += 10;
                    });

                    // Save PDF
                    pdf.save(`locations_report_${deviceSn}_${dateNow.replace(/[ .]/g, '_')}.pdf`);

                    destroyMap(map);
                    setIsLoading(false);
                    notify(translate('ra.notification.created'), 'info');
                }
            ).catch((error) => {
                destroyMap(map);
                setIsLoading(false);
                console.log("error ==>", error)
                notify(translate("resources.exportPdf.errors.errorCreation"), 'error');
            })

        } catch (error) {
            destroyMap(map);
            setIsLoading(false);
            console.log("error ==>", error)
            notify(translate("resources.exportPdf.errors.errorCreation"), 'error');
        }
    };





    const createMap = (map) => {
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(map);
        const customIcon = L.divIcon({
            className: 'custom-marker',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="blue" class="icon icon-tabler icons-tabler-filled icon-tabler-map-pin">
                        <path stroke="none" d="M0 0h12v12H0z" fill="none" stroke-width="0.5" />
                        <path d="M9.182 2.318a4.5 4.5 0 0 1 0.102 6.26l-0.102 0.105 -2.122 2.121a1.5 1.5 0 0 1 -2.049 0.068l-0.072 -0.068 -2.122 -2.122A4.5 4.5 0 0 1 9.182 2.318M6 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0 -3" />
                    </svg>`,
            iconSize: [12, 12],
            iconAnchor: [6, 12]
        });
        const markers = [];
        locations.forEach((loc, index) => {
            const marker = L.marker([loc.lat, loc.lng], { icon: customIcon })
                .addTo(map);
            markers.push(marker.getLatLng());
        });

        if (markers.length > 1) {
            L.polyline(markers, {
                color: 'blue',
                weight: 3,
                opacity: 0.7
            }).addTo(map);
            const latLngs = markers.map(marker => [marker.lat, marker.lng]);
            map.fitBounds(latLngs, {
                padding: [50, 50],
                maxZoom: 17,
                animate: true
            });
        } else {
            map.setView(markers[0], 10);
        }
    }



    const destroyMap = (map) => {
        if (map) {
            map.remove();
        }
    }

    if (!locations) return null
    return (

        <IconButton
            sx={styles}
            onClick={(e) => {
                e.preventDefault();
                setIsLoading(true);
                setTimeout(() => {
                    const map = L.map("map-page-export-pdf", {
                        zoomControl: false, // Hide zoom buttons
                        preferCanvas: true
                    }).setView([
                        locations[0].lat,
                        locations[0].lng
                    ], 10);
                    createMap(map);
                    setTimeout(() => {
                        generatePDF({ map, setIsLoading });
                    }, 2000);

                }, 1000);


            }}
        >

            <PdfIcon color={theme.palette.text.secondary} />
            <LoadingDialog isLoading={isLoading} content={translate("resources.exportPdf.creationInProgress")} />
            <StandardDialog
                width="900px"
                height="600px"
                open={isLoading}
                fullWidth={false}
                BodyComponent={() => <div id='map-page-export-pdf' style={{ height: "600px", width: "900px", borderRadius: "12px" }}></div>}
            />
        </IconButton>

    )
}





/*


<!DOCTYPE html>
<html lang="en">
<head>
   <meta charset="UTF-8">
   <title>Location Tracking Map</title>
   <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
   <style>
       #map { height: 600px; width: 900px; border-radius: 12px; }
       body { margin: 0; padding: 0; }
   </style>
</head>
<body>
   <div id="map"></div>
   <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>
   <script>
       const locations = [];
       window.onload = function() { initializeMap(locations); };
       function initializeMap(locations) {
           const map = L.map('map', {
               zoomControl: false // Hide zoom buttons
           }).setView([
               locations[0].location[0],
               locations[0].location[1]
           ], 10);
           L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
               attribution: '© OpenStreetMap contributors'
           }).addTo(map);
           const customIcon = L.divIcon({
               className: 'custom-marker',
               html: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="blue" class="icon icon-tabler icons-tabler-filled icon-tabler-map-pin">
                         <path stroke="none" d="M0 0h12v12H0z" fill="none" stroke-width="0.5"/>
                         <path d="M9.182 2.318a4.5 4.5 0 0 1 0.102 6.26l-0.102 0.105 -2.122 2.121a1.5 1.5 0 0 1 -2.049 0.068l-0.072 -0.068 -2.122 -2.122A4.5 4.5 0 0 1 9.182 2.318M6 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0 -3" />
                     </svg>`,
               iconSize: [12, 12],
               iconAnchor: [6, 12]
           });
           const markers = [];
           locations.forEach((loc, index) => {
               const marker = L.marker([loc.location[0], loc.location[1]], { icon: customIcon })
                   .addTo(map)
                   .bindPopup(`Stop ${index + 1}<br>Lat: ${loc.location[0]}<br>Long: ${loc.location[1]}<br>Time: ${loc.timestamp}<br>Accuracy: ${loc.accuracy}`);
               markers.push(marker.getLatLng());
           });
           if (markers.length > 1) {
               L.polyline(markers, {
                   color: 'blue',
                   weight: 3,
                   opacity: 0.7
               }).addTo(map);
               const latLngs = markers.map(marker => [marker.lat, marker.lng]);
               map.fitBounds(latLngs, {
                   padding: [50, 50],
                   maxZoom: 17,
                   animate: true
               });
           } else {
               map.setView(markers[0], 10);
           }
       }
   </script>
</body>
</html>                    
*/