import React, { useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import {
    ArrayInput,
    Edit,
    FormDataConsumer,
    ReferenceInput,
    required,
    SelectInput,
    SaveButton,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
    Toolbar,
    TextField,
    AutocompleteInput,
    useUpdate,
    useRecordContext,
    DeleteButton,
    Button
} from 'react-admin'
import Autocomplete from '@mui/material/Autocomplete';
import PhoneInput from 'react-phone-number-input'
import withStyles from '@mui/styles/withStyles';
import { containerHeight } from '../../../helper/resourceHelper'
import { Box, SvgIcon, Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import CalendarTimeSlotSelector from '../../../ui/inputs/CalendarTimeSlotSelector';
import GeofenceInput from '../GeofenceInput';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';
import { ButtonBase } from '@material-ui/core';
import { height, width } from '@mui/system';






const validateRequired = [required('Champs obligatoire')]

const styles = theme => ({
    width100: {
        width: 320,
        marginTop: 16,
    },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        marginTop: 20,
    }
})

const PhoneNumberInput = ({ value, onChange }) => {
    const translate = useTranslate();
    return (
        <PhoneInput
            country="FR"
            placeholder={translate('resources.notifications.enter_phone_number')}
            value={value}
            onChange={onChange}
            sx={{
                width: '320px',
                marginTop: '16px'
            }}
        />
    )
}
const PhoneNumberField = ({ source }) => {

    const input = useController({ name: source, defaultValue: '' });
    return <PhoneNumberInput {...input.field} />
}

const DrawGeofenceButton = ({ onClick }) => {
    const theme = useTheme();
    const iconUrl = `
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-x">
  <path stroke="none" d="M0 0h48v48H0z" fill="none" stroke-width="2"/>
  <path d="M17.12 7.38a18 18 0 0 0 -5.84 3.9"/>
  <path d="M7.38 17.12A18 18 0 0 0 6 24"/>
  <path d="M7.38 30.88a18 18 0 0 0 3.9 5.84"/>
  <path d="M17.12 40.62A18 18 0 0 0 24 42"/>
  <path d="M30.88 40.62a18 18 0 0 0 5.84 -3.9"/>
  <path d="M40.62 30.88A18 18 0 0 0 42 24"/>
  <path d="M40.62 17.12a18 18 0 0 0 -3.9 -5.84"/>
  <path d="M30.88 7.38A18 18 0 0 0 24 6"/>
  <path d="m28 28 -8 -8"/>
  <path d="m20 28 8 -8"/>
</svg>
    `
    return (
        <Button
            sx={{
                margin: "0px",
                padding: "5px",
                width: "60px",
                height: "60px",

            }}
            onClick={onClick}>
            <Box
                sx={{
                    margin: "0px",
                    padding: "0px",
                    borderRadius: "10px",
                    width: "50px",
                    height: "50px",
                    borderColor: `${theme.palette.text.primary} !important`,
                    border: 1,
                    borderWidth: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"

                }}
            >
                <SvgIcon sx={{ color: `${theme.palette.text.primary} !important` }} viewBox={"0 0 48 48"}>
                    <g dangerouslySetInnerHTML={{ __html: iconUrl }} />
                </SvgIcon>

            </Box>

        </Button>
    )
}


const NotificationEditToolBar = props => (
    <Toolbar {...props} >
        <SaveButton />
        <DeleteButton />
    </Toolbar>
);

const ComponentBySourceEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element === equals) {
        return (
            <Component />
        )
    }
    return null;
}

const ComponentIfSourceUndefinned = ({ source, Component }) => {
    const element = useWatch({ name: source });
    if (!element) {
        return (
            <Component />
        )
    }

    return null;
}


const NotificationEdit = () => {

    const translate = useTranslate();
    const theme = useTheme();

    const transform = (data) => {
        if (data && data.device_id) {
            data.device_ids = [data.device_id]
            delete data.device_id;
        }

        return data

    };


    const timeZonesChoices = [
        { id: 'Etc/GMT+12', name: '(UTC-12:00) International Date Line West' },
        { id: 'Pacific/Midway', name: '(UTC-11:00) Midway Island, Samoa' },
        { id: 'Pacific/Honolulu', name: '(UTC-10:00) Hawaii' },
        { id: 'America/Anchorage', name: '(UTC-09:00) Alaska' },
        { id: 'America/Los_Angeles', name: '(UTC-08:00) Pacific Time (US & Canada)' },
        { id: 'America/Denver', name: '(UTC-07:00) Mountain Time (US & Canada)' },
        { id: 'America/Chicago', name: '(UTC-06:00) Central Time (US & Canada)' },
        { id: 'America/Mexico_City', name: '(UTC-06:00) Mexico City' },
        { id: 'America/New_York', name: '(UTC-05:00) Eastern Time (US & Canada)' },
        { id: 'America/Bogota', name: '(UTC-05:00) Bogota, Lima, Quito' },
        { id: 'America/Caracas', name: '(UTC-04:30) Caracas' },
        { id: 'America/Halifax', name: '(UTC-04:00) Atlantic Time (Canada)' },
        { id: 'America/Santiago', name: '(UTC-04:00) Santiago' },
        { id: 'Atlantic/Bermuda', name: '(UTC-04:00) Bermuda' },
        { id: 'America/St_Johns', name: '(UTC-03:30) Newfoundland' },
        { id: 'America/Argentina/Buenos_Aires', name: '(UTC-03:00) Buenos Aires' },
        { id: 'America/Sao_Paulo', name: '(UTC-03:00) Brasilia' },
        { id: 'Atlantic/South_Georgia', name: '(UTC-02:00) South Georgia' },
        { id: 'Atlantic/Azores', name: '(UTC-01:00) Azores' },
        { id: 'Europe/London', name: '(UTC+00:00) London, Lisbon' },
        { id: 'Africa/Casablanca', name: '(UTC+00:00) Casablanca' },
        { id: 'Europe/Berlin', name: '(UTC+01:00) Central European Time Berlin' },
        { id: 'Europe/Madrid', name: '(UTC+01:00) Central European Time Madrid' },
        { id: 'Europe/Paris', name: '(UTC+01:00) Central European Time Paris' },
        { id: 'Africa/Lagos', name: '(UTC+01:00) Lagos' },
        { id: 'Europe/Athens', name: '(UTC+02:00) Athens, Bucharest' },
        { id: 'Africa/Johannesburg', name: '(UTC+02:00) Johannesburg' },
        { id: 'Europe/Moscow', name: '(UTC+03:00) Moscow, St. Petersburg' },
        { id: 'Asia/Riyadh', name: '(UTC+03:00) Riyadh' },
        { id: 'Asia/Tehran', name: '(UTC+03:30) Tehran' },
        { id: 'Asia/Dubai', name: '(UTC+04:00) Abu Dhabi, Dubai' },
        { id: 'Asia/Kabul', name: '(UTC+04:30) Kabul' },
        { id: 'Asia/Karachi', name: '(UTC+05:00) Islamabad, Karachi' },
        { id: 'Asia/Kolkata', name: '(UTC+05:30) New Delhi, Mumbai' },
        { id: 'Asia/Kathmandu', name: '(UTC+05:45) Kathmandu' },
        { id: 'Asia/Dhaka', name: '(UTC+06:00) Dhaka, Almaty' },
        { id: 'Asia/Yangon', name: '(UTC+06:30) Yangon (Rangoon)' },
        { id: 'Asia/Bangkok', name: '(UTC+07:00) Bangkok, Hanoi' },
        { id: 'Asia/Shanghai', name: '(UTC+08:00) Beijing, Singapore' },
        { id: 'Asia/Tokyo', name: '(UTC+09:00) Tokyo, Seoul' },
        { id: 'Australia/Sydney', name: '(UTC+10:00) Sydney, Melbourne' },
        { id: 'Pacific/Noumea', name: '(UTC+11:00) New Caledonia' },
        { id: 'Pacific/Fiji', name: '(UTC+12:00) Fiji, Kamchatka' },
        { id: 'Pacific/Auckland', name: '(UTC+12:00) Auckland, Wellington' }
    ];

    const inputsDataGeneral2 = [
        { name: 'active', label: translate('resources.notifications.fields.active'), validate: validateRequired, type: "boolean" },
        { name: 'language', label: translate('resources.notifications.fields.language'), validate: validateRequired, type: "select", choices: [{ id: 'fr', name: 'FR' }, { id: 'en', name: 'EN' }, { id: 'es', name: 'ES' },] },
        { name: 'timezone', label: translate('resources.notifications.fields.timezone'), validate: validateRequired, type: "select", choices: timeZonesChoices },
        { name: 'channel', label: translate('resources.notifications.fields.channel'), validate: validateRequired, type: "select", choices: [{ id: 'sms', name: 'SMS' }, { id: 'email', name: 'EMAIL' }], disabled: true },
    ]





    const inputsGeofenceCrossedParametersMethod = [
        { name: 'geofence_input_method', label: translate('resources.notifications.fields.geofence_input_method'), validate: validateRequired, type: "select", choices: [{ id: 'with_map', name: translate('resources.notifications.fields.geofence_input_method_with_map') }, { id: 'without_map', name: translate('resources.notifications.fields.geofence_input_method_without_map') }], defaultValue: "without_map", width: '300px' },
    ]

    const inputsGeofenceCrossedParametersWithMap = [
        { name: 'geofence_formatted_address', label: translate('resources.notifications.fields.geofence_formatted_address'), validate: validateRequired, type: "text", disabled: true, width: '300px' },
        { name: 'geofence_radius', label: translate('resources.notifications.fields.geofence_radius'), validate: validateRequired, type: "number", disabled: true },
        { name: 'geofence_longitude', label: translate('resources.notifications.fields.geofence_longitude'), validate: validateRequired, type: "number", disabled: true },
        { name: 'geofence_latitude', label: translate('resources.notifications.fields.geofence_latitude'), validate: validateRequired, type: "number", disabled: true },
        { name: 'geofence_on_entry', label: translate('resources.notifications.fields.geofence_on_entry'), validate: validateRequired, type: "boolean" },
        { name: 'geofence_on_exit', label: translate('resources.notifications.fields.geofence_on_exit'), validate: validateRequired, type: "boolean" }
    ]

    const inputsGeofenceCrossedParametersWithoutMap = [
        { name: 'geofence_radius', label: translate('resources.notifications.fields.geofence_radius'), validate: validateRequired, type: "number" },
        { name: 'geofence_longitude', label: translate('resources.notifications.fields.geofence_longitude'), validate: validateRequired, type: "number" },
        { name: 'geofence_latitude', label: translate('resources.notifications.fields.geofence_latitude'), validate: validateRequired, type: "number" },
        { name: 'geofence_on_entry', label: translate('resources.notifications.fields.geofence_on_entry'), validate: validateRequired, type: "boolean" },
        { name: 'geofence_on_exit', label: translate('resources.notifications.fields.geofence_on_exit'), validate: validateRequired, type: "boolean" }
    ]


    const inputHighGThresholdsReachedParameters = [
        { name: 'high_g_peak_threshold', label: translate('resources.notifications.fields.high_g_peak_threshold'), validate: validateRequired, type: "number" },
        { name: 'high_g_average_threshold', label: translate('resources.notifications.fields.high_g_average_threshold'), validate: validateRequired, type: "number" },
        { name: 'high_g_duration_threshold', label: translate('resources.notifications.fields.high_g_duration_threshold'), validate: validateRequired, type: "number" },
    ]


    const inputBatteryLowParametersParameters = [
        { name: 'battery_threshold_value', label: translate('resources.notifications.fields.battery_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'battery_threshold_unit', label: translate('resources.notifications.fields.battery_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'percent', name: 'PERCENT' }, { id: 'millivolt', name: 'MILLIVOLT' }] },
    ]

    const inputCo2ThresholdReachedParameters = [
        { name: 'co2_threshold_value', label: translate('resources.notifications.fields.co2_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'co2_threshold_unit', label: translate('resources.notifications.fields.co2_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'ppm', name: 'PPM' }] },
    ]

    const inputHumidityThresholdReachedParameters = [
        { name: 'humidity_threshold_value', label: translate('resources.notifications.fields.humidity_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'humidity_threshold_unit', label: translate('resources.notifications.fields.humidity_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'percent', name: 'PERCENT' }] },
    ]

    const inputTemperatureThresholdReachedParameters = [
        { name: 'temperature_threshold_value', label: translate('resources.notifications.fields.temperature_threshold_value'), validate: validateRequired, type: "number" },
        { name: 'temperature_threshold_unit', label: translate('resources.notifications.fields.temperature_threshold_unit'), validate: validateRequired, type: "select", choices: [{ id: 'celsius', name: 'CELSIUS' }, { id: 'fahrenheit', name: 'FAHRENHEIT' }] },
    ]


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.notifications.edit')}
            </Typography>
            <Edit
                title={'resources.notifications.edit'}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                }}
                redirect="list"
                transform={transform}
            >
                <SimpleForm toolbar={<NotificationEditToolBar />}>

                    {styledFormInputs(
                        [
                            { name: 'name', label: translate('resources.notifications.fields.name'), validate: validateRequired, type: "text" },
                            { name: 'device_ids', label: translate('resources.notifications.fields.device_ids'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "devices", optionText: "name" },
                            { name: 'organization_ids', label: translate('resources.notifications.fields.organization_ids'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "organizations", optionText: "name" },
                            { name: 'subject', label: translate('resources.notifications.fields.subject'), validate: validateRequired, type: "reference", reference: "notification_subjects", optionValue: "subject", optionText: (s) => (s && s.subject) ? translate(`resources.notifications.${s.subject}`) : "n", disabled: true },
                        ]
                    )}




                    {styledFormInputs(inputsDataGeneral2)}


                    <ComponentBySourceEqual
                        source={"channel"}
                        equals={"email"}
                        Component={() =>
                            <ArrayInput source="destinations" validate={[required()]}>
                                <SimpleFormIterator>
                                    <TextInput variant="standard" margin="normal" source="email" validate={[required()]} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        }
                    />
                    <ComponentBySourceEqual
                        source={"channel"}
                        equals={"sms"}
                        Component={() =>
                            <ArrayInput
                                source="destinations"
                                validate={[required()]}
                            >
                                <SimpleFormIterator>
                                    <PhoneNumberField source="number" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        }
                    />



                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => styledFormInputs(inputsGeofenceCrossedParametersMethod)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentBySourceEqual
                                source={"geofence_input_method"}
                                equals={"with_map"}
                                Component={() => {
                                    const [openGeofenceInputDialog, setOpenGeofenceInputDialog] = useState(false);
                                    const record = useRecordContext()
                                    return ([
                                        <Typography color="text.main" sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            margin: "0px 0px 8px 0px"
                                        }}
                                        >
                                            {translate('resources.notifications.fields.geofence_input_draw_on_map')}
                                        </Typography>,
                                        <DrawGeofenceButton onClick={() => { setOpenGeofenceInputDialog(true) }} />,
                                        <GeofenceInput
                                            record={record}
                                            openDialog={openGeofenceInputDialog}
                                            setOpenDialog={setOpenGeofenceInputDialog} />
                                    ]
                                    )

                                }}
                            />
                        )}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentBySourceEqual
                                source={"geofence_input_method"}
                                equals={"with_map"}
                                Component={() => styledFormInputs(inputsGeofenceCrossedParametersWithMap)}
                            />
                        )}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentBySourceEqual
                                source={"geofence_input_method"}
                                equals={"without_map"}
                                Component={() => styledFormInputs(inputsGeofenceCrossedParametersWithoutMap)}
                            />
                        )}
                    />

                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"geofence_crossed"}
                        Component={() => (
                            <ComponentIfSourceUndefinned
                                source={"geofence_input_method"}
                                Component={() => styledFormInputs(inputsGeofenceCrossedParametersWithoutMap)}
                            />
                        )}
                    />

                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"high_g_thresholds_reached"}
                        Component={() => styledFormInputs(inputHighGThresholdsReachedParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"battery_low"}
                        Component={() => styledFormInputs(inputBatteryLowParametersParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"co2_threshold_reached"}
                        Component={() => styledFormInputs(inputCo2ThresholdReachedParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"humidity_threshold_reached"}
                        Component={() => styledFormInputs(inputHumidityThresholdReachedParameters)}
                    />
                    <ComponentBySourceEqual
                        source={"subject"}
                        equals={"temperature_threshold_reached"}
                        Component={() => styledFormInputs(inputTemperatureThresholdReachedParameters)}
                    />


                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 15px 0px"
                    }}
                    >
                        {translate('resources.notifications.fields.calendar.calendar')}
                    </Typography>
                    <CalendarTimeSlotSelector />

                </SimpleForm>
            </Edit>
        </>
    )
}

export default NotificationEdit