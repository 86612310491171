
import React from 'react'
import PropTypes from 'prop-types'
import * as Leaflet from 'leaflet'
import { Marker, Circle } from 'react-leaflet'



const styles = theme => ({
    //
})



const TifizMarker = React.memo((props) => {

    const { location, selected, handleSelect, scrollYContainerRef, scrollXContainerRef, icon, iconColor } = props


    if (selected && location.accuracy && location.accuracy > 50) {
        return (
            <Circle
                center={[location.lat, location.lng]}
                radius={location.accuracy}
                color={iconColor ? iconColor : "blue"}
                fillOpacity={0.4}
            >

            </Circle>
        )
    }

    return (
        <Marker
            icon={icon}
            position={[location.lat, location.lng]}
            eventHandlers={{
                click: (e) => {
                    const elem = document.getElementById(`menu-custom-list-item-${location.id}`);
                    if (scrollYContainerRef && scrollYContainerRef.current && elem && elem.offsetTop) {
                        scrollYContainerRef.current.scrollTop = elem.offsetTop
                    }

                    if (scrollXContainerRef && scrollXContainerRef.current && elem && elem.offsetTop) {
                        // Center the element horizontally
                        const scrollPosition =
                            elem.offsetLeft - scrollXContainerRef.current.offsetWidth / 2 + elem.offsetWidth / 2;

                        scrollXContainerRef.current.scrollLeft = scrollPosition;
                    }
                    handleSelect(location)
                },
            }}

        >

        </Marker >

    )
})



export default TifizMarker

