// in src/comments.js
import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, required, NumberField, DateField, FunctionField, useLocaleState } from 'react-admin';
import { Table, useTheme, Grid, TableBody, Typography, CardContent, Card, Avatar, Tooltip, ButtonBase, Box } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { InvoiceIcon } from '..';
import { useNavigate } from 'react-router-dom';
import InvoiceDownloadButton from '../../../ui/button/InvoiceDownloadButton';




const InvoiceCardContent = ({ invoice }) => {
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>



                    {invoice.customer_id && (
                        <CardDetailRow label={translate('resources.invoices.customer')} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="customer_id"
                            reference="users"
                            record={invoice}
                        >
                            <FunctionField
                                render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                sortable={false}
                            />
                        </ReferenceField>} />
                    )}
                    {invoice.creation_date && (
                        <CardDetailRow label={translate('resources.invoices.date')} ValueComponent={() => <DateField
                            source="creation_date"
                            record={invoice}
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />} />
                    )}

                    {invoice.total_amount && (
                        <CardDetailRow label={translate('resources.invoices.price_tax_included')} ValueComponent={() => <NumberField source="total_amount" record={invoice} />} />
                    )}
                    {invoice.currency && (
                        <CardDetailRow label={translate('resources.invoices.currency')} ValueComponent={() => <TextField source="currency" record={invoice} />} />
                    )}
                    {invoice.product_label && (
                        <CardDetailRow label={translate('resources.invoices.product_label')} ValueComponent={() => <TextField source="product_label" record={invoice} />} />
                    )}
                    {invoice.product_description1 && (
                        <CardDetailRow label={translate('resources.invoices.product_description1')} ValueComponent={() => <TextField source="product_description1" record={invoice} />} />
                    )}
                    {invoice.product_description2 && (
                        <CardDetailRow label={translate('resources.invoices.product_description2')} ValueComponent={() => <TextField source="product_description2" record={invoice} />} />
                    )}
                    <CardDetailRow label={translate('resources.invoices.download', { smart_count: 1 })} ValueComponent={() => <Box sx={{ display: "flex", justifyContent: 'flex-end', }}><InvoiceDownloadButton disabled={false} recordP={invoice} /></Box>} />

                </TableBody>
            </Table>
        </Box>
    );
}


const InvoiceCardHeader = ({ permissions, invoice }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    <Avatar
                        size={"45px"}
                        style={{ width: "45px", height: "45px" }}
                    >
                        <InvoiceIcon />
                    </Avatar>
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{invoice ? `${invoice.invoice_number}` : ""}</Typography>
            </Box>
        </Box>

    );
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((invoice, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: "0px 0px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>

                            <InvoiceCardHeader invoice={invoice} permissions={permissions} />
                            <InvoiceCardContent sx={{
                                width: "100%",
                            }} invoice={invoice} />

                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )


}




export default MobileGrid