import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { useTranslate } from 'react-admin'
import { IconBell as NotificationIcon } from '@tabler/icons-react';
import DashboardCard, { InfoTable2Col } from "../../ui/card/DashboardCard";
import { useEffect, useState } from "react";
import { USER_ID } from "../configuration/actions";
import { getUserRemaingSmsCredit } from "../../rest/restClient";
import { useNavigate } from "react-router-dom";





const Icon = ({ color }) => {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_569007)">
            <path d="M16.667 3.3335H3.33366C2.40866 3.3335 1.67533 4.07516 1.67533 5.00016L1.66699 15.0002C1.66699 15.9252 2.40866 16.6668 3.33366 16.6668H16.667C17.592 16.6668 18.3337 15.9252 18.3337 15.0002V5.00016C18.3337 4.07516 17.592 3.3335 16.667 3.3335ZM16.667 15.0002H3.33366V10.0002H16.667V15.0002ZM16.667 6.66683H3.33366V5.00016H16.667V6.66683Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_7_569007">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

    )
}




const SmsCreditSummaryBodyComponent = ({ nbrOfRemainingCredit }) => {

    const translate = useTranslate();

    let rows = []

    if (nbrOfRemainingCredit) {
        rows.push({ label: translate("resources.dashboard.sms_credit_summary.nbr_of_remaining_credit"), info: nbrOfRemainingCredit })
    }

    return (
        <InfoTable2Col rows={rows} />
    )


}


const SmsCreditSummary = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const [nbrOfRemainingCredit, setNbrOfRemainingCredit] = useState(null);
    const navigate = useNavigate()


    useEffect(() => {
        const userId = localStorage.getItem(USER_ID);
        getUserRemaingSmsCredit(userId).then((res) => {
            if (res && res.status === 200) {
                setNbrOfRemainingCredit(res.body)
            }
        })
    }, [])

    if (nbrOfRemainingCredit === null) {
        return null
    }
    return (

        <Grid
            item
            sm={12}
            md={6}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >
            <DashboardCard
                HeaderIcon={() => <NotificationIcon color={theme.palette.primary.dark} />}
                title={translate("resources.dashboard.titles.sms_credit_summary")}
                BodyComponent={() => <SmsCreditSummaryBodyComponent nbrOfRemainingCredit={nbrOfRemainingCredit} />}
                onClick={() => navigate("/notifications")}
            />
        </Grid>
    )
}

export default SmsCreditSummary;