import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography, TableBody, TableCell, Table, IconButton } from "@mui/material"
import { TextField, useTranslate } from 'react-admin'
import { useNavigate } from "react-router-dom";
import { CardDetailRow, CardDetailRowWithOnClick } from "../Common";
import { IconX } from "@tabler/icons-react";
import { useState } from "react";
import StandardDialog from "../dialog/StandardDialog";




const DetailsIcon = ({ color }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path d="M12 9h.01" />
            <path d="M11 12h1v4h1" />
        </svg>



    )
}


const DetailsBtn = ({ onClick, color }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >

            <DetailsIcon color={color} />
        </IconButton>

    )
}


const CustomCardHeader = ({ Icon, title, NoticeComponent, onClose }) => {
    const theme = useTheme();
    const [openNotice, setOpenNotice] = useState(false)
    return (
        <Box sx={{
            marginX: "12px",
            marginTop: "12px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"

        }}>
            <Box
                sx={{
                    marginRight: "24px",

                }}
            >
                <Avatar
                    size={"45px"}

                    style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                >
                    <Icon />
                </Avatar>
            </Box>

            <Box
                sx={{
                    marginRight: "24px",

                }}
            >
                <Typography
                    color="text.main"
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontWeight: 450,
                        lineHeight: "29.3px",
                        textAlign: 'start',
                        margin: "5px 5px 10px 5px"
                    }}
                >
                    {title}
                </Typography>

            </Box>

            {NoticeComponent &&
                <Box flex={1} />
            }

            {NoticeComponent &&

                <Box sx={{ margin: "0px 0px 0px 0px" }}>
                    <DetailsBtn color={theme.palette.primary.main} onClick={() => setOpenNotice(true)} />
                    <StandardDialog
                        fullWidth={false}
                        open={openNotice}
                        contentPadding={"5px 15px 5px 15px"}
                        onClose={() => setOpenNotice(false)}
                        BodyComponent={() => {

                            return (
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", margin: "0px 0px 20px 0px" }}>
                                        <DetailsIcon color={theme.palette.primary.main} />
                                        <Box sx={{ flex: 1 }} />
                                        <IconX onClick={() => setOpenNotice(false)} />
                                    </Box>
                                    <NoticeComponent />

                                </Box>
                            )

                        }}
                    />
                </Box>
            }


            {
                onClose &&
                <Box flex={1} />
            }
            {
                onClose &&
                <Box
                >
                    <IconX onClick={() => onClose()} />

                </Box>
            }





        </Box>

    );
}




export const InfoLine = ({ isSmall, label, info }) => {
    return (

        <Grid
            item
            xs={12}
            md={6}
            xl={4}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >

            <Grid container spacing={0}>

                <Grid
                    item
                    sm={12}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >
                    <Box>
                        {label}
                    </Box>

                </Grid>

                <Grid
                    item
                    sm={12}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >

                    <Typography
                        color="text.main"
                        sx={{
                            fontFamily: "Roboto",
                            fontSize: "20px",
                            fontWeight: 450,
                            lineHeight: "29.3px",
                            textAlign: "center",
                            margin: "5px 5px 10px 5px"
                        }}
                    >
                        {info}
                    </Typography>

                </Grid>
            </Grid>

        </Grid>
    )
}






export const InfoTable2Col = ({ rows, lastCaption }) => {
    const theme = useTheme();
    return (

        <Table sx={{
            width: "100%",
        }}>
            <TableBody sx={{
                width: "100% !important",
                "& .MuiTableRow-root": {
                    width: "100%",
                },
                "& .MuiTableCell-root": {
                    borderBottomColor: theme.palette.divider
                }
            }}>
                {rows && rows.map((r, idx) => <CardDetailRow key={idx} label={r.label} ValueComponent={() => <TextField source="info" record={r} />} />)}

                {lastCaption && <TableCell colSpan={2} align="right" sx={{ fontSize: "12px", border: 0, padding: "15px 0px 0px 15px" }}>{lastCaption}</TableCell>}
            </TableBody>
        </Table>)

}


export const InfoTable2ColWithOnClick = ({ rows, lastCaption }) => {
    const theme = useTheme();
    return (

        <Table sx={{
            width: "100%",
        }}>
            <TableBody sx={{
                width: "100% !important",
                "& .MuiTableRow-root": {
                    width: "100%",
                },
                "& .MuiTableCell-root": {
                    borderBottomColor: theme.palette.divider
                }
            }}>
                {rows && rows.map((r, idx) => <CardDetailRowWithOnClick key={idx} label={r.label} labelColor={theme.palette.text.primary} onClick={r.onClick} ValueComponent={() => <TextField source="info" record={r} />} />)}

                {lastCaption && <TableCell colSpan={2} align="right" sx={{ fontSize: "12px", border: 0, padding: "15px 0px 0px 15px" }}>{lastCaption}</TableCell>}
            </TableBody>
        </Table>)

}


export const InfoTable2ColWithTitle = ({ title, rows }) => {
    const theme = useTheme();
    return (<TableBody sx={{
        width: "100%",
        "& .MuiTableRow-root": {
            width: "100%",
        },
        "& .MuiTableCell-root": {
            borderBottomColor: theme.palette.divider
        }
    }}>
        <TableCell align="left" sx={{ fontWeight: 550 }}>{title}</TableCell>
        {rows && rows.map((r, idx) => <CardDetailRow key={idx} label={r.label} ValueComponent={() => <TextField source="info" record={r} />} />)}
    </TableBody>)

}



const DashboardCard = ({ HeaderIcon, title, NoticeComponent, BodyComponent, onClose, onClick }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();


    return (
        <Card
            sx={{
                height: '100%',
                width: "100%",
                display: 'flex',
                borderRadius: "12px",
                flexDirection: 'column',
                margin: '0px',
            }}>
            <Tooltip title={title}>
                {
                    onClick &&
                    <ButtonBase
                        onClick={onClick}
                        disabled={(!onClick)}
                        sx={{
                            display: 'flex',
                            width: "100%"
                        }}
                    >
                        <CardContent sx={{
                            ...theme.typography.body1,
                            display: 'flex',
                            width: "100%",
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "8px"
                        }}>
                            <CustomCardHeader Icon={() => <HeaderIcon />} title={title} onClose={onClose} NoticeComponent={NoticeComponent} />
                            <BodyComponent />

                        </CardContent>

                    </ButtonBase>
                }

                {
                    !onClick &&

                    <CardContent sx={{
                        ...theme.typography.body1,
                        display: 'flex',
                        width: "100%",
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        padding: "8px"
                    }}>
                        <CustomCardHeader Icon={() => <HeaderIcon />} title={title} onClose={onClose} NoticeComponent={NoticeComponent} />
                        <BodyComponent />

                    </CardContent>


                }

            </Tooltip>
        </Card>
    )
}



export default DashboardCard;