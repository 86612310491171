import React, { useCallback, useEffect, useMemo, useState } from 'react'
//react-admin
import { useTranslate, List, useListContext, useListPaginationContext, Pagination as RaPagination, PaginationActions, useLocaleState } from 'react-admin'
// ticatag
import { LocationsMap } from '../../ui/maps/map'
import LocationHistoryActions from './actions'
import { useParams } from 'react-router-dom';
import { deviceName } from '../../rest/restClient';
import { listMapCommonStyle } from '../../themes/commonStyles'
import { Box, Typography, ListSubheader, ListItemButton, ListItem, IconButton, useTheme, useMediaQuery, Button, TablePagination, Pagination } from '@mui/material'
import { OptionsBtn } from '../../ui/button/OptionsBtn'
import PageHeader from '../../ui/layout/PageHeader'
import LoadingDialog from '../../ui/dialog/LoadingDialog'
import NavigateBackBtn from '../../ui/button/NavigateBackBtn'
import { PDF_REPORT_LOCATION_HISTORY } from '../configuration/actions';
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { makeStyles } from '@mui/styles';
import { fontWeight, margin } from '@mui/system';
import StandardDialog from '../../ui/dialog/StandardDialog';
import { getDateTimeToFullLocalString } from '../../utils/date';
import { reverseGeocodingLocationEvent } from '../../utils/utils';




const useStyles = makeStyles({
    root: {
    },
    toolbar: {
        boxShadow: "none !important",
        flexDirection: "column",
        margin: "0px !important",
        padding: "0px !important",
        "&.MuiToolbar-root.MuiTablePagination-toolbar": {
            padding: "0px !important",
            minHeight: "0px  !important",
        }

    },
    displayedRows: {
        margin: "0px !important",
        fontWeight: 500
    },
    actions: {
        margin: "0px !important",
        fontSize: "10px"
    }

});






const MenuCustomListSubheader = ({
}) => {
    const theme = useTheme();
    const translate = useTranslate();
    const classes = useStyles();
    const { id } = useParams();
    let options = []
    options.push({
        label: translate('resources.locations.labels.exportData'),
        path: `/export/create?selectedIds=${id}&selectedEventType=location`
    })
    options.push({
        label: translate('resources.locations.labels.delete'),
        path: `/`
    })


    const {
        isLoading,
        hasNextPage,
        page,
        perPage,
        total,
        setPage,
        setPerPage,
    } = useListPaginationContext();

    const totalPages = useMemo(() => {
        return total != null ? Math.ceil(total / perPage) : undefined;
    }, [perPage, total]);
    const handlePageChange = useCallback(
        (event, page) => {
            event && event.stopPropagation();
            if (page < 0 || page > totalPages - 1) {
                throw new Error(
                    translate('ra.navigation.page_out_of_boundaries', {
                        page: page + 1,
                    })
                );
            }
            setPage(page + 1);
        },
        [totalPages, setPage, translate]
    );

    const labelDisplayedRows = useCallback(
        ({ from, to, count }) =>
            count === -1 && hasNextPage
                ? translate('ra.navigation.partial_page_range_info', {
                    offsetBegin: from,
                    offsetEnd: to,
                    _: `%{from}-%{to} of more than %{to}`,
                })
                : translate('ra.navigation.page_range_info', {
                    offsetBegin: from,
                    offsetEnd: to,
                    total: count === -1 ? to : count,
                    _: `%{from}-%{to} of %{count === -1 ? to : count}`,
                }),
        [translate, hasNextPage]
    );

    const labelItem = useCallback(
        type => translate(`ra.navigation.${type}`, { _: `Go to ${type} page` }),
        [translate]
    );

    if (isLoading) {
        return <Toolbar variant="dense" />;
    }

    // Avoid rendering TablePagination if "page" value is invalid
    if (total === 0 || page < 1 || (total != null && page > totalPages)) {
        if (limit != null && process.env.NODE_ENV === 'development') {
            console.warn(
                'The Pagination limit prop is deprecated. Empty state should be handled by the component displaying data (Datagrid, SimpleList).'
            );
        }
        return null;
    }

    return (
        <ListSubheader
            component="div"
            sx={{
                padding: "0px 0px 16px 0px",
                margin: "0px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            }}>


            <Box sx={{
                padding: "0px",
                margin: "4px 0px 0px 4px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            }}>

                <TablePagination
                    count={total == null ? -1 : total}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[]}
                    page={page - 1}
                    ActionsComponent={(rest) => <PaginationActions siblingCount={0} boundaryCount={1} color="primary"{...rest} />}
                    onPageChange={handlePageChange}
                    slotProps={{
                        actions: {
                            nextButton: { disabled: !hasNextPage }
                        }
                    }}

                    classes={{
                        root: classes.root,
                        toolbar: classes.toolbar,
                        displayedRows: classes.displayedRows,
                        actions: classes.actions,
                    }}
                    component="span"
                    labelRowsPerPage={""}
                    labelDisplayedRows={labelDisplayedRows}
                    getItemAriaLabel={labelItem}
                />
            </Box>
            <Box sx={{ flex: 1 }} />
            <Box sx={{
                padding: "0px",
                margin: "10px 10px 0px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                lineHeight: "18.75px"
            }} >

                <OptionsBtn width={"24"} height={"24"} menuItems={options} />
            </Box>
        </ListSubheader>
    )
}

const MenuCustomListItemNumberIcon = ({ number, color }) => {
    const theme = useTheme();
    let fontSizeText = "16px";
    if (number > 0 && number < 10) {
        fontSizeText = "16px";
    }
    if (number > 9 && number < 100) {
        fontSizeText = "16px";
    }
    if (number > 99 && number < 1000) {
        fontSizeText = "14px";
    }
    if (number > 999) {
        fontSizeText = "12px";
    }
    return (

        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="8" fill={color} />
            {number != null && number > 0 && <g>
                <text x="50%" y="50%" font-family="Roboto" font-size={fontSizeText} dominant-baseline="middle" text-anchor="middle" font-weight="bold" fill="white">{number}</text>
            </g>}
        </svg>



    )
}


const MenuCustomListItemLocationIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_39822)">
                <path d="M8.00033 5.3335C7.26699 5.3335 6.66699 5.9335 6.66699 6.66683C6.66699 7.40016 7.26699 8.00016 8.00033 8.00016C8.73366 8.00016 9.33366 7.40016 9.33366 6.66683C9.33366 5.9335 8.73366 5.3335 8.00033 5.3335ZM8.00033 1.3335C5.20033 1.3335 2.66699 3.48016 2.66699 6.80016C2.66699 9.0135 4.44699 11.6335 8.00033 14.6668C11.5537 11.6335 13.3337 9.0135 13.3337 6.80016C13.3337 3.48016 10.8003 1.3335 8.00033 1.3335ZM8.00033 12.8868C5.30033 10.4202 4.00033 8.36016 4.00033 6.7935C4.00033 4.38016 5.76699 2.66683 8.00033 2.66683C10.2337 2.66683 12.0003 4.38016 12.0003 6.80016C12.0003 8.36016 10.7003 10.4268 8.00033 12.8868Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_12_39822">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

const MenuCustomListItemDetailsIcon = ({ color }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path d="M12 9h.01" />
            <path d="M11 12h1v4h1" />
        </svg>



    )
}

const MenuCustomListItemSpeedIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-speedtest">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5.636 19.364a9 9 0 1 1 12.728 0" />
            <path d="M16 9l-4 4" />
        </svg>


    )
}

const MenuCustomListItemAltitudeIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-alt"><path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 16v-6a2 2 0 1 1 4 0v6" />
            <path d="M4 13h4" />
            <path d="M11 8v8h4" />
            <path d="M16 8h4" />
            <path d="M18 8v8" />
        </svg>


    )
}

const MenuCustomListItemDateTimeIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-clock">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" stroke-width="1" />
            <path d="M3 12a9 9 0 1 0 18 0 9 9 0 0 0 -18 0" />
            <path d="M12 7V12l3 3" />
        </svg>


    )
}


const MenuCustomListItemAccuracyIcon = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-gradienter">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3.227 14c.917 4 4.497 7 8.773 7c4.277 0 7.858 -3 8.773 -7" />
            <path d="M20.78 10a9 9 0 0 0 -8.78 -7a8.985 8.985 0 0 0 -8.782 7" />
            <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
        </svg>
    )
}

const MenuCustomListItemCloseIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-x">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
        </svg>
    )
}






const MenuCustomListItemSatelliteIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-satellite">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" strokeWidth="0.6666666666666666" />
            <path d="m2.471 4.195 1.724 -1.724a0.667 0.667 0 0 1 0.943 0l3.724 3.724a0.667 0.667 0 0 1 0 0.943l-1.724 1.724a0.667 0.667 0 0 1 -0.943 0L2.471 5.138a0.667 0.667 0 0 1 0 -0.943" />
            <path d="m4 6.667 -2 2 2 2 2 -2" />
            <path d="m6.667 4 2 -2 2 2 -2 2" />
            <path d="m8 8 1 1" />
            <path d="M9.667 11.333a1.667 1.667 0 0 0 1.667 -1.667" />
            <path d="M10 14a4 4 0 0 0 4 -4" /></svg>


    )
}

const MenuCustomListItemCloseBtn = ({ onClick, color }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            <MenuCustomListItemCloseIcon color={color} />
        </IconButton>

    )
}




const MenuCustomListItemDetailsBtn = ({ onClick, color }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >

            <MenuCustomListItemDetailsIcon color={color} />
        </IconButton>

    )
}

const MenuCustomListItem = ({ location, number, theme, handleSelect, selected, isMobile, isListAsDialog }) => {
    const [openDetails, setOpenDetails] = useState(false);
    const [updatedFormattedAddressText, setUpdatedFormattedAddressText] = useState(null);
    const [locale, setLocale] = useLocaleState();
    const record = location.record;
    const maxFormattedAddressCharacters = isListAsDialog ? 70 : 25
    let locationText = null;
    let dateTimeText = null;
    let formattedAddressText = null;



    useEffect(async () => {
        if (record.latitude && record.longitude && !record.formatted_address) {
            const formattedAddress = await reverseGeocodingLocationEvent({ eventId: record.id, latitude: record.latitude, longitude: record.longitude });
            setUpdatedFormattedAddressText(formattedAddress)
        }
    }, [updatedFormattedAddressText])


    if (record.timestamp) {
        dateTimeText = getDateTimeToFullLocalString({ timestamp: record.timestamp, locale });
    }


    if (record.formatted_address) {
        formattedAddressText = record.formatted_address.length > maxFormattedAddressCharacters ? `${record.formatted_address.substring(0, maxFormattedAddressCharacters - 1)}...` : record.formatted_address;
    }
    else if (record.latitude && record.longitude) {
        locationText = `${record.latitude}, ${record.longitude}`;
    }


    if (dateTimeText == null && locationText == null) {
        return null
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
            <ListItemButton
                id={`menu-custom-list-item-${record.id}`}
                button
                onClick={() => {
                    handleSelect(location)
                }}
                sx={{
                    backgroundColor: selected ? theme.palette.primary.light : theme.palette.background.paper,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: "12px 2px 12px 12px",
                    margin: "0px",
                    borderRadius: isMobile ? "12px" : 0
                }} >
                <MenuCustomListItemNumberIcon number={number} color={theme.palette.primary.main} />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: "0px",
                    margin: "0px 10px 0px 10px"
                }}>

                    <Typography sx={{ color: theme.palette.text.primary, fontSize: "14px", fontWeight: 500, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "0px 0px 5px 0px", padding: "0px" }} >{dateTimeText}</Typography>



                    {!updatedFormattedAddressText && locationText &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                padding: "0px",
                                margin: "0px"
                            }}>
                            <MenuCustomListItemSatelliteIcon color={theme.palette.text.secondary} />
                            <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "2px 0px 2px 5px", padding: "0px" }} >{locationText}</Typography>

                        </Box>
                    }
                    {!updatedFormattedAddressText && formattedAddressText &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                padding: "0px",
                                margin: "0px"
                            }}>
                            <MenuCustomListItemLocationIcon color={theme.palette.text.secondary} />
                            <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "2px 0px 2px 5px", padding: "0px" }} >{formattedAddressText}</Typography>

                        </Box>
                    }


                    {updatedFormattedAddressText &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                padding: "0px",
                                margin: "0px"
                            }}>
                            <MenuCustomListItemLocationIcon color={theme.palette.text.secondary} />
                            <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "2px 0px 2px 5px", padding: "0px" }} >{updatedFormattedAddressText.length > maxFormattedAddressCharacters ? `${updatedFormattedAddressText.substring(0, maxFormattedAddressCharacters - 1)}...` : updatedFormattedAddressText}</Typography>

                        </Box>
                    }




                </Box>
                <Box sx={{ flex: 1 }} />
            </ListItemButton>

            <Box sx={{ flex: 1 }} />
            <Box sx={{ margin: "0px 10px 0px 0px" }}>
                <MenuCustomListItemDetailsBtn color={theme.palette.primary.main} onClick={() => setOpenDetails(true)} />
                <StandardDialog
                    fullWidth={false}
                    open={openDetails}
                    onClose={() => setOpenDetails(false)}
                    BodyComponent={() =>
                        <MenuCustomListItemDetails
                            location={location}
                            number={number}
                            theme={theme}
                            onClose={() => setOpenDetails(false)}
                        />} />
            </Box>
        </Box>
    )

}

const MenuCustomListItemDetails = ({ location, number, theme, onClose }) => {
    const [locale, setLocale] = useLocaleState();
    const translate = useTranslate();
    const record = location.record;

    let locationText = null;
    let formattedAddressText = null;
    let dateTimeText = null;
    let altitudeText = null;
    let speedText = null;
    let accuracyText = null;



    if (record.timestamp) {
        dateTimeText = getDateTimeToFullLocalString({ timestamp: record.timestamp, locale });
    }


    if (record.formatted_address) {
        formattedAddressText = record.formatted_address;
    }

    if (record.latitude && record.longitude) {
        locationText = `${record.latitude}, ${record.longitude}`;
    }

    if (record.altitude) {
        altitudeText = `${record.altitude} m`;
    }

    if (record.speed) {
        speedText = `${record.speed} km/h`;
    }

    if (record.accuracy) {
        accuracyText = `${record.accuracy} m`;
    }




    return <ListItem
        sx={{
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: "0px",
            margin: "0px",
            width: "100%"
        }} >
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: "0px",
            margin: "0px",
            width: "100%"
        }}>

            <MenuCustomListItemNumberIcon number={number} color={theme.palette.primary.main} />
            <Typography sx={{ color: theme.palette.text.primary, fontSize: "20px", fontWeight: 500, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "0px 120px 0px 10px", padding: "0px" }} >{translate("ra.message.details")}</Typography>
            <MenuCustomListItemCloseBtn onClick={onClose} color={theme.palette.text.secondary} />


        </Box>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: "0px",
            margin: "20px 10px 0px 10px"
        }}>

            {dateTimeText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px 0px 8px 0px"
                    }}>
                    <MenuCustomListItemDateTimeIcon color={theme.palette.text.primary} />
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: "16px", fontWeight: 500, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "6px 0px 8px 5px", padding: "0px" }} >{dateTimeText}</Typography>

                </Box>
            }


            {formattedAddressText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px 0px 5px 3px"
                    }}>
                    <MenuCustomListItemLocationIcon color={theme.palette.text.secondary} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px", width: "80%" }} >{formattedAddressText}</Typography>

                </Box>}

            {locationText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px 0px 0px 5px"
                    }}>
                    <MenuCustomListItemSatelliteIcon color={theme.palette.text.secondary} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{locationText}</Typography>

                </Box>
            }

            {altitudeText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px 0px 0px 5px"
                    }}>
                    <MenuCustomListItemAltitudeIcon color={theme.palette.text.secondary} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{altitudeText}</Typography>

                </Box>
            }


            {
                accuracyText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px 0px 0px 5px"
                    }}>
                    <MenuCustomListItemAccuracyIcon color={theme.palette.text.secondary} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{accuracyText}</Typography>

                </Box>
            }

            {speedText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px 0px 0px 5px"
                    }}>
                    <MenuCustomListItemSpeedIcon color={theme.palette.text.secondary} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{speedText}</Typography>

                </Box>
            }


        </Box>
    </ListItem>
}


const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};



const LocationHistoryList = ({ ...props }) => {


    const { id } = useParams()
    const [_deviceName, setDeviceName] = useState('');
    const [deviceSN, setDeviceSN] = useState('');
    const theme = useTheme()
    const translate = useTranslate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {

        deviceName(id).then(event => {
            setDeviceName(event.json.name)
            setDeviceSN(event.json.serial_number)
        })

    }, {})


    return (
        <Box>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 2px 0px" }}>
                <NavigateBackBtn /> {translate('resources.devices.labels.locationsHistory')}
            </Typography>
            {
                !isMobile &&
                <PageHeader
                    rows={[
                        [{ title: translate('resources.heading_messages.device_name'), message: _deviceName }, { title: translate('resources.heading_messages.device_id'), message: deviceSN }]
                    ]}
                    isSmall={isMobile}
                />
            }

            <List
                basePath={"locations/{id}/history"}
                actions={!isMobile ? <LocationHistoryActions recordId={id} isMobile={isMobile} /> : <div />}
                filters={<div />}
                filter={{
                    names: 'location',
                    device_ids: id,
                }}
                filterDefaultValues={{ ignore_pagination: true }}
                resource="location_events"
                sort={{ field: 'timestamp', order: 'DESC' }}
                pagination={false}
                disableSyncWithLocation
                perPage={5}
                sx={listMapCommonStyle({ theme, isMobile: isMobile, noActions: isMobile })}
            >

                <LocationsMap
                    history={true}
                    MenuCustomListItem={MenuCustomListItem}
                    MenuCustomListSubheader={MenuCustomListSubheader}
                    translate={translate}
                    allowRouting={true}
                    enablePdfReport={true}
                    pdfReportType={PDF_REPORT_LOCATION_HISTORY}
                    latKey={"latitude"}
                    longKey={"longitude"}
                    timestampKey={"timestamp"}
                    altKey={"altitude"}
                    accuracyKey={"accuracy"}
                    formattedAddressKey={"formatted_address"}
                    iconUrlKey={"image_url"}
                    nameKey={"event_id"}
                    isMobile={isMobile}
                    isListAsDialog={isMobile}
                    showLocationHistoryFiltersBtn={isMobile}
                    recordId={id}
                    InfoDialogComponent={isMobile ? () => <PageHeader rows={[[{ title: translate('resources.heading_messages.device_name'), message: _deviceName }, { title: translate('resources.heading_messages.device_id'), message: deviceSN }]]} isSmall={isMobile} /> : null}
                    deviceName={_deviceName}
                    deviceSn={deviceSN}
                />

                <LoadingPopUp />
            </List>
        </Box>
    )
}

export default React.memo(LocationHistoryList);
