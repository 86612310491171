import React, { useRef, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
import { TopToolbar, Filter, ReferenceInput, TextInput, useTranslate, Datagrid, AutocompleteInput, List, useListContext, useLocaleState, NumberInput, SelectInput } from 'react-admin'

import { Box, Typography, ListSubheader, ListItemButton, ListItem, IconButton, useTheme, Slide, useMediaQuery, Button, Dialog, DialogContent } from '@mui/material'
import Map, { LocationsMap } from '../../../ui/maps/map'
import { PER_PAGE_MAP, isAdminPermissions, isSuperAdmin } from '../../../helper/resourceHelper'
import { autocompleteInputCommonStyle, listMapCommonStyle, numberInputCommonStyle, searchInputCommonStyle, selectInputCommonStyle, textInputCommonStyle } from '../../../themes/commonStyles'
import SearchInputLabelComponent from '../../../ui/inputs/SearchInputLabelComponent'
import { OptionsBtn } from '../../../ui/button/OptionsBtn'
import LoadingDialog from '../../../ui/dialog/LoadingDialog';
import { getDateTimeToFullLocalString } from '../../../utils/date';








const MenuCustomListSubheader = ({
}) => {
    const theme = useTheme();
    const translate = useTranslate()
    const { data, total } = useListContext();
    if (!data) {
        return null
    }
    return (
        <ListSubheader
            component="div"
            sx={{ padding: "12px 12px 16px 12px", margin: "0px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ color: theme.palette.text.primary, fontSize: "16px", fontWeight: 500, lineHeight: "18.75px", fontFamily: "Roboto", textAlign: "left" }}>
                {`${total && total > data.length ? "+" : ""}${data.length} ${translate("resources.addresses.name", { smart_count: data.length, })}`}
            </Typography>

        </ListSubheader>
    )
}

const MenuCustomListItemNumberIcon = ({ number, color }) => {
    const theme = useTheme();
    let fontSizeText = "16px";
    if (number > 0 && number < 10) {
        fontSizeText = "16px";
    }
    if (number > 9 && number < 100) {
        fontSizeText = "16px";
    }
    if (number > 99 && number < 1000) {
        fontSizeText = "14px";
    }
    if (number > 999) {
        fontSizeText = "12px";
    }
    return (

        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="8" fill={color} />
            {number != null && number > 0 && <g>
                <text x="50%" y="50%" font-family="Roboto" font-size={fontSizeText} dominant-baseline="middle" text-anchor="middle" font-weight="bold" fill="white">{number}</text>
            </g>}
        </svg>



    )
}

const MenuCustomListItemDetailsIcon = ({ color }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path d="M12 9h.01" />
            <path d="M11 12h1v4h1" />
        </svg>



    )
}

const MenuCustomListItemClockIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99301 1.3335C4.31301 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31301 14.6668 7.99301 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99301 1.3335ZM7.99967 13.3335C5.05301 13.3335 2.66634 10.9468 2.66634 8.00016C2.66634 5.0535 5.05301 2.66683 7.99967 2.66683C10.9463 2.66683 13.333 5.0535 13.333 8.00016C13.333 10.9468 10.9463 13.3335 7.99967 13.3335Z" fill={color} />
            <path d="M8.33301 4.66683H7.33301V8.66683L10.833 10.7668L11.333 9.94683L8.33301 8.16683V4.66683Z" fill={color} />
        </svg>


    )
}


const MenuCustomListItemLocationIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_39822)">
                <path d="M8.00033 5.3335C7.26699 5.3335 6.66699 5.9335 6.66699 6.66683C6.66699 7.40016 7.26699 8.00016 8.00033 8.00016C8.73366 8.00016 9.33366 7.40016 9.33366 6.66683C9.33366 5.9335 8.73366 5.3335 8.00033 5.3335ZM8.00033 1.3335C5.20033 1.3335 2.66699 3.48016 2.66699 6.80016C2.66699 9.0135 4.44699 11.6335 8.00033 14.6668C11.5537 11.6335 13.3337 9.0135 13.3337 6.80016C13.3337 3.48016 10.8003 1.3335 8.00033 1.3335ZM8.00033 12.8868C5.30033 10.4202 4.00033 8.36016 4.00033 6.7935C4.00033 4.38016 5.76699 2.66683 8.00033 2.66683C10.2337 2.66683 12.0003 4.38016 12.0003 6.80016C12.0003 8.36016 10.7003 10.4268 8.00033 12.8868Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_12_39822">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

const MenuCustomListItemCloseIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-x">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
        </svg>


    )
}


const MenuCustomListItemSatelliteIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-satellite">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" strokeWidth="0.6666666666666666" />
            <path d="m2.471 4.195 1.724 -1.724a0.667 0.667 0 0 1 0.943 0l3.724 3.724a0.667 0.667 0 0 1 0 0.943l-1.724 1.724a0.667 0.667 0 0 1 -0.943 0L2.471 5.138a0.667 0.667 0 0 1 0 -0.943" />
            <path d="m4 6.667 -2 2 2 2 2 -2" />
            <path d="m6.667 4 2 -2 2 2 -2 2" />
            <path d="m8 8 1 1" />
            <path d="M9.667 11.333a1.667 1.667 0 0 0 1.667 -1.667" />
            <path d="M10 14a4 4 0 0 0 4 -4" /></svg>


    )
}

const MenuCustomListItemCloseBtn = ({ onClick, color }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            <MenuCustomListItemCloseIcon color={color} />
        </IconButton>

    )
}

const MenuCustomListItemDetailsBtn = ({ onClick, color }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >

            <MenuCustomListItemDetailsIcon color={color} />
        </IconButton>

    )
}

const MenuCustomListItem = ({ location, number, theme, translate, handleSelect, selected, isMobile }) => {
    const [openDetails, setOpenDetails] = useState(false);
    const [locale, setLocale] = useLocaleState();
    const record = location.record
    const seletecBgColor = theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.background.paper;


    let locationText = null;
    let formattedAddressText = null;



    if (record.latitude && record.longitude) {
        locationText = `${record.latitude}, ${record.longitude}`;
    }
    if (record.formatted_address) {
        formattedAddressText = record.formatted_address.length > 25 ? `${record.formatted_address.substring(0, 24)}...` : record.formatted_address;
    }



    return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <ListItemButton
            id={`menu-custom-list-item-${record.id}`}
            button
            onClick={() => {
                handleSelect(location)
            }}
            sx={{
                backgroundColor: selected ? seletecBgColor : theme.palette.background.paper,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: "12px",
                margin: "0px",
                borderRadius: isMobile ? "12px" : 0
            }} >
            <MenuCustomListItemNumberIcon number={number} color={theme.palette.primary.main} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: "0px",
                margin: "0px 10px 0px 10px"
            }}>

                <Typography sx={{ color: theme.palette.text.primary, fontSize: "14px", fontWeight: 500, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "0px 0px 0px 0px", padding: "0px" }} >{record.id}</Typography>
                <Typography sx={{ color: theme.palette.text.primary, fontSize: "10px", fontWeight: 500, lineHeight: "15px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "0px 0px 5px 0px", padding: "0px" }} >{record.id}</Typography>



                {locationText &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: "0px",
                            margin: "0px"
                        }}>
                        <MenuCustomListItemSatelliteIcon color={theme.palette.text.secondary} />
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "2px 0px 2px 5px", padding: "0px" }} >{locationText}</Typography>

                    </Box>
                }
                {formattedAddressText &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: "0px",
                            margin: "0px"
                        }}>
                        <MenuCustomListItemLocationIcon color={theme.palette.text.secondary} />
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "2px 0px 2px 5px", padding: "0px" }} >{formattedAddressText}</Typography>

                    </Box>
                }




            </Box>
            <Box sx={{ flex: 1 }} />
        </ListItemButton>

    </Box >
}

const MenuCustomListItemDetails = ({ location, number, theme, onClose }) => {

    const translate = useTranslate();
    const record = location.record
    let options = []
    options.push({
        label: translate('resources.devices.labels.locationsHistory'),
        path: `/locations/${record.id}/history`
    })

    let nameText = null;
    let locationText = null;
    let formattedAddressText = null;
    let noLocationText = null;
    let noSubscriptionText = null;
    let dateTimeText = null;

    if (record && record.last_location) {
        if (record.last_location.timestamp) {
            dateTimeText = getDateTimeToFullLocalString({ timestamp: record.last_location.timestamp, locale });
        }


        if (record.last_location.formatted_address) {
            formattedAddressText = record.last_location.formatted_address;
        }
        if (record.last_location.latitude && record.last_location.longitude) {
            locationText = `${record.last_location.latitude}, ${record.last_location.longitude}`;
        }

    }

    if (dateTimeText == null && locationText == null) {
        noLocationText = translate("resources.devices.no_location");
    }


    if (record && record.subscription_status == "inactive") {
        noSubscriptionText = translate("resources.devices.no_device_subscription");
    }

    if (record && record.name) {
        nameText = record.name;
    }


    return <ListItem
        sx={{
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: "12px",
            margin: "0px"
        }} >
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: "0px",
            margin: "0",
            width: "100%"
        }}>
            <MenuCustomListItemNumberIcon number={number} color={theme.palette.primary.main} />
            <Typography sx={{ color: theme.palette.text.primary, fontSize: "20px", fontWeight: 500, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "0px 120px 0px 10px", padding: "0px" }} >{translate("ra.message.details")}</Typography>
            <MenuCustomListItemCloseBtn onClick={onClose} color={theme.palette.text.secondary} />


        </Box>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: "0px",
            margin: "20px 10px 0px 10px"
        }}>
            {nameText &&
                <Typography sx={{ color: theme.palette.text.primary, fontSize: "16px", fontWeight: 500, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", alignmentBaseline: "central", margin: "0px 0px 5px 0px", padding: "0px" }} >{record.name}</Typography>

            }

            {dateTimeText && <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: "0px",
                    margin: "0px 0px 5px 0px"
                }}>
                <MenuCustomListItemClockIcon color={theme.palette.text.secondary} />
                <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{dateTimeText}</Typography>

            </Box>}


            {formattedAddressText && <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: "0px",
                    margin: "0px 0px 5px 0px"
                }}>
                <MenuCustomListItemLocationIcon color={theme.palette.text.secondary} />
                <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px", width: "80%" }} >{formattedAddressText}</Typography>

            </Box>}

            {locationText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px"
                    }}>
                    <MenuCustomListItemSatelliteIcon color={theme.palette.text.secondary} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{locationText}</Typography>

                </Box>
            }

            {noLocationText &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: "0px",
                        margin: "0px"
                    }}>
                    <MenuCustomListItemLocationIcon color={theme.palette.warning.dark} />
                    <Typography sx={{ color: theme.palette.text.secondary, fontSize: "12px", fontWeight: 400, lineHeight: "20px", fontFamily: "Roboto", textAlign: "left", margin: "8px 0px 8px 5px", padding: "0px" }} >{noLocationText}</Typography>

                </Box>
            }


        </Box>
    </ListItem>
}



const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};


const AddressList = ({ classes, ...props }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const iconColorMap = {
        parameterKey: "id",
        matchingList: [
            {
                isTrue: (id) => true,
                color: "#09630c",
                name: "addressIconUrl_24x24",

            },
        ]

    }


    if (isMobile) return null
    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: isMobile ? "0px 0px 0px 0px" : "0px 0px 16px 0px" }}>
                {translate('resources.addresses.name', { smart_count: 2 })}
            </Typography>
            <List
                {...props}
                disableSyncWithLocation
                title={translate('resources.addresses.name', { smart_count: 2 })}
                sx={listMapCommonStyle({ theme, isMobile, noActions: isMobile })}
                resource='addresses'
                actions={<div />}
                filters={<Filter {...props} >
                    <NumberInput
                        source="latitude"
                        alwaysOn
                        sx={numberInputCommonStyle({ theme })}
                        label={translate('resources.addresses.latitude')}
                    />
                    <NumberInput
                        source="longitude"
                        alwaysOn
                        sx={numberInputCommonStyle({ theme })}
                        label={translate('resources.addresses.longitude')}
                    />


                </Filter>
                }
                filter={{
                    radius_meters: 100
                }}
                pagination={false}
            >

                <LocationsMap
                    isMobile={false}
                    MenuCustomListItem={MenuCustomListItem}
                    MenuCustomListSubheader={MenuCustomListSubheader}
                    translate={translate}
                    allowRouting={false}
                    latKey={"latitude"}
                    longKey={"longitude"}
                    formattedAddressKey={"formatted_address"}
                    iconColorMap={iconColorMap}
                    handleMapClickWithSetFilters={({ e, setFilters }) => {
                        if (e && e.latlng && e.latlng.lat && e.latlng.lng) {
                            setFilters({ latitude: e.latlng.lat, longitude: e.latlng.lng }, undefined, false)
                        }
                    }}
                />

                <LoadingPopUp />

            </List>
        </>
    )
}

export default AddressList