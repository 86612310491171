import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { List, useTranslate } from 'react-admin'
import { useNavigate } from "react-router-dom";
import DashboardCard from "../../ui/card/DashboardCard";
import { useEffect, useState } from "react";
import { listMapCommonStyle } from "../../themes/commonStyles";
import { PER_PAGE_MAP } from "../../helper/resourceHelper";
import { LocationsMap } from "../../ui/maps/map";
import { IconMap as LocationIcon } from '@tabler/icons-react';





const Icon = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-devices">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 9a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-10z" />
            <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
            <path d="M16 9h2" />
        </svg>
    )

}




const LocationsSummaryMap = ({ theme, isMobile }) => {

    const translate = useTranslate();

    return (
        <List
            title={translate('resources.locations.name', { smart_count: 2 })}
            sx={listMapCommonStyle({ theme, isMobile })}
            resource='locations'
            filter={{
                includes: 'last_events,last_seen,image_url',

            }}
            actions={null}
            sort={{ field: "last_seen", order: "DESC" }}
            pagination={false}
            perPage={25}
            storeKey={"LocationsSummaryMap"}
        >

            <LocationsMap
                isMobile={isMobile}
                translate={translate}
                allowRouting={false}
                disableClusteringUi={true}
                disableFullscreenUi={true}
                disableSatteliteUi={true}
                disableZoomUi={true}
                latKey={"last_location.latitude"}
                longKey={"last_location.longitude"}
                timestampKey={"last_location.timestamp"}
                altKey={"last_location.altitude"}
                accuracyKey={"last_location.accuracy"}
                formattedAddressKey={"last_location.formatted_address"}
                iconUrlKey={"image_url"}
                nameKey={"name"}
            />

        </List>
    )

}

const LocationsSummary = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const navigate = useNavigate()


    return (

        <Grid
            item
            sm={12}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >
            <DashboardCard
                HeaderIcon={() => <LocationIcon color={theme.palette.primary.dark} />}
                title={translate("resources.dashboard.titles.locations_summary")}
                BodyComponent={() =>
                    <LocationsSummaryMap
                        theme={theme}
                        isMobile={isSmall}
                    />}
                onClick={() => navigate("/locations")}
            />
        </Grid>
    )
}

export default LocationsSummary;