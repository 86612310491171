import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Box, useTheme, Typography, ButtonBase, useMediaQuery, Chip, Tooltip, Button } from '@mui/material';
import { BooleanInput, DateTimeInput, TimeInput, useInput, useLocaleState, useTranslate } from 'react-admin';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { textInputCommonStyle } from '../../themes/commonStyles';
import dayjs from 'dayjs';
import StandardDialog from '../dialog/StandardDialog';
import { useNavigate } from 'react-router-dom';






const ActiveOnDayIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-checkbox">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 11l3 3l8 -8" />
            <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
        </svg>


    )
}

const InactiveOnDayIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-square">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /></svg>
    )
}


const ActiveOnDayButton = ({
    title = '',
    onClick = (() => { }),
    isActive = true

}) => {
    const theme = useTheme();
    return (<Tooltip
        title={title}
    >
        <Button
            onClick={onClick}>
            {isActive ?
                <ActiveOnDayIcon color={theme.palette.primary.main} /> :
                <InactiveOnDayIcon color={theme.palette.primary.main} />}
        </Button>
    </Tooltip>)




}




const MobileCalendarTimeSlotSelector = ({
    handleSelectDay,
    handleUnSelectDay,
    grid,
    unSelectCell,
    selectCell,
    days
}) => {
    const translate = useTranslate();
    const [openDialog, setOpenDialog] = useState(false)
    const theme = useTheme();
    const navigate = useNavigate()
    const [locale, setLocale] = useLocaleState();

    useEffect(() => {
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            if (isDayContainsMultipleSlots(grid, dayIndex)) {
                setOpenDialog(true)
            }
        }
    }, [grid])


    const isDayContainsMultipleSlots = (gridP, dayIndex) => {
        const dayGrid = gridP[dayIndex];
        let startDetected = false;
        let endDetected = false;
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < 96; oneQuarterSlotIndex++) {
            if (startDetected && endDetected && dayGrid[oneQuarterSlotIndex] === true) {
                return true;
            }
            if (dayGrid[oneQuarterSlotIndex] === true) {
                startDetected = true;
            }
            if (startDetected && dayGrid[oneQuarterSlotIndex] === false) {
                endDetected = true;
            }
        }
        return false;

    }


    const isActiveDay = (dayIndex) => {
        const dayGrid = grid[dayIndex];
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < 96; oneQuarterSlotIndex++) {
            if (dayGrid[oneQuarterSlotIndex] === true) {
                return true
            }
        }
        return false;

    }

    const getDefaultStartDate = (dayIndex) => {
        const today = new Date();
        const dayGrid = grid[dayIndex];

        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < 96; oneQuarterSlotIndex++) {
            if (dayGrid[oneQuarterSlotIndex] === true) {
                const hours = Math.floor(oneQuarterSlotIndex / 4);
                const minutes = (oneQuarterSlotIndex % 4) * 15;
                today.setHours(hours);
                today.setMinutes(minutes);
                today.setSeconds(0);
                today.setMilliseconds(0);
                return dayjs(today); // Return the start time as Day.js object
            }
        }

        // If no true slot is found, return the end of the day
        return dayjs(today).endOf('day');
    };


    const getDefaultEndTime = (dayIndex) => {
        const today = new Date();
        const dayGrid = grid[dayIndex];

        for (let oneQuarterSlotIndex = 95; oneQuarterSlotIndex >= 0; oneQuarterSlotIndex--) {
            if (dayGrid[oneQuarterSlotIndex] === true) {
                const hours = Math.floor((oneQuarterSlotIndex + 1) / 4);
                const minutes = ((oneQuarterSlotIndex + 1) % 4) * 15;
                today.setHours(hours);
                today.setMinutes(minutes);
                today.setSeconds(59); // End time is the end of this minute
                today.setMilliseconds(999);
                return dayjs(today); // Return the end time as Day.js object
            }
        }

        // If no true slot is found, return the end of the day
        return dayjs(today).endOf('day');
    };



    const updateStartTime = (dayIndex, startTime) => {
        const dayGrid = grid[dayIndex];
        const startTimeIndex = Math.floor(startTime.hour() * 4 + startTime.minute() / 15);

        // Clear all slots before the start time
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < startTimeIndex; oneQuarterSlotIndex++) {
            unSelectCell(dayIndex, oneQuarterSlotIndex);
        }

        // Fill slots starting from the start time until the next active slot
        for (let oneQuarterSlotIndex2 = startTimeIndex; oneQuarterSlotIndex2 < 96; oneQuarterSlotIndex2++) {
            if (dayGrid[oneQuarterSlotIndex2] === true) {
                break; // Stop if an already active slot is encountered
            }
            selectCell(dayIndex, oneQuarterSlotIndex2);
        }
    };

    const updateEndTime = (dayIndex, endTime) => {
        const dayGrid = grid[dayIndex];
        const endTimeIndex = Math.floor(endTime.hour() * 4 + endTime.minute() / 15);

        // Clear all slots after the end time
        for (let oneQuarterSlotIndex = endTimeIndex; oneQuarterSlotIndex < 96; oneQuarterSlotIndex++) {
            unSelectCell(dayIndex, oneQuarterSlotIndex);
        }

        // Fill slots starting from the end time and moving backward until the next active slot
        for (let oneQuarterSlotIndex2 = endTimeIndex - 1; oneQuarterSlotIndex2 >= 0; oneQuarterSlotIndex2--) {
            if (dayGrid[oneQuarterSlotIndex2] === true) {
                break; // Stop if an already active slot is encountered
            }
            selectCell(dayIndex, oneQuarterSlotIndex2);
        }
    };




    return (

        <Table sx={{ width: "310px" }} aria-label="simple table"
        >
            <TableBody>
                {
                    days.map((day, index) => {
                        const rows = [
                            <TableRow
                                index={(index + 1) * 11}
                                sx={{
                                    backgroundColor: theme.palette.primary.light,
                                    borderRadius: "12px",
                                    '&:last-child td, &:last-child th': {
                                        border: 0
                                    }
                                }}
                            >
                                <TableCell align="left" sx={{ padding: "12px 0px 12px 10px", maxWidth: "40px !important" }}> <Typography sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", width: "40px" }}>{day}</Typography></TableCell>
                                <TableCell align="right" sx={{ padding: "0px", fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}>
                                    <ActiveOnDayButton
                                        isActive={isActiveDay(index)}
                                        onClick={() => {
                                            if (isActiveDay(index)) {
                                                handleUnSelectDay(index);
                                            } else {
                                                handleSelectDay(index);
                                            }

                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ]
                        if (isActiveDay(index)) {
                            rows.push(
                                <TableRow
                                    index={(index + 2) * 17}
                                    sx={{
                                        borderRadius: "12px",
                                        '&:last-child td, &:last-child th': {
                                            border: 0
                                        }
                                    }}
                                >
                                    <TableCell align="left" sx={{ padding: "12px 0px 25px 20px", maxWidth: "50px !important" }}> <Typography sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", width: "40px" }}>{translate('resources.devices.from')}</Typography></TableCell>
                                    <TableCell align="right" sx={{ padding: "0px", fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                            <TimePicker
                                                label={translate('resources.devices.from')}
                                                defaultValue={getDefaultStartDate(index)}
                                                onChange={(newValue) => updateStartTime(index, newValue)}
                                                sx={textInputCommonStyle({ theme })}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                </TableRow>
                            )
                            rows.push(
                                <TableRow
                                    index={(index + 3) * 7}
                                    sx={{
                                        borderRadius: "12px",
                                        '&:last-child td, &:last-child th': {
                                            border: 0
                                        }
                                    }}
                                >
                                    <TableCell align="left" sx={{ padding: "12px 0px 25px 20px", maxWidth: "50px !important" }}> <Typography sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px", width: "40px" }}>{translate('resources.devices.to')}</Typography></TableCell>
                                    <TableCell align="right" sx={{ padding: "0px", fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                            <TimePicker
                                                label={translate('resources.devices.to')}
                                                defaultValue={getDefaultEndTime(index)}
                                                onChange={(newValue) => updateEndTime(index, newValue)}
                                                sx={textInputCommonStyle({ theme })}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        return rows;
                    })

                }

            </TableBody>
            <StandardDialog
                alertSeverity='warning'
                fullWidth={false}
                alertText={translate('resources.notifications.cannot_edit_notification_on_mobile_title')}
                bodyText={translate('resources.notifications.cannot_edit_notification_on_mobile_message')}
                showAlert={true}
                onClose={() => { }}
                BodyComponent={() => <Button variant='contained' onClick={() => navigate(-1)} sx={{ padding: "0px", borderRadius: "5px" }}>{translate(`ra.action.back`)}</Button>}
                open={openDialog} />
        </Table>


    );
};

const CalendarTimeSlotSelector = ({ }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme => theme.breakpoints.up('sm') && theme.breakpoints.down('md'));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('md') && theme.breakpoints.down('lg'));
    const isXLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const { field: mondayTimeWindowField } = useInput({ source: "monday_time_window", defaultValue: "ffffffffffffffffffffffff" });
    const { field: tuesdayTimeWindowField } = useInput({ source: "tuesday_time_window", defaultValue: "ffffffffffffffffffffffff" });
    const { field: wednesdayTimeWindowField } = useInput({ source: "wednesday_time_window", defaultValue: "ffffffffffffffffffffffff" });
    const { field: thursdayTimeWindowField } = useInput({ source: "thursday_time_window", defaultValue: "ffffffffffffffffffffffff" });
    const { field: fridayTimeWindowField } = useInput({ source: "friday_time_window", defaultValue: "ffffffffffffffffffffffff" });
    const { field: saturdayTimeWindowField } = useInput({ source: "saturday_time_window", defaultValue: "ffffffffffffffffffffffff" });
    const { field: sundayTimeWindowField } = useInput({ source: "sunday_time_window", defaultValue: "ffffffffffffffffffffffff" });


    const daySlotsToCoded = (daySlots) => {
        // daySlots is expected to be an array of 96 booleans (representing 15-minute intervals)
        let binaryString = "";

        // Flatten the 2D array into a single array
        daySlots.forEach((slot) => {
            // Add '1' if the slot is selected, otherwise '0'
            binaryString += slot ? "1" : "0";
        });

        // Convert binary string to hex string
        let hexString = "";
        for (let i = 0; i < binaryString.length; i += 8) {
            // Convert each group of 8 bits to a hex byte
            const byte = binaryString.substring(i, i + 8);
            hexString += parseInt(byte, 2).toString(16).padStart(2, '0');
        }

        return hexString;
    };


    // Convert hex string back to a 2D array of day slots
    const codedToDay = (hexString) => {
        // Convert hex string to binary string
        let binaryString = "";
        for (let i = 0; i < hexString.length; i += 2) {
            const hexByte = hexString.substring(i, i + 2);
            binaryString += parseInt(hexByte, 16).toString(2).padStart(8, '0');
        }

        // Split binary string into 4 slots per hour (15 minutes each), 24 hours a day (96 slots total)
        const daySlots = [];
        for (let quarterSlotIndex = 0; quarterSlotIndex < 96; quarterSlotIndex++) {
            // Convert '0' or '1' back to a boolean (false/true)
            daySlots.push(binaryString[quarterSlotIndex] === "1");
        }
        return daySlots;
    };

    const onChangeTimeWindows = (updatedSlots) => {

        mondayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[0]))
        tuesdayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[1]))
        wednesdayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[2]))
        thursdayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[3]))
        fridayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[4]))
        saturdayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[5]))
        sundayTimeWindowField.onChange(daySlotsToCoded(updatedSlots[6]))

    }

    const initialDayStateExists = (dayIndex) => {
        if (dayIndex === 0) return mondayTimeWindowField && mondayTimeWindowField.value && mondayTimeWindowField.value.length === 24
        if (dayIndex === 1) return tuesdayTimeWindowField && tuesdayTimeWindowField.value && tuesdayTimeWindowField.value.length === 24
        if (dayIndex === 2) return wednesdayTimeWindowField && wednesdayTimeWindowField.value && wednesdayTimeWindowField.value.length === 24
        if (dayIndex === 3) return thursdayTimeWindowField && thursdayTimeWindowField.value && thursdayTimeWindowField.value.length === 24
        if (dayIndex === 4) return fridayTimeWindowField && fridayTimeWindowField.value && fridayTimeWindowField.value.length === 24
        if (dayIndex === 5) return saturdayTimeWindowField && saturdayTimeWindowField.value && saturdayTimeWindowField.value.length === 24
        if (dayIndex === 6) return sundayTimeWindowField && sundayTimeWindowField.value && sundayTimeWindowField.value.length === 24

    }
    const getInitialDayValue = (dayIndex) => {
        if (dayIndex === 0) return mondayTimeWindowField.value
        if (dayIndex === 1) return tuesdayTimeWindowField.value
        if (dayIndex === 2) return wednesdayTimeWindowField.value
        if (dayIndex === 3) return thursdayTimeWindowField.value
        if (dayIndex === 4) return fridayTimeWindowField.value
        if (dayIndex === 5) return saturdayTimeWindowField.value
        if (dayIndex === 6) return sundayTimeWindowField.value

    }

    // Define a 2D array to represent the days and 15-minute intervals (96 intervals per day)
    const rows = 7;
    const columns = 96;
    const initialGrid = Array.from({ length: rows }, (v, dayIndex) => initialDayStateExists(dayIndex) ?
        codedToDay(getInitialDayValue(dayIndex)) :
        Array(columns).fill(true)
    ); // Initialize the grid

    const [grid, setGrid] = useState(initialGrid);
    const [isSelecting, setIsSelecting] = useState(false);
    const theme = useTheme();
    const translate = useTranslate();
    const slotWidth = 12;
    const slotHeight = 60;
    const timePicks = ["00:00", "03:00", "06:00", "09:00", "12:00", "15:00", "18:00", "21:00"]
    const days = [translate('resources.notifications.fields.calendar.monday'),
    translate('resources.notifications.fields.calendar.tuesday'),
    translate('resources.notifications.fields.calendar.wednesday'),
    translate('resources.notifications.fields.calendar.thursday'),
    translate('resources.notifications.fields.calendar.friday'),
    translate('resources.notifications.fields.calendar.saturday'),
    translate('resources.notifications.fields.calendar.sunday')];


    const handleSelectDay = (dayIndex) => {
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < columns; oneQuarterSlotIndex++) {
            selectCell(dayIndex, oneQuarterSlotIndex);
        }
    };


    const handleUnSelectDay = (dayIndex) => {
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < columns; oneQuarterSlotIndex++) {
            unSelectCell(dayIndex, oneQuarterSlotIndex);
        }
    };

    const handleSelectWeekdays = () => {
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < columns; oneQuarterSlotIndex++) {
            selectCell(0, oneQuarterSlotIndex);
            selectCell(1, oneQuarterSlotIndex);
            selectCell(2, oneQuarterSlotIndex);
            selectCell(3, oneQuarterSlotIndex);
            selectCell(4, oneQuarterSlotIndex);
        }

    };
    const handleSelectWeekend = () => {
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < columns; oneQuarterSlotIndex++) {
            selectCell(5, oneQuarterSlotIndex);
            selectCell(6, oneQuarterSlotIndex);
        }

    };

    const handleSelectAlldays = () => {
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < columns; oneQuarterSlotIndex++) {
            selectCell(0, oneQuarterSlotIndex);
            selectCell(1, oneQuarterSlotIndex);
            selectCell(2, oneQuarterSlotIndex);
            selectCell(3, oneQuarterSlotIndex);
            selectCell(4, oneQuarterSlotIndex);
            selectCell(5, oneQuarterSlotIndex);
            selectCell(6, oneQuarterSlotIndex);
        }

    };
    const handleClearAll = () => {
        for (let oneQuarterSlotIndex = 0; oneQuarterSlotIndex < columns; oneQuarterSlotIndex++) {
            unSelectCell(0, oneQuarterSlotIndex);
            unSelectCell(1, oneQuarterSlotIndex);
            unSelectCell(2, oneQuarterSlotIndex);
            unSelectCell(3, oneQuarterSlotIndex);
            unSelectCell(4, oneQuarterSlotIndex);
            unSelectCell(5, oneQuarterSlotIndex);
            unSelectCell(6, oneQuarterSlotIndex);
        }

    };


    const handleMouseDown = (rowIndex, colIndex) => {
        setIsSelecting(true);
        toggleCell(rowIndex, colIndex);
    };

    const handleMouseEnter = (rowIndex, colIndex) => {
        if (isSelecting) {
            toggleCell(rowIndex, colIndex);
        }
    };

    const handleMouseUp = () => {
        setIsSelecting(false);
    };

    const toggleCell = (rowIndex, colIndex) => {
        const updatedGrid = [...grid];
        updatedGrid[rowIndex][colIndex] = !updatedGrid[rowIndex][colIndex];
        setGrid(updatedGrid);
        onChangeTimeWindows(updatedGrid)
    };

    const selectCell = (rowIndex, colIndex) => {
        const updatedGrid = [...grid];
        updatedGrid[rowIndex][colIndex] = true;
        setGrid(updatedGrid);
        onChangeTimeWindows(updatedGrid)
    };


    const unSelectCell = (rowIndex, colIndex) => {
        const updatedGrid = [...grid];
        updatedGrid[rowIndex][colIndex] = false;
        setGrid(updatedGrid);
        onChangeTimeWindows(updatedGrid);
    };

    const ActionButton = ({ label, onClick }) => {
        return (
            <TableCell
                colSpan={8}
                sx={{ border: 0, padding: "10px 0px 0px 0px", margin: "0px", width: `${slotWidth * 8}px` }} >
                <ButtonBase
                    onClick={onClick}
                    sx={{ display: 'flex', width: "auto" }}
                >
                    <Box sx={{
                        margin: "5px",
                        padding: "0px",
                        height: `${slotHeight - 10}px`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: `${(slotWidth * 8) - 10}px`,
                        borderRadius: "10px",
                        backgroundColor: theme.palette.primary.main,

                    }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>{label}</Typography>
                    </Box>
                </ButtonBase>
            </TableCell>
        )
    }


    const EmptyActionCell = () => {
        return (
            <TableCell
                colSpan={8}
                sx={{ border: 0, padding: "0px", margin: "0px", width: `${slotWidth * 8}px` }} >

            </TableCell>
        )
    }


    if (isSmall || isMedium || isLarge) {
        return (
            <MobileCalendarTimeSlotSelector
                grid={grid}
                days={days}
                handleSelectDay={handleSelectDay}
                handleUnSelectDay={handleUnSelectDay}
                selectCell={selectCell}
                unSelectCell={unSelectCell}
            />
        )
    }

    return (

        <Table sx={{ width: `${slotWidth * 108}px` }} aria-label="simple table"
            onMouseUp={handleMouseUp}
        >
            <TableHead>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell
                        align="center"
                        sx={{
                            border: 0,
                            padding: "0px",
                            margin: "0px",
                            width: `${slotWidth * 8}px`
                        }} />
                    {timePicks.map((timePick, idx) => (
                        [<TableCell
                            key={idx}
                            colSpan={4}
                            align="center"
                            sx={{
                                border: 0,
                                padding: "0px",
                                margin: "0px",
                                width: `${slotWidth * 4}px`
                            }}>{timePick}</TableCell>,
                        <TableCell
                            key={idx * 120}
                            align="center"
                            colSpan={8}
                            sx={{
                                border: 0,
                                padding: "0px",
                                margin: "0px",
                                width: `${slotWidth * 8}px`
                            }} />]

                    ))}

                </TableRow>

                {grid.map((row, dayIndex) => (
                    <TableRow
                        key={dayIndex}
                    >
                        <TableCell component="th" scope="row" sx={{ border: 0, padding: "0px", margin: "0px", width: `${slotWidth * 8}px` }} >
                            <ButtonBase
                                onClick={() => handleSelectDay(dayIndex)}
                                sx={{ display: 'flex', width: "auto" }}
                            >
                                <Box sx={{
                                    margin: "5px",
                                    padding: "0px",
                                    height: `${slotHeight - 10}px`,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: `${(slotWidth * 8) - 10}px`,
                                    borderRadius: "10px",
                                    backgroundColor: theme.palette.primary.main,

                                }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>{days[dayIndex]}</Typography>
                                </Box>

                            </ButtonBase>
                        </TableCell>
                        <TableCell align="center" sx={{ border: 0, padding: "0px", margin: "0px", width: `${slotWidth}px` }}></TableCell>
                        <TableCell align="center" sx={{ border: 0, padding: "0px", margin: "0px", width: `${slotWidth}px` }}></TableCell>

                        {row.map((selected, oneQuarterSlotIndex) => {
                            const borderRight = ((oneQuarterSlotIndex % 4) === 3) ? 1 : 0;
                            const borderRightWidth = ((oneQuarterSlotIndex % 4) === 3) ? "0.5px" : "0px";
                            const borderLeft = ((oneQuarterSlotIndex % 4) === 0) ? 1 : 0;
                            const borderLeftWidth = ((oneQuarterSlotIndex % 4) === 0) ? "0.5px" : "0px";

                            return (
                                <TableCell
                                    key={oneQuarterSlotIndex}
                                    sx={{
                                        backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.default,
                                        borderBottom: 1,
                                        borderBottomWidth: "0.5px",
                                        borderTop: 1,
                                        borderTopWidth: "0.5px",
                                        borderRight: borderRight,
                                        borderRightWidth: borderRightWidth,
                                        borderLeft: borderLeft,
                                        borderLeftWidth: borderLeftWidth,
                                        borderColor: theme.palette.text.secondary,
                                        cursor: 'pointer',
                                        width: `${slotWidth}px`,
                                        height: `${slotHeight}px`,
                                        padding: "0px",
                                        margin: "0px"
                                    }}
                                    onMouseDown={() => handleMouseDown(dayIndex, oneQuarterSlotIndex)}
                                    onMouseEnter={() => handleMouseEnter(dayIndex, oneQuarterSlotIndex)}
                                />
                            )
                        }
                        )}
                    </TableRow>
                ))}

                <TableRow>

                    <EmptyActionCell />
                    <EmptyActionCell />
                    <EmptyActionCell />
                    <EmptyActionCell />
                    <EmptyActionCell />
                    <EmptyActionCell />
                    <EmptyActionCell />
                    <EmptyActionCell />
                    <ActionButton label={translate('resources.notifications.fields.calendar.weekdays')} onClick={() => handleSelectWeekdays()} />
                    <ActionButton label={translate('resources.notifications.fields.calendar.weekend')} onClick={() => handleSelectWeekend()} />
                    <ActionButton label={translate('resources.notifications.fields.calendar.alldays')} onClick={() => handleSelectAlldays()} />
                    <ActionButton label={translate('resources.notifications.fields.calendar.clear')} onClick={() => handleClearAll()} />


                </TableRow>

            </TableBody>
        </Table>


    );
};

export default CalendarTimeSlotSelector;