import { Grid, useTheme, useMediaQuery, Typography } from "@mui/material"
import DashboardCard from "../../ui/card/DashboardCard";
import { useEffect, useState } from "react";
import { getBroadcastMessages, updateUserUiSetup } from "../../rest/restClient";
import { set } from "lodash";
import { getUiHiddenMessages, USER_ID, USER_USER_UI_SETUP_FETCHED_AT } from "../configuration/actions";





const InfoIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill={color} class="icon icon-tabler icons-tabler-filled icon-tabler-info-circle">
            <path stroke="none" d="M0 0h45v45H0z" fill="none" stroke-width="1.875" />
            <path d="M22.5 3.75c10.356 0 18.75 8.394 18.75 18.75a18.75 18.75 0 0 1 -37.491 0.608L3.75 22.5l0.007 -0.525C4.035 11.863 12.319 3.75 22.5 3.75m0 16.875h-1.875l-0.219 0.013a1.875 1.875 0 0 0 0 3.724L20.625 24.375v5.625l0.013 0.219a1.875 1.875 0 0 0 1.643 1.643L22.5 31.875h1.875l0.219 -0.013a1.875 1.875 0 0 0 1.643 -1.643L26.25 30l-0.013 -0.219a1.875 1.875 0 0 0 -1.433 -1.607l-0.21 -0.037L24.375 28.125v-5.625l-0.013 -0.219a1.875 1.875 0 0 0 -1.643 -1.643zm0.019 -5.625 -0.238 0.013a1.875 1.875 0 0 0 0 3.724L22.5 18.75l0.238 -0.013a1.875 1.875 0 0 0 0 -3.724z" />
        </svg>


    )
}


const WarningIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill={color} class="icon icon-tabler icons-tabler-filled icon-tabler-alert-circle">
            <path stroke="none" d="M0 0h45v45H0z" fill="none" stroke-width="1.875" />
            <path d="M22.5 3.75c10.356 0 18.75 8.394 18.75 18.75a18.75 18.75 0 0 1 -37.491 0.608L3.75 22.5l0.007 -0.525C4.035 11.863 12.319 3.75 22.5 3.75m0.019 24.375 -0.238 0.013a1.875 1.875 0 0 0 0 3.724L22.5 31.875l0.238 -0.013a1.875 1.875 0 0 0 0 -3.724zM22.5 13.125a1.875 1.875 0 0 0 -1.862 1.656L20.625 15v7.5l0.013 0.219a1.875 1.875 0 0 0 3.724 0L24.375 22.5V15l-0.013 -0.219A1.875 1.875 0 0 0 22.5 13.125" />
        </svg>




    )
}


const MarketingIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-confetti">
            <path stroke="none" d="M0 0h28v28H0z" fill="none" stroke-width="1.1666666666666667" />
            <path d="M4.667 5.833h2.333" />
            <path d="M5.833 4.667v2.333" />
            <path d="m13.417 4.667 -0.584 2.333" />
            <path d="M21 5.833h2.333" />
            <path d="M22.167 4.667v2.333" />
            <path d="M17.5 10.5 16.333 11.667" />
            <path d="m21 15.167 2.333 -0.584" />
            <path d="M21 22.167h2.333" />
            <path d="M22.167 21v2.333" />
            <path d="M16.333 19.271 8.729 11.667 3.608 22.843a1.167 1.167 0 0 0 1.551 1.551z" />
        </svg>




    )
}


const Icon = ({ type }) => {

    const theme = useTheme();
    if (type === "marketing") {
        return <MarketingIcon color={theme.palette.primary.dark} />
    }
    if (type === "warning") {
        return <WarningIcon color={theme.palette.warning.dark} />
    }
    return <InfoIcon color={theme.palette.primary.dark} />

}





const BroadcastMessageBodyComponent = ({ message }) => {

    return (
        <Typography variant="body2" sx={{ color: "text.secondary", textAlign: "left", margin: "0px 0px 0px 20px" }} textAlign={"left"}>
            {message}
        </Typography>
    )


}


const BroadcastMessages = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [messages, setMessages] = useState([])


    useEffect(async () => {
        getBroadcastMessages().then((res) => {
            if (res && res.status === 200 &&
                res.json && res.json._embedded && res.json._embedded.broadcast_messages &&
                Array.isArray(res.json._embedded.broadcast_messages)
            ) {
                getUiHiddenMessages().then((hMsgs) => {
                    setMessages(res.json._embedded.broadcast_messages.filter((m) => !hMsgs.includes(m.message_key)))
                })

            }

        })


    }, [])

    const hideMessage = (messageKey) => {
        const userId = localStorage.getItem(USER_ID)
        const patchUserUiSetup = {
            "messages_to_hide": [messageKey]
        }

        updateUserUiSetup(userId, patchUserUiSetup).then((res) => {
            if (res && res.status === 200) {
                setMessages(messages.filter((message) => message.message_key !== messageKey))
                localStorage.removeItem(USER_USER_UI_SETUP_FETCHED_AT)
            }
        })


    }

    if (!messages || messages.length < 1) {
        return null
    }
    return (


        <Grid
            item
            sm={12}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >

            <Grid
                container
                spacing={isSmall ? 0 : 1}
                sx={{
                    margin: '0px',
                    width: "100%",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {
                    messages.map((message, index) =>
                        <Grid
                            key={index}
                            item
                            sm={12}
                            sx={{
                                width: "100%",
                                margin: isSmall ? "8px 0px 0px 0px" : '0px',
                            }} >
                            <DashboardCard
                                HeaderIcon={() => <Icon type={message.type} />}
                                title={message.title}
                                BodyComponent={() => <BroadcastMessageBodyComponent message={message.message} />}
                                onClose={() => {
                                    hideMessage(message.message_key)
                                }}
                            />
                        </Grid>)
                }

            </Grid>
        </Grid>
    )
}

export default BroadcastMessages;