import { Grid, useTheme, useMediaQuery, Typography } from "@mui/material"
import { useLocaleState, useTranslate } from 'react-admin'
import DashboardCard, { InfoTable2ColWithOnClick } from "../../ui/card/DashboardCard";
import { getDateTimeToFullLocalString } from "../../utils/date";
import { useEffect, useState } from "react";
import { getNumberOfDevicesByStandBy } from "../../rest/restClient";
import { STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS } from "../configuration/actions";
import { useNavigate } from "react-router-dom";








const Icon = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-devices-pause">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 19v-10a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v4" />
            <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
            <path d="M17 17v5" />
            <path d="M21 17v5" />
            <path d="M16 9h2" />
        </svg>

    )

}



const NumberOfStationaryDevicesBodyComponent = ({
    stationaryDevicesForMoreThan10DaysOnClick,
    stationaryDevicesForMoreThan20DaysOnClick,
    stationaryDevicesForMoreThan30DaysOnClick,
    stationaryDevicesForMoreThan50DaysOnClick,
    stationaryDevicesForMoreThan70DaysOnClick,
    stationaryDevicesForMoreThan10Days,
    stationaryDevicesForMoreThan20Days,
    stationaryDevicesForMoreThan30Days,
    stationaryDevicesForMoreThan50Days,
    stationaryDevicesForMoreThan70Days,
    fetchDate
}) => {
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    let rows = []
    let caption = null

    rows.push({
        label: translate("resources.dashboard.number_of_stationary_devices.for_more_than_10_days"),
        info: stationaryDevicesForMoreThan10Days >= 0 ? stationaryDevicesForMoreThan10Days : "UNKNOWN",
        onClick: stationaryDevicesForMoreThan10DaysOnClick
    })

    rows.push({
        label: translate("resources.dashboard.number_of_stationary_devices.for_more_than_20_days"),
        info: stationaryDevicesForMoreThan20Days >= 0 ? stationaryDevicesForMoreThan20Days : "UNKNOWN",
        onClick: stationaryDevicesForMoreThan20DaysOnClick
    })

    rows.push({
        label: translate("resources.dashboard.number_of_stationary_devices.for_more_than_30_days"),
        info: stationaryDevicesForMoreThan30Days >= 0 ? stationaryDevicesForMoreThan30Days : "UNKNOWN",
        onClick: stationaryDevicesForMoreThan30DaysOnClick

    })

    rows.push({
        label: translate("resources.dashboard.number_of_stationary_devices.for_more_than_50_days"),
        info: stationaryDevicesForMoreThan50Days >= 0 ? stationaryDevicesForMoreThan50Days : "UNKNOWN",
        onClick: stationaryDevicesForMoreThan50DaysOnClick

    })

    rows.push({
        label: translate("resources.dashboard.number_of_stationary_devices.for_more_than_70_days"),
        info: stationaryDevicesForMoreThan70Days >= 0 ? stationaryDevicesForMoreThan70Days : "UNKNOWN",
        onClick: stationaryDevicesForMoreThan70DaysOnClick

    })

    caption = `${translate("resources.dashboard.number_of_stationary_devices.created_at")} ${getDateTimeToFullLocalString({ timestamp: fetchDate, locale })}`


    return (

        <InfoTable2ColWithOnClick rows={rows} lastCaption={caption} />



    )
}


const NumberOfStationaryDevices = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const navigate = useNavigate()
    const [fetchDate, setFetchDate] = useState(null);
    const [stationaryDevicesForMoreThan10Days, setStationaryDevicesForMoreThan10Days] = useState(null);
    const [stationaryDevicesForMoreThan20Days, setStationaryDevicesForMoreThan20Days] = useState(null);
    const [stationaryDevicesForMoreThan30Days, setStationaryDevicesForMoreThan30Days] = useState(null);
    const [stationaryDevicesForMoreThan50Days, setStationaryDevicesForMoreThan50Days] = useState(null);
    const [stationaryDevicesForMoreThan70Days, setStationaryDevicesForMoreThan70Days] = useState(null);
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    const dayMilli = 1000 * 60 * 60 * 24
    const tenDaysAgoMilli = todayMidnight - dayMilli * 10;
    const twentyDaysAgoMilli = todayMidnight - dayMilli * 20;
    const thirtyDaysAgoMilli = todayMidnight - dayMilli * 30;
    const fiftyDaysAgoMilli = todayMidnight - dayMilli * 50;
    const seventyDaysAgoMilli = todayMidnight - dayMilli * 70;
    const centuryAgoMilli = todayMidnight - dayMilli * 365 * 100;


    useEffect(async () => {

        getNumberOfDevicesByStandBy({
            since: (new Date(twentyDaysAgoMilli)).toISOString(),
            until: (new Date(tenDaysAgoMilli)).toISOString()
        }).then((res) => {
            if (res && res.status === 200) {
                setStationaryDevicesForMoreThan10Days(res.json)
                setFetchDate(new Date())

            }
        })
        getNumberOfDevicesByStandBy({
            since: (new Date(thirtyDaysAgoMilli)).toISOString(),
            until: (new Date(twentyDaysAgoMilli)).toISOString()
        }).then((res) => {
            if (res && res.status === 200) {
                setStationaryDevicesForMoreThan20Days(res.json)
                setFetchDate(new Date())

            }
        })

        getNumberOfDevicesByStandBy({
            since: (new Date(fiftyDaysAgoMilli)).toISOString(),
            until: (new Date(thirtyDaysAgoMilli)).toISOString()
        }).then((res) => {
            if (res && res.status === 200) {
                setStationaryDevicesForMoreThan30Days(res.json)
                setFetchDate(new Date())

            }
        })
        getNumberOfDevicesByStandBy({
            since: (new Date(seventyDaysAgoMilli)).toISOString(),
            until: (new Date(fiftyDaysAgoMilli)).toISOString()
        }).then((res) => {
            if (res && res.status === 200) {
                setStationaryDevicesForMoreThan50Days(res.json)
                setFetchDate(new Date())

            }
        })
        getNumberOfDevicesByStandBy({
            since: (new Date(centuryAgoMilli)).toISOString(),
            until: (new Date(seventyDaysAgoMilli)).toISOString()
        }).then((res) => {
            if (res && res.status === 200) {
                setStationaryDevicesForMoreThan70Days(res.json)
                setFetchDate(new Date())
            }
        })



    }, [])
    return (

        <Grid
            item
            sm={12}
            lg={6}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >
            <DashboardCard
                HeaderIcon={() => <Icon color={theme.palette.primary.dark} />}
                title={translate("resources.dashboard.titles.number_of_stationary_devices")}
                NoticeComponent={() => {
                    return (
                        <Typography sx={{ margin: "0px 10px 0px 10px", maxWidth: "280px", fontSize: "14px", fontWeight: 400, lineHeight: "30px" }}>
                            {translate("resources.dashboard.number_of_stationary_devices.notice")}
                        </Typography>
                    )
                }}
                BodyComponent={() =>
                    <NumberOfStationaryDevicesBodyComponent
                        fetchDate={fetchDate}
                        stationaryDevicesForMoreThan10DaysOnClick={() => { navigate(`/devices?favorite_search=${STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS}`) }}
                        stationaryDevicesForMoreThan20DaysOnClick={() => { navigate(`/devices?favorite_search=${STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS}`) }}
                        stationaryDevicesForMoreThan30DaysOnClick={() => { navigate(`/devices?favorite_search=${STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS}`) }}
                        stationaryDevicesForMoreThan50DaysOnClick={() => { navigate(`/devices?favorite_search=${STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS}`) }}
                        stationaryDevicesForMoreThan70DaysOnClick={() => { navigate(`/devices?favorite_search=${STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS}`) }}
                        stationaryDevicesForMoreThan10Days={stationaryDevicesForMoreThan10Days}
                        stationaryDevicesForMoreThan20Days={stationaryDevicesForMoreThan20Days}
                        stationaryDevicesForMoreThan30Days={stationaryDevicesForMoreThan30Days}
                        stationaryDevicesForMoreThan50Days={stationaryDevicesForMoreThan50Days}
                        stationaryDevicesForMoreThan70Days={stationaryDevicesForMoreThan70Days}
                    />}

            />

        </Grid>
    )
}

export default NumberOfStationaryDevices;