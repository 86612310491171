import { borderColor, display, width } from "@mui/system";
import { LIST_TYPE_CARDS } from "../ui/button/ListTypeButton";

export const listCommonStyle = ({ isCardMode, theme, isSmall }) => {
  return {
    width: "100%",
    "& .RaList-main": {
      width: "100%",
    },
    "& .MuiTablePagination-toolbar": {
      marginTop: isCardMode ? "20px" : "0px",
    },
    "& .MuiToolbar-root": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "12px",
      padding: "10px !important",
      minHeight: isSmall ? "44px" : "64px",
      marginBottom: "20px",
      alignItems: "center",
    },
    "& .RaList-content": {
      background: "none",
      boxShadow: "none",
    },
    "& .RaDatagrid-root": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    "& .MuiTableHead-root": {
      borderRadius: "12px",
    },
    "& .MuiTableCell-head": {
      background: "none",
    },
    "& .RaFilter-form": {
      width: "100%",
      flexDirection: isSmall ? "column" : "row",
      alignItems: isSmall ? "center" : "flex-start",
      margin: "0px !important",
      padding: "0px !important",
    },
    "& .MuiTablePagination-toolbar": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "12px",
      borderBottomLeftRadius: "12px",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    "& .MuiTableCell-root": {
      borderBottomColor: theme.palette.divider,
    },
    "& .RaFilter-button": {
      display: "none",
    },
  };
};

export const listMapCommonStyle = ({ theme, isMobile, noActions }) => {
  return {
    width: "100%",
    "& .RaList-main": {
      width: "100%",
    },
    "& .MuiTablePagination-toolbar": {
      marginTop: "0px",
    },
    "& .MuiToolbar-root": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "12px",
      padding: noActions
        ? "0px"
        : isMobile
        ? "5px 10px 5px 10px !important"
        : "10px !important",
      minHeight: noActions ? "0px" : isMobile ? "44px" : "64px",
      marginBottom: isMobile ? "10px" : "20px",
      alignItems: "center",
    },
    "& .RaList-content": {
      background: "none",
      boxShadow: "none",
    },
    "& .RaDatagrid-root": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    "& .MuiTableHead-root": {
      borderRadius: "12px",
    },
    "& .MuiTableCell-head": {
      background: "none",
    },
    "& .RaFilter-form": {
      display: isMobile ? "none" : "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "center" : "flex-start",
      margin: "0px !important",
      padding: "0px !important",
    },
    "& .MuiTablePagination-toolbar": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "12px",
      borderBottomLeftRadius: "12px",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    "& .MuiTableCell-root": {
      borderBottomColor: theme.palette.divider,
    },
    "& .RaFilter-button": {
      display: "none",
    },
  };
};

export const datagridCommonStyle = ({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 2,
    fontSize: "12px !important",
    table: {
      tableLayout: "fixed",
      width: "100%",
      wordBreak: "break-word",
    },
    "& .MuiTableCell-root.MuiTableCell-body": {
      height: "70px !important",
      "& .MuiTypography-root": {
        fontSize: "12px !important",
        lineHeight: "20px",
      },
    },
  };
};

export const listActionsColumnIconCommonStyle = ({ isLastItem }) => {
  return {
    width: "20px",
    height: "20px",
    minWidth: "20px",
    display: "flex",
    margin: isLastItem ? "0px" : "0px 24px 0px 0px",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
  };
};

export const searchInputCommonStyle = ({ theme, isSmall, width }) => {
  return {
    width: width ? width : "220px",
    height: "48px",
    margin: isSmall ? "8px 0px 0px 0px !important" : "0px !important",
    "& .MuiInputBase-root": {
      padding: "0px !important",
      height: "48px",
      textAlign: "center !important",
      borderRadius: "12px !important",
      borderWidth: "1px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[50]
          : theme.palette.background.appFrame,
      color: theme.palette.text.primary,
    },
    "& .MuiFormLabel-root": {
      marginTop: "4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px !important",
      borderWidth: "1px",
    },
    "& .MuiFormHelperText-root": {
      display: "none",
    },
  };
};

export const autocompleteInputCommonStyle = ({
  theme,
  isSmall,
  width,
  height,
}) => {
  return {
    width: width ? width : "220px",
    height: height ? height : "48px",
    display: "flex",
    alignItems: "flex-end",

    margin: isSmall ? "10px 0px 0px 0px !important" : "0px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px !important",
      borderWidth: "1px",
    },
    "& .MuiInputBase-root": {
      padding: "0px !important",
      height: height ? height : "48px",
      textAlign: "center !important",
      borderRadius: "12px !important",
      borderWidth: "1px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[50]
          : theme.palette.background.appFrame,
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      paddingLeft: "14px !important",
      paddingRight: "14px !important",
      height: "20.125px",
    },

    "& .MuiFormLabel-root": {
      padding: "0px !important",
      height: "40.125px",
      lineHeight: "20.125px",
      marginTop: "-3px",
    },
  };
};

export const textInputCommonStyle = ({ theme, width, height }) => {
  return {
    width: width ? width : "220px",
    height: height ? height : "48px",
    margin: "8px 0px 20px 0px !important",
    "& .MuiInputBase-root": {
      padding: "0px !important",
      height: height ? `${height} !important` : "48px !important",
      textAlign: "center !important",
      borderRadius: "12px !important",
      borderWidth: "1px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[50]
          : theme.palette.background.appFrame,
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      height: height ? `${height} !important` : "48px !important",
    },
    "& .MuiFormLabel-root": {
      display: "none",
    },
    "legend > span": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px !important",
      borderWidth: "1px",
    },
  };
};

export const dateTimeInputCommonStyle = ({ theme, width, height }) => {
  return {
    width: width ? width : "220px",
    height: height ? height : "48px",
    margin: "8px 0px 20px 0px !important",
    "& .MuiInputBase-root": {
      padding: "0px 0px 8px 12px !important",
      height: height ? `${height} !important` : "48px !important",
      textAlign: "center !important",
      borderRadius: "12px !important",
      borderWidth: "1px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[50]
          : theme.palette.background.appFrame,
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      height: height ? `${height} !important` : "48px !important",
      padding: "0px 5px 0px 10px !important",
    },
    "& .MuiButtonBase-root": {
      margin: "0px 15px 0px 0px !important",
      padding: "0px 0px 0px 0px !important",
    },
    "& .MuiFormLabel-root": {
      display: "none",
    },
    "legend > span": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px !important",
      borderWidth: "1px",
    },
  };
};

export const textFieldCommonStyle = ({ theme, width }) => {
  return {
    width: width ? width : "220px",
    height: "48px",
    margin: "0px 0px 20px 0px !important",
    padding: "12px",
  };
};

export const selectInputCommonStyle = ({ theme, width, height }) => {
  return {
    width: width ? width : "220px",
    height: height ? height : "48px",
    margin: "8px 0px 20px 0px !important",
    "& .MuiInputBase-root": {
      "& .MuiSelect-select.MuiSelect-outlined": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      padding: "0px !important",
      height: height ? `${height} !important` : "48px !important",
      textAlign: "center !important",
      borderRadius: "12px !important",
      borderWidth: "1px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[50]
          : theme.palette.background.appFrame,
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      height: height ? `${height} !important` : "48px !important",
    },
    "& .MuiFormLabel-root": {
      display: "none",
    },
    "legend > span": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px !important",
      borderWidth: "1px",
    },
  };
};

export const numberInputCommonStyle = ({ theme, isSmall, width, height }) => {
  return {
    width: width ? width : "220px",
    height: height ? height : "48px",
    display: "flex",
    alignItems: "flex-end",

    margin: isSmall ? "10px 0px 0px 0px !important" : "0px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px !important",
      borderWidth: "1px",
    },
    "& .MuiInputBase-root": {
      padding: "0px !important",
      height: height ? height : "48px",
      textAlign: "center !important",
      borderRadius: "12px !important",
      borderWidth: "1px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[50]
          : theme.palette.background.appFrame,
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      paddingLeft: "14px !important",
      paddingRight: "14px !important",
      height: "20.125px",
    },

    "& .MuiFormLabel-root": {
      padding: "0px !important",
      height: "40.125px",
      lineHeight: "20.125px",
      margin: "5px 3px 3px 20px",
    },
  };
};
