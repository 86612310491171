import { Box, Button, Grid, IconButton, LinearProgress, Typography, useTheme } from "@mui/material"
import { useEffect, useState, useMemo } from "react";
import { useDataProvider, useListContext, useTranslate } from "react-admin";
import StandardDialog from "../dialog/StandardDialog";
import { DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY, STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS, STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS } from "../../pages/configuration/actions";
import { useLocation } from "react-router-dom";



function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}


const customStyles = ({ theme, selected }) => ({
    favoriteFilterButton: {
        margin: "10px 0px 10px 0px",
        fontSize: "14px",
        backgroundColor: theme.palette.grey[200],
        color: selected ? theme.palette.primary.dark :
            theme.palette.text.primary,
        ":hover": {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
        }
    }
})


const AdvancedFiltersIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-filter-bolt">
            <path stroke="none" d="M0 0h20v20H0z" fill="none" stroke-width="0.8333333333333334" />
            <path d="M10.826 16.393 7.5 17.5v-7.083L3.767 6.31A1.667 1.667 0 0 1 3.333 5.189V3.333h13.333v1.81a1.667 1.667 0 0 1 -0.489 1.177L12.5 10v2.5" />
            <path d="m15.833 13.333 -1.667 2.5h3.333l-1.667 2.5" />
        </svg>
    )
}

const AdvancedFiltersIconWithBadge = ({ color, badgeColor }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-filter-bolt">
            <path stroke="none" d="M0 0h20v20H0z" fill="none" stroke-width="0.8333333333333334" />
            <path d="M10.826 16.393 7.5 17.5v-7.083L3.767 6.31A1.667 1.667 0 0 1 3.333 5.189V3.333h13.333v1.81a1.667 1.667 0 0 1 -0.489 1.177L12.5 10v2.5" />
            <path d="m15.833 13.333 -1.667 2.5h3.333l-1.667 2.5" />
            <circle cx="15" cy="5" r="5" fill={badgeColor} />
        </svg>
    )
}




const sortOrganizations = (organizations) => {

    const sortedOrganizations = [...organizations].sort((a, b) => {
        const aDisabled = !a.site_boundary_radius || !a.site_latitude || !a.site_longitude;
        const bDisabled = !b.site_boundary_radius || !b.site_latitude || !b.site_longitude;
        return aDisabled - bDisabled;
    });

    return sortedOrganizations

}
const DevicesOutsideOrganizationBoudaryDialog = ({
    open,
    setOpen,
    setSelectedAdvancedSearchOption,
    setSearchText,
    handleSubmitFilters
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const dataProvider = useDataProvider();
    const [organizations, setOrganizations] = useState(null);
    const [isLoading, setIsLoading] = useState(null);


    useEffect(async () => {
        setIsLoading(true);
        const { data: orgs } = await dataProvider.getMany('organizations', {
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 1000 }
        });
        if (orgs) {
            setOrganizations(sortOrganizations(orgs))
        }
        setTimeout(() => {

            setIsLoading(false);
        }, 500);
    }, []);


    return (
        <StandardDialog
            open={open}
            onClose={() => { }}
            fullWidth={false}
            BodyComponent={
                () => {
                    return (
                        <Grid container  >
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: "14px", margin: "0px 0px 12px 0px" }}>{translate("resources.devices.fields.organization")}</Typography>
                            </Grid>
                            {isLoading &&
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                    <Box sx={{ width: "250px", color: theme.palette.text.secondary }}>
                                        <LinearProgress color="inherit" />
                                    </Box>
                                </Grid>
                            }
                            <Grid item spacing={0} sx={{ overflowY: "scroll", maxHeight: "270px" }} >

                                {!isLoading && organizations &&
                                    organizations.map((org, idx) => {
                                        return (
                                            <Grid item xs={12} key={idx} sx={{ display: "flex", justifyContent: "center" }} >
                                                <Button
                                                    variant="text"
                                                    disabled={!org.site_boundary_radius || !org.site_latitude || !org.site_longitude}
                                                    onClick={() => {
                                                        setSelectedAdvancedSearchOption(DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY);
                                                        setSearchText(translate("resources.dashboard.number_of_devices_outside_organization_boundary.search_text", { organizationName: org.name }))
                                                        handleSubmitFilters({
                                                            outside_organization_boundary: org.id
                                                        });
                                                        setOpen(false);
                                                    }}
                                                    sx={customStyles({ theme }).favoriteFilterButton}>
                                                    {translate("resources.dashboard.number_of_devices_outside_organization_boundary.search_text", { organizationName: org.name })}
                                                </Button>
                                            </Grid>
                                        )
                                    })

                                }
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                <Button
                                    onClick={() => { setOpen(false); }}
                                    variant='text'
                                    sx={{ margin: "5px 0px  0px 0px", }}
                                >
                                    {translate('ra.action.close')}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
            } />
    )
}


export const AdvancedFiltersButton = ({ setSearchText }) => {
    const theme = useTheme();
    const [advancedFilters, setAdvancedFilters] = useState(null);
    const [selectedAdvancedSearchOption, setSelectedAdvancedSearchOption] = useState(null);
    const translate = useTranslate();
    const { filterValues, setFilters } = useListContext();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDevicesOutsideOrganizationBoudaryDialog, setOpenDevicesOutsideOrganizationBoudaryDialog] = useState(false);
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    const dayMilli = 1000 * 60 * 60 * 24
    const tenDaysAgoMilli = todayMidnight - dayMilli * 10;
    const twentyDaysAgoMilli = todayMidnight - dayMilli * 20;
    const thirtyDaysAgoMilli = todayMidnight - dayMilli * 30;
    const fiftyDaysAgoMilli = todayMidnight - dayMilli * 50;
    const seventyDaysAgoMilli = todayMidnight - dayMilli * 70;
    const centuryAgoMilli = todayMidnight - dayMilli * 365 * 100;
    const query = useQuery();


    useEffect(() => {
        if (query.get("favorite_search") === STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS) {
            setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS)
            setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_10_days")
            handleSubmitFilters({
                last_stand_by_since: (new Date(twentyDaysAgoMilli)).toISOString(),
                last_stand_by_until: (new Date(tenDaysAgoMilli)).toISOString()
            });
        }
        if (query.get("favorite_search") === STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS) {
            setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS)
            setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_20_days")
            handleSubmitFilters({
                last_stand_by_since: (new Date(thirtyDaysAgoMilli)).toISOString(),
                last_stand_by_until: (new Date(twentyDaysAgoMilli)).toISOString()
            });
        }
        if (query.get("favorite_search") === STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS) {
            setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS)
            setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_30_days")
            handleSubmitFilters({
                last_stand_by_since: (new Date(fiftyDaysAgoMilli)).toISOString(),
                last_stand_by_until: (new Date(thirtyDaysAgoMilli)).toISOString()
            });
        }
        if (query.get("favorite_search") === STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS) {
            setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS)
            setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_50_days")
            handleSubmitFilters({
                last_stand_by_since: (new Date(seventyDaysAgoMilli)).toISOString(),
                last_stand_by_until: (new Date(fiftyDaysAgoMilli)).toISOString()
            });
        }
        if (query.get("favorite_search") === STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS) {
            setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS)
            setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_70_days")
            handleSubmitFilters({
                last_stand_by_since: (new Date(centuryAgoMilli)).toISOString(),
                last_stand_by_until: (new Date(seventyDaysAgoMilli)).toISOString()
            });
        }
        if (query.get("favorite_search") === DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY && query.get("organization_id") && query.get("organization_name")) {
            setSelectedAdvancedSearchOption(DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY)
            setSearchText(translate("resources.dashboard.number_of_devices_outside_organization_boundary.search_text", { organizationName: query.get("organization_name") }))
            handleSubmitFilters({
                outside_organization_boundary: query.get("organization_id")
            });
        }


    }, [])

    useEffect(() => {
        if (
            filterValues?.last_stand_by_since ||
            filterValues?.last_stand_by_until ||
            filterValues?.outside_organization_boundary
        ) {
            setAdvancedFilters(filterValues)
        }

    }, [filterValues])

    const resetFilters = () => {
        setSelectedAdvancedSearchOption(null)
        setSearchText(null)
        setAdvancedFilters(null)
        query.delete("favorite_search")
        setFilters({}, {}, false);
        setTimeout(() => {
            setOpenDialog(false)
        }, 500);
    };

    const handleSubmitFilters = (adF) => {
        query.delete("favorite_search")
        if (adF) {
            setAdvancedFilters({ ...adF });
            setTimeout(() => {
                setFilters(adF, {}, false);
                setOpenDialog(false)
            }, 500);
        } else {
            setFilters(advancedFilters, {}, false);
            setTimeout(() => {
                setOpenDialog(false)
            }, 500);
        }
    };



    return (
        <Box sx={{
            padding: "0px",
            marginRight: "20px",
        }}>
            <IconButton
                sx={{
                    padding: advancedFilters ? "4px" : "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={() => { setOpenDialog(true); }}
            >

                {advancedFilters ?
                    <AdvancedFiltersIconWithBadge color={theme.palette.text.secondary} badgeColor={"red"} /> :
                    <AdvancedFiltersIcon color={theme.palette.text.secondary} />
                }

            </IconButton>
            <StandardDialog
                open={openDialog}
                fullWidth={false}
                onClose={() => { }}
                BodyComponent={() => {
                    return (
                        <Grid container  >
                            <Grid item xs={12}  >
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography sx={{ fontSize: "14px", margin: "0px 0px 12px 0px" }}>{translate("resources.common.favorite_filters")}</Typography>
                                </Grid>
                                <Grid item spacing={0} sx={{ overflowY: "scroll", maxHeight: "270px" }} >
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                        <Button
                                            variant="text"
                                            onClick={() => { setOpenDevicesOutsideOrganizationBoudaryDialog(true) }}
                                            sx={customStyles({ theme, selected: (selectedAdvancedSearchOption === DEVICES_OUTSIDE_ORGANIZATION_BOUNDARY) }).favoriteFilterButton}>
                                            {translate("resources.dashboard.number_of_devices_outside_organization_boundary.search_text", { organizationName: "...." })}
                                        </Button>
                                        <DevicesOutsideOrganizationBoudaryDialog
                                            open={openDevicesOutsideOrganizationBoudaryDialog}
                                            setOpen={setOpenDevicesOutsideOrganizationBoudaryDialog}
                                            setSelectedAdvancedSearchOption={setSelectedAdvancedSearchOption}
                                            setSearchText={setSearchText}
                                            handleSubmitFilters={handleSubmitFilters}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS);
                                                setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_10_days")
                                                handleSubmitFilters({
                                                    last_stand_by_since: (new Date(twentyDaysAgoMilli)).toISOString(),
                                                    last_stand_by_until: (new Date(tenDaysAgoMilli)).toISOString()
                                                });
                                            }}
                                            sx={customStyles({ theme, selected: (selectedAdvancedSearchOption === STATIONARY_DEVICES_FOR_MORE_THAN_10_DAYS) }).favoriteFilterButton}>
                                            {translate("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_10_days")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS)
                                                setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_20_days")
                                                handleSubmitFilters({
                                                    last_stand_by_since: (new Date(thirtyDaysAgoMilli)).toISOString(),
                                                    last_stand_by_until: (new Date(twentyDaysAgoMilli)).toISOString()
                                                });
                                            }}
                                            sx={customStyles({ theme, selected: (selectedAdvancedSearchOption === STATIONARY_DEVICES_FOR_MORE_THAN_20_DAYS) }).favoriteFilterButton}>
                                            {translate("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_20_days")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS)
                                                setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_30_days")
                                                handleSubmitFilters({
                                                    last_stand_by_since: (new Date(fiftyDaysAgoMilli)).toISOString(),
                                                    last_stand_by_until: (new Date(thirtyDaysAgoMilli)).toISOString()
                                                });
                                            }}
                                            sx={customStyles({ theme, selected: (selectedAdvancedSearchOption === STATIONARY_DEVICES_FOR_MORE_THAN_30_DAYS) }).favoriteFilterButton}>
                                            {translate("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_30_days")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS)
                                                setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_50_days")
                                                handleSubmitFilters({
                                                    last_stand_by_since: (new Date(seventyDaysAgoMilli)).toISOString(),
                                                    last_stand_by_until: (new Date(fiftyDaysAgoMilli)).toISOString()
                                                });
                                            }}
                                            sx={customStyles({ theme, selected: (selectedAdvancedSearchOption === STATIONARY_DEVICES_FOR_MORE_THAN_50_DAYS) }).favoriteFilterButton}>
                                            {translate("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_50_days")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setSelectedAdvancedSearchOption(STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS)
                                                setSearchText("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_70_days")
                                                handleSubmitFilters({
                                                    last_stand_by_since: (new Date(centuryAgoMilli)).toISOString(),
                                                    last_stand_by_until: (new Date(seventyDaysAgoMilli)).toISOString()
                                                });
                                            }}
                                            sx={customStyles({ theme, selected: (selectedAdvancedSearchOption === STATIONARY_DEVICES_FOR_MORE_THAN_70_DAYS) }).favoriteFilterButton}>
                                            {translate("resources.dashboard.number_of_stationary_devices.devices_list_subtitles.for_more_than_70_days")}
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>



                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                <Button
                                    variant="contained"
                                    onClick={resetFilters}
                                    sx={{
                                        margin: "30px 0px 5px 0px",
                                        fontSize: "14px",
                                    }}>
                                    {translate('resources.common.reset_filters')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} >
                                <Button
                                    onClick={() => { setOpenDialog(false); }}
                                    variant='text'
                                    sx={{ margin: "5px 0px  0px 0px", }}
                                >
                                    {translate('ra.action.close')}
                                </Button>
                            </Grid>
                        </Grid>

                    )
                }}
            />

        </Box >

    )


}