import React, { useState, useRef, Fragment, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
//react-admin
import { Datagrid, Filter, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, useTranslate, useSetLocale, useListContext, Pagination, AutocompleteInput, useLocaleState, DateField } from 'react-admin'

import { isAdminPermissions, isSuperAdmin, isYabby, PER_PAGE } from '../../../helper/resourceHelper'
import { Battery } from './Battery'
import SettingsButton from '../../../ui/button/SettingsButton'
import LocationButton from '../../../ui/button/LocationButton'
import MeasuresButton from '../../../ui/button/MeasuresButton'
import SubscriptionButton from '../../../ui/button/SubscriptionButton'
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { hasBattery, hasLocation } from '../../../utils/utils'
import MobileGrid from './MobileGrid'
import TrackingModeButton from '../../../ui/button/TrackingModeButton'
import { useMediaQuery, Box, Button, Dialog, DialogContent, Slide, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { usePermissions } from 'react-admin';
import { LIST_TYPE_CARDS, LIST_TYPE_TABLE, ListTypeButton } from '../../../ui/button/ListTypeButton';
import { AddBtnWithOptionalMenu } from '../../../ui/button/AddBtn';
import { DatagridHeader } from './DatagridHeader';
import Empty from './Empty';
import { autocompleteInputCommonStyle, datagridCommonStyle, listCommonStyle, searchInputCommonStyle } from '../../../themes/commonStyles';
import LoadingDialog from '../../../ui/dialog/LoadingDialog';
import { useLocation } from 'react-router-dom';
import { AdvancedFiltersButton } from '../../../ui/button/AdvancedFiltersButton';







const FiltersIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-filter">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" strokeWidth="0.6666666666666666" />
            <path d="M2.667 2.667h10.667v1.448a1.333 1.333 0 0 1 -0.391 0.943L10 8v4.667l-4 1.333v-5.667L3.013 5.048A1.333 1.333 0 0 1 2.667 4.151z" />
        </svg>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FiltersDialog = ({ open, setOpen }) => {

    const theme = useTheme();
    const contentBg = theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default;
    const translate = useTranslate();
    const dialogRef = useRef(null);
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });


    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, undefined, false);
        } else {
            hideFilter("main");
        }

        setTimeout(() => {
            setOpen(false)
        }, 500);
    };

    const resetFilter = () => {
        setFilters({}, [], false);
        setTimeout(() => {
            setOpen(false)
        }, 500);
    };


    const handleClose = () => setOpen(false);




    const handleDrag = (event) => {
        const dialogElement = dialogRef.current;
        if (!dialogElement) return;

        // Detect touch or mouse events
        const startY = event.type === 'mousedown'
            ? event.clientY
            : event.touches[0].clientY;

        const onMove = (moveEvent) => {
            const currentY = moveEvent.type === 'mousemove'
                ? moveEvent.clientY
                : moveEvent.touches[0].clientY;

            const deltaY = currentY - startY;

            if (deltaY > 150) {
                // Close the dialog if dragged down more than 150px
                handleClose();
            } else {
                // Temporarily move the dialog down
                dialogElement.style.transform = `translateY(${Math.min(deltaY, 150)}px)`;
            }
        };

        const onEnd = () => {
            // Reset dialog position and remove listeners
            dialogElement.style.transform = '';
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onEnd);
            window.removeEventListener('touchmove', onMove);
            window.removeEventListener('touchend', onEnd);
        };

        // Add listeners for dragging and release
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onEnd);
        window.addEventListener('touchmove', onMove);
        window.addEventListener('touchend', onEnd);
    };

    return (
        <Dialog
            ref={dialogRef}
            open={open}
            TransitionComponent={Transition}
            onClose={() => { }}
            fullScreen
            PaperProps={{
                style: { boxShadow: 'none' }, // Optional: Remove shadow around the dialog
            }}
            slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } }
            }}
            sx={{
                "& .MuiPaper-root": {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    height: "auto",
                    minHeight: "50%",
                    maxHeight: "70%",
                    margin: "0px",
                    padding: "0px",
                    border: 1,
                    borderTopColor: theme.palette.text.disabled,
                    borderWidth: "1px",
                    borderRadius: "12px 12px 0px 0px"
                }
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "15px",
                    margin: "0px",
                    padding: "10px 0px 10px 0px",
                    borderRadius: "0px",
                    backgroundColor: "none",
                    opacity: 0.45,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onMouseDown={handleDrag} // Handle mouse drag
                onTouchStart={handleDrag}

            >
                <Box
                    sx={{
                        width: "40px",
                        height: "4px",
                        margin: "1px",
                        borderRadius: "12px",
                        backgroundColor: theme.palette.text.secondary,
                        opacity: 0.45
                    }}


                >
                </Box>
            </Box>
            <DialogContent
                sx={{
                    width: "100%",
                    margin: "0px",
                    padding: "0px",
                    background: contentBg,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ padding: "0px 0px 0px 16px" }}>
                        <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>
                            {translate("resources.common.filter")}
                        </Typography>
                    </Box>
                </Box>

                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}
                        style={{
                            width: "100%",
                            height: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                margin: "0px",
                                padding: "16px 16px 30px 16px",
                                background: contentBg,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >

                            <Box component="span" mb="38px" sx={{ width: "100%" }}>
                                <TextInput
                                    id="outlined-basic-size-small"
                                    label={<SearchInputLabelComponent />}
                                    source="q"
                                    alwaysOn
                                    sx={searchInputCommonStyle({ theme, width: "100%" })}
                                />


                            </Box>
                            <Box component="span" mb="16px" sx={{ width: "100%" }}>
                                {isAdminPermissions() && <ReferenceInput source="organization_id" reference="organizations" alwaysOn >
                                    <AutocompleteInput
                                        id="outlined-basic-size-small"
                                        optionText="name"
                                        matchSuggestion={() => true} label="resources.devices.fields.organization"
                                        sx={autocompleteInputCommonStyle({ theme, width: "100%" })}
                                    />
                                </ReferenceInput>}
                            </Box>
                            <Box component="span" mb="16px" sx={{ width: "100%" }}>
                                {isAdminPermissions() && <ReferenceInput source="owner_id" reference="users" alwaysOn >
                                    <AutocompleteInput
                                        id="outlined-basic-size-small"
                                        optionText="email"
                                        matchSuggestion={() => true} label="resources.devices.fields.owner"
                                        sx={autocompleteInputCommonStyle({ theme, width: "100%" })}
                                    />
                                </ReferenceInput>}
                            </Box>

                            <Box component="span" mb="10px" sx={{ width: "100%" }}>
                                {isSuperAdmin() && <ReferenceInput source="product_id" reference="products" alwaysOn >
                                    <AutocompleteInput
                                        id="outlined-basic-size-small"
                                        optionText="marketing_name"
                                        matchSuggestion={() => true} label="resources.devices.product"
                                        sx={autocompleteInputCommonStyle({ theme, width: "100%" })}
                                    />
                                </ReferenceInput>}
                            </Box>

                            <Box component="span" mt="16px" sx={{ width: "100%" }}>
                                <Button variant="contained" color="primary" type="submit" sx={{ width: "100%" }}>
                                    {translate('resources.common.apply_filters')}
                                </Button>
                            </Box>
                            <Box component="span" mb="8px" sx={{ width: "100%" }}>
                                <Button variant="text" sx={{ width: "100%" }} onClick={resetFilter} >
                                    {translate('resources.common.reset_filters')}
                                </Button>
                            </Box>

                        </Box>
                    </form>
                </FormProvider>
            </DialogContent>


        </Dialog>
    )
}

const FiltersBtn = ({ label, color }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <Button sx={{ color: color }}
                onClick={(e) => { e.preventDefault(); setOpen(!open) }}
            >
                <FiltersIcon color={color} />&nbsp;{label}
            </Button>
            <FiltersDialog open={open} setOpen={setOpen} />
        </div>



    )
}

const DeviceActions = ({
    setListFormat,
    listFormat,
    isMobile,
    setSearchText
}) => {
    const { resource } = useListContext();
    const { isLoading, permissions } = usePermissions();
    const theme = useTheme();
    const translate = useTranslate();
    const addBtnMenuItems = []
    if (isSuperAdmin()) {
        addBtnMenuItems.push({
            label: translate('resources.devices.add_new_device'),
            path: `/${resource}/add`
        })
    }

    if (isSuperAdmin()) {
        addBtnMenuItems.push({
            label: translate('resources.devices.add_bulk_device'),
            path: `/${resource}/add_bulk`
        })
    }

    if (hasWriteAccess(permissions)) {
        addBtnMenuItems.push({
            label: translate('resources.devices.activate_new_device'),
            path: `/${resource}/create`
        })
    }

    if (!isMobile) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "row"

            }}>

                <AdvancedFiltersButton setSearchText={setSearchText} />
                <ListTypeButton listFormat={listFormat} setListFormat={setListFormat} />
                <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} isMobile={isMobile} />
            </Box>
        )
    }


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0px",
            margin: "0px",
            width: "100%",
            height: "24px"
        }}>
            <FiltersBtn
                color={theme.palette.text.secondary}
                label={translate("resources.common.filter")}
            />
            <Box sx={{ flex: 1 }}></Box>
            <AdvancedFiltersButton setSearchText={setSearchText} />
            <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} isMobile={isMobile} />
        </Box>
    )



}


const SearchInputLabelComponent = (labelProps) => {

    const translate = useTranslate();
    const SearchIcon = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_165_9911)">
                    <path d="M10.3338 9.33326H9.80708L9.62042 9.15326C10.4204 8.21993 10.8338 6.9466 10.6071 5.59326C10.2938 3.73993 8.74708 2.25993 6.88042 2.03326C4.06042 1.6866 1.68708 4.05993 2.03375 6.87993C2.26042 8.74659 3.74042 10.2933 5.59375 10.6066C6.94708 10.8333 8.22042 10.4199 9.15375 9.61993L9.33375 9.80659V10.3333L12.1671 13.1666C12.4404 13.4399 12.8871 13.4399 13.1604 13.1666C13.4338 12.8933 13.4338 12.4466 13.1604 12.1733L10.3338 9.33326ZM6.33375 9.33326C4.67375 9.33326 3.33375 7.99326 3.33375 6.33326C3.33375 4.67326 4.67375 3.33326 6.33375 3.33326C7.99375 3.33326 9.33375 4.67326 9.33375 6.33326C9.33375 7.99326 7.99375 9.33326 6.33375 9.33326Z" fill="#697586" />
                </g>
                <defs>
                    <clipPath id="clip0_165_9911">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        }}>
            <SearchIcon {...labelProps} />
            <div style={{
                marginLeft: "8px",
                color: "inherit",
            }}>
                {translate('resources.common.search')}
            </div>
        </div>

    )
}



const DevicePagination = ({ cardMode }) => {
    return (<Pagination sx={{
        '& .MuiToolbar-root': {
            borderTopRightRadius: cardMode ? "12px" : "0px",
            borderTopLeftRadius: cardMode ? "12px" : "0px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            marginTop: cardMode ? "25px" : "0px",

        }

    }} rowsPerPageOptions={[10, 25, 50, 100, 500]} />)
};


const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};





const DeviceList = ({ ...props }) => {
    const theme = useTheme()
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    const [listFormat, setListFormat] = useState(LIST_TYPE_TABLE)
    const [searchText, setSearchText] = useState(null)
    const [showImageColumn, setShowImageColumn] = useState(true)
    const [showIDColumn, setShowIDColumn] = useState(true)
    const [showNameColumn, setShowNameColumn] = useState(true)
    const [showSoftwareVersion, setShowSoftwareVersion] = useState(false)
    const [showBatteryColumn, setShowBatteryColumn] = useState(true)
    const [showProductColumn, setShowProductColumn] = useState(false)
    const [showLastSeenColumn, setShowLastSeenColumn] = useState(true)
    const [showOrganizationColumn, setShowOrganizationColumn] = useState(true)
    const [showOwnerColumn, setShowOwnerColumn] = useState(true)
    const { isLoading, permissions } = usePermissions();
    const isLargeOrLess = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const columnsDetails = [
        {
            label: translate('resources.devices.fields.image'),
            showColumn: showImageColumn,
            setShowColumn: setShowImageColumn,

        },
        {
            label: translate('resources.devices.fields.name'),
            showColumn: showNameColumn,
            setShowColumn: setShowNameColumn,

        },
        {
            label: translate('resources.organization.organization'),
            showColumn: showOrganizationColumn,
            setShowColumn: setShowOrganizationColumn,

        },
        {
            label: translate('resources.devices.fields.mac_address'),
            showColumn: showIDColumn,
            setShowColumn: setShowIDColumn,

        },
        {
            label: translate('resources.devices.fields.software_version'),
            showColumn: showSoftwareVersion,
            setShowColumn: setShowSoftwareVersion,

        },
        {
            label: translate('resources.devices.fields.battery'),
            showColumn: showBatteryColumn,
            setShowColumn: setShowBatteryColumn,

        },
        {
            label: translate('resources.devices.product'),
            showColumn: showProductColumn,
            setShowColumn: setShowProductColumn,

        },
        {
            label: translate('resources.devices.fields.last_seen'),
            showColumn: showLastSeenColumn,
            setShowColumn: setShowLastSeenColumn,

        },
        {
            label: translate("resources.devices.owner"),
            showColumn: showOwnerColumn,
            setShowColumn: setShowOwnerColumn,

        }

    ]





    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.devices.page_titles.my_devices')}
            </Typography>
            <List
                {...props}
                title={translate('resources.devices.name', { smart_count: 2 })}
                actions={<DeviceActions isMobile={isLargeOrLess} listFormat={listFormat} setListFormat={setListFormat} setSearchText={setSearchText} />}
                resource="devices"
                empty={<Empty />}
                filters={!isLargeOrLess ?
                    <Filter  {...props}>
                        <TextInput
                            id="outlined-basic-size-small"
                            label={<SearchInputLabelComponent />}
                            source="q"
                            alwaysOn
                            sx={searchInputCommonStyle({ theme, isSmall: isLargeOrLess })}
                        />
                        {isAdminPermissions() && <ReferenceInput source="organization_id" reference="organizations" alwaysOn >
                            <AutocompleteInput
                                id="outlined-basic-size-small"
                                disablePortal
                                optionText="name"
                                matchSuggestion={() => true} label="resources.devices.fields.organization"
                                sx={autocompleteInputCommonStyle({ theme, isSmall: isLargeOrLess })}
                            />
                        </ReferenceInput>}
                        {isAdminPermissions() && <ReferenceInput source="owner_id" reference="users" alwaysOn >
                            <AutocompleteInput
                                id="outlined-basic-size-small"
                                optionText="email"
                                matchSuggestion={() => true} label="resources.devices.fields.owner"
                                sx={autocompleteInputCommonStyle({ theme, isSmall: isLargeOrLess })}
                            />
                        </ReferenceInput>}
                        {isSuperAdmin() && <ReferenceInput source="product_id" reference="products" alwaysOn >
                            <AutocompleteInput
                                id="outlined-basic-size-small"
                                disablePortal
                                optionText="marketing_name"
                                matchSuggestion={() => true} label="resources.devices.product"
                                sx={autocompleteInputCommonStyle({ theme, isSmall: isLargeOrLess })}
                            />
                        </ReferenceInput>}

                    </Filter> : null
                }
                filter={{
                    includes:
                        'image,last_seen,mac_address,product,organization_id,software_version,status,type,last_events,last_battery,last_location,last_subscription,owner_id',
                }}
                perPage={PER_PAGE}
                pagination={false}
                sort={{ field: 'last_seen', order: 'DESC' }}
                sx={listCommonStyle({ isSmall: isLargeOrLess, isCardMode: (isLargeOrLess || listFormat === LIST_TYPE_CARDS), theme })}

            >
                {searchText && <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 400, fontSize: "14px", lineHeight: "18px", margin: "0px 0px 2px 0px" }}> {`${translate("resources.devices.advsearch")} :`}</Typography>}
                {searchText && <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "14px", lineHeight: "36px", margin: "0px 0px 16px 0px" }}> {translate(searchText)}</Typography>}
                {(isLargeOrLess || listFormat === LIST_TYPE_CARDS) ? (
                    <MobileGrid />
                ) : (
                    <Datagrid
                        sx={datagridCommonStyle({ theme })}
                        bulkActionButtons={false}
                        size='small'
                        header={<DatagridHeader columns={columnsDetails} />}

                    >


                        {showImageColumn && <FunctionField
                            source="image"
                            label=""
                            render={record =>
                                record.image && record.image.src ? (
                                    <img
                                        style={{ width: "50px", height: "50px", backgroundColor: "transparent", borderRadius: "10px", margin: "0px", padding: "0px" }}
                                        alt={record.icon_name ? record.product.icon_name : null}
                                        src={record.image.src}
                                    />
                                ) : (
                                    (record.product && record.product.image && record.product.image.src) ? (
                                        <img
                                            style={{ width: "50px", height: "50px", backgroundColor: "transparent", borderRadius: "10px", margin: "0px", padding: "0px" }}
                                            alt={record.product ? record.product.name : null}
                                            src={record.product.image.src}
                                        />
                                    ) : (
                                        <Box sx={{ width: 50, height: 50, backgroundColor: theme.palette.text.primary, opacity: 0.1, borderRadius: "10px" }} />
                                    )
                                )
                            }
                            sortable={false}
                        />}
                        {showNameColumn && <TextField source="name" />}
                        {isAdminPermissions() && showOrganizationColumn && (
                            <ReferenceField
                                allowEmpty
                                label={'resources.organization.organization'}
                                source="organization_id"
                                reference="organizations"
                                sortable={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        )}
                        {showIDColumn && <FunctionField
                            source="mac_address"
                            render={record =>
                                record.serial_number ? record.serial_number : record.mac_address
                            }
                        />}

                        {showSoftwareVersion && <TextField
                            source="software_version"
                            sortable="false"
                        />}
                        {showBatteryColumn && <FunctionField
                            source="battery"
                            render={record => <Battery record={record} />}
                            sortable={false}
                        />}
                        {showProductColumn && <FunctionField
                            label={'resources.devices.product'}
                            source="product_marketing_name"
                            render={record => record.product ? record.product.marketing_name.toUpperCase() : null}
                        />}

                        {showLastSeenColumn && <DateField
                            source="last_seen"
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false, // Use 24-hour format
                            }}
                        />}
                        {isAdminPermissions() && showOwnerColumn && (
                            <ReferenceField label="resources.devices.owner" source="owner_id" reference="users" allowEmpty>
                                <FunctionField
                                    render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                    sortable={false}
                                />
                            </ReferenceField>
                        )
                        }


                        <FunctionField
                            label={translate('resources.devices.fields.actions', { smart_count: 1 })}
                            render={record =>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>

                                    <SettingsButton disabled={!hasWriteAccess(permissions)} basePath={"devices"} record={record} />
                                    <SubscriptionButton disabled={record.type !== 'unb' || !hasWriteAccess(permissions)} basePath={"devices"} record={record} />
                                    <TrackingModeButton basePath={"devices"} key={record && record.configuration && record.configuration.recovery_mode_status ? record.configuration.recovery_mode_status : "key"} record={record} />
                                    <MeasuresButton disabled={!hasBattery(record)} record={record} />
                                    <LocationButton disabled={!hasLocation(record)} record={record} />

                                </Box>


                            }
                        />

                    </Datagrid>

                )
                }

                <DevicePagination cardMode={(isLargeOrLess || listFormat === LIST_TYPE_CARDS)} />
                <LoadingPopUp />

            </List >
        </>

    )
}

export default DeviceList
