import React, { cloneElement, useEffect, useState } from 'react'
import {
    DateField,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    SelectField,
    TextField,
    TextInput,
    useTranslate,
    Pagination,
    useLocaleState,
    FunctionField,
    useListContext,
} from 'react-admin'
import { PER_PAGE } from '../../helper/resourceHelper'
import { Alert, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import { datagridCommonStyle, listCommonStyle } from '../../themes/commonStyles'
import NotificationLogAckButton from '../../ui/button/NotificationLogAckButton';
import { useParams } from 'react-router-dom';
import { AckChip } from './AckChip';
import NotificationLogHistoryActions from './actions';
import { deviceName } from '../../rest/restClient';
import PageHeader from '../../ui/layout/PageHeader';
import LoadingDialog from '../../ui/dialog/LoadingDialog';
import MobileGrid from './MobileGrid';





const NotificationLogHistoryPagination = ({ cardMode }) => {
    return (<Pagination sx={{
        '& .MuiToolbar-root': {
            borderTopRightRadius: cardMode ? "12px" : "0px",
            borderTopLeftRadius: cardMode ? "12px" : "0px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            marginTop: cardMode ? "25px" : "0px",
        }
    }} rowsPerPageOptions={[10, 25, 50, 100, 500]} />)
};

const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};




const NotificationLogHistoryList = ({ props }) => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm')
    );
    const translate = useTranslate();
    const theme = useTheme();
    const { id } = useParams();
    const [_deviceName, setDeviceName] = useState('');
    const [deviceSN, setDeviceSN] = useState('');
    const [locale, setLocale] = useLocaleState();

    useEffect(() => {
        deviceName(id).then(event => {

            setDeviceName(event.json.name)
            setDeviceSN(event.json.mac_address ? event.json.mac_address : event.json.serial_number)
        })
    }, [])
    return (

        <div>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.notification_logs.name', { smart_count: 2 })}
            </Typography>
            <PageHeader
                rows={[
                    [{ title: translate('resources.heading_messages.device_name'), message: _deviceName }, { title: translate('resources.heading_messages.device_id'), message: deviceSN }]
                ]}
                isSmall={isSmall}
            />
            <List
                title={translate('resources.notification_logs.name', { smart_count: 2 })}
                filter={{
                    names: ["notification_log"],
                    device_ids: id,
                }}
                resource='notification_log_events'
                perPage={PER_PAGE}
                disableSyncWithLocation
                pagination={false}
                sx={listCommonStyle({ isCardMode: isSmall, theme })}
                actions={
                    <NotificationLogHistoryActions recordId={id} isMobile={isSmall} />
                }
            >
                {(isSmall) ? (
                    <MobileGrid />
                ) : (
                    <Datagrid
                        bulkActionButtons={false}
                        size='small'
                        sx={datagridCommonStyle({ theme })}
                    >
                        <ReferenceField
                            allowEmpty={true}
                            label={translate('resources.devices.name', { smart_count: 1 })}
                            source="device_id"
                            reference="devices"
                            sortable={false}
                        >
                            <TextField source="name" />
                        </ReferenceField>

                        <DateField
                            source="timestamp"
                            showTime
                            locales={locale}
                            options={{
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            }}
                        />
                        <FunctionField
                            source="subject"
                            render={
                                record => {
                                    const subject = translate(`resources.notifications.${record.subject}`)
                                    return (
                                        <Typography
                                            sx={{ fontWeight: 400 }}
                                        >
                                            {subject.length > 35 ? `${subject.substring(0, 35)}...` : subject}
                                        </Typography>
                                    )
                                }}
                            sortable={false}
                        />

                        <FunctionField
                            source="description"
                            render={
                                record => {
                                    let description = record.description
                                    if (locale && locale === 'fr' && record.meta && record.meta.descriptionFR) description = record.meta.descriptionFR
                                    if (locale && locale === 'en' && record.meta && record.meta.descriptionEN) description = record.meta.descriptionEN
                                    if (locale && locale === 'es' && record.meta && record.meta.descriptionES) description = record.meta.descriptionES

                                    return (
                                        <Typography
                                            sx={{ fontWeight: 400, maxWidth: "450px" }}
                                        >
                                            {description.length > 400 ? `${description.substring(0, 400)}...` : description}
                                        </Typography>
                                    )
                                }}
                            sortable={false}
                        />
                        <FunctionField
                            source="acknowledged"
                            render={record => <AckChip record={record} />}
                            sortable={false}
                        />

                        <FunctionField
                            label={translate('resources.common.actions', { smart_count: 1 })}
                            render={record =>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>

                                    <NotificationLogAckButton
                                        eventId={record.event_id}
                                        canAck={record.acknowledged === false}
                                        canNack={record.acknowledged === true}
                                    />
                                </Box>

                            }
                        />
                    </Datagrid>

                )}
                <NotificationLogHistoryPagination cardMode={isSmall} />
                <LoadingPopUp />
            </List>
        </div>
    )
}



export default NotificationLogHistoryList




